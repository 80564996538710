import React from "react";
import { observer } from "mobx-react";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../css/DataTable.css";
import Popover from "react-bootstrap/Popover";
import {
  employeeList,
  deleteEmployee,
  getEmployee,
  deleteMultipleEmployees,
} from "../../apis/admin/Employee";
import Modal from "react-bootstrap/Modal";
import { roleOptions, roleName } from "../../common/DropdownOptions";
import { CustomSelect } from "../../common/Element";
import ConfirmModal from "../../common/ConfirmModal";
import CreateEmployee from "../../pages/admin/create/CreateEmployee";
import AppConfig from "../../modal/AppConfig";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import User from "../../modal/User";
import moment from "moment";

class Employee extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    rows: [],
    columns: [],
    totalData: 0,
    search: "",
    viewGuestDetails: false,
    filter: "",
    viewUserConfirmModal: false,
    role_id: "",
    guestuser: false,
    hideButton: false,
    isMultipleDelete: false,
    employeeIdsArr: [],
  };

  async componentDidMount() {
    const columns = [
      {
        field: "icon",
        headerName: (
          <div>
            <i className="fas fa-ellipsis-v pointer theme-font-color" />
          </div>
        ),
        renderCell: (cellValues) => (
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="right"
            id="popover-basic"
            overlay={this.popover(cellValues.id, cellValues.row.role)}
          >
            <i className="fas fa-ellipsis-v pointer theme-font-color" />
          </OverlayTrigger>
        ),
        minWidth: 20,
      },
      { field: "id", headerName: "Id", minWidth: 150 },
      { field: "name", headerName: "Name", minWidth: 150 },
      { field: "email", headerName: "Email", minWidth: 150 },
      { field: "mobile", headerName: "Phone", minWidth: 150 },
      { field: "role_id", headerName: "Role", minWidth: 150 },
      // { field: "role", hide: true },
      {
        field: "action",
        headerName: "Action",
        minWidth: 150,
        renderCell: (param) => {
          return param?.row?.role_id === "Guest User" ? (
            <div>
              <i
                className="fas fa-eye theme-font-color pointer"
                onClick={() =>
                  this.setState({ viewGuestDetails: true }, () =>
                    this.guestUserApi(param.row.id)
                  )
                }
              />
            </div>
          ) : null;
        },
      },
    ];
    this.guestUserApi();
    this.employeeApi();
    this.setState({ columns: columns });
  }

  employeeApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
      search: this.state.search,
      role_id: this.state.role_id,
    };
    const response = await employeeList(requestData);
    if (response && response.status === "success") {
      const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      if (rows.length > 0) {
        for (let i in rows) {
          apiRows.push(rows[i]);
        }
      }
      for (let i in result.data) {
        const row = {
          id: result?.data[i].id,
          name: result?.data[i].name,
          email: result?.data[i].email,
          mobile: result?.data[i].mobile,
          role: result.data[i].role_id,
          role_id: roleName[result.data[i].role_id],
        };
        apiRows.push(row);
      }
      this.setState({ rows: [...apiRows], totalData: result.total });
    }
  };
  guestUserApi = async (id) => {
    const response = await getEmployee(id);
    if (response && response.status === "success") {
      let result = response.result?.guestusers;
      let lot_access = [];
      for (let i in result?.lot_access) {
        lot_access.push(result?.lot_access[i].lot_no);
      }
      this.setState({
        accessLotNo: lot_access + "  ",
        locationAccess: result?.loc_access?.name,
        startDate: result?.created_at,
        endDate: moment(result?.access_end_date).format("Do MMMM  YYYY"),
      });
      console.log(
        "res",
        this.state.locationAccess,
        this.state.startDate,
        this.state.endDate
      );
    }
  };

  // Render Menu function for data table data
  popover = (id, currentUserRole) => {
    console.log("id", id);
    const role_id = User.role_id;
    return (
      <Popover id="popover-basic" className="border-0 p-3">
        <p className="my-1 pointer ">
          <i
            className="far fa-edit mr-2"
            onClick={() => {
              this.editUser(id, currentUserRole);
            }}
          >
            Edit
          </i>
        </p>
        {role_id === 1 || role_id === 2 || role_id === 3 ? (
          <p
            className="my-1 pointer "
            onClick={() => {
              this.deleteUser(id);
            }}
          >
            <i className="far fa-trash-alt mr-2">Delete</i>
          </p>
        ) : null}
      </Popover>
    );
  };

  render() {
    if (User.refresh) {
      this.employeeApi();
      User.setRefresh(false);
    }
    const role_id = User.role_id;

    return (
      <div className="m-3 row">
        <div className="border col-md-4 bg-white rounded border-secondary d-flex justify-content-between p-3">
          <input
            type="text"
            className="no-outline input-style small-font-size w-100"
            placeholder="Search.."
            onChange={this._handleSearchChange}
          />
          <i className="fas fa-search align-self-center" />
        </div>
        <div className="col-md-3">
          <CustomSelect
            className="black"
            placeholder="Filter Role"
            value={this.state.role_id}
            onChange={this.sortingHandler}
            options={roleOptions}
          />
        </div>
        {role_id === 2 || role_id === 3 || role_id === 4 || role_id === 1 ? (
          this.state.isMultipleDelete ? (
            <div className="col-md-2 my-2" style={{ marginLeft: "15px" }}>
              <button
                type="btn"
                className="btn theme-bg-color text-white fw-bold normal-font-size"
                onClick={() => this.handleDeleteMultiple()}
              >
                Delete
              </button>
            </div>
          ) : null
        ) : null}
        <div className="my-3">{this.renderTable()}</div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewUserConfirmModal}
          heading="Delete User"
          title="Are you sure you want to delete the User?"
          confirm={() => this.getUserSuccess()}
          handleClose={() => this.setState({ viewUserConfirmModal: false })}
        />
        <CreateEmployee
          hideButton={this.state.hideButton}
          status={this.state.status}
          editUserId={this.state.editUserId}
          closeModel={() => this.setState({ status: false })}
          user={this.state.guestuser ? "guest" : "employee"}
          reRenderTable={() => {
            this.setState({ rows: [] }, () => this.employeeApi());
          }}
        />
      </div>
    );
  }

  renderTable = () => {
    const CustomToolbar = () => {
      return (
        <div className="px-3 py-2 d-flex justify-content-between">
          <p className="theme-font-color normal-font-size align-self-center">
            User / Guest Details
          </p>
          <GridToolbarExport />
        </div>
      );
    };
    console.log("this.state.current_page", this.state.current_page);
    return (
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          className="black"
          rows={this.state.rows}
          columns={this.state.columns}
          page={this.state.current_page - 1}
          pageSize={this.state.size}
          onPageSizeChange={(size) =>
            this.setState({ size: size, current_page: 1 }, () =>
              this.employeeApi()
            )
          }
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(current_page) =>
            this.setState({ current_page: parseInt(current_page) + 1 }, () =>
              this.employeeApi()
            )
          }
          rowCount={this.state.totalData}
          checkboxSelection={true}
          disableSelection={true}
          hideFooterSelectedRowCount={true}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(ids) => {
            if (ids.length > 0) {
              console.log("selection_product_ids", ids);
              this.setState({
                isMultipleDelete: true,
                employeeIdsArr: [...ids],
              });
            } else {
              this.setState({
                isMultipleDelete: false,
                employeeIdsArr: [...ids],
              });
            }
          }}
        />
        {this.renderViewGuestDetails()}
      </div>
    );
  };
  renderViewGuestDetails = () => {
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewGuestDetails}
      >
        <div className="m-3">
          <div className="d-flex justify-content-end px-3">
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={() => {
                this.setState({ viewGuestDetails: false });
              }}
            />
          </div>
          <Modal.Body>
            <div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size">Access Lot No.</p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size">
                    {this.state.accessLotNo ? this.state.accessLotNo : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size">Location</p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size">
                    {this.state.locationAccess
                      ? this.state.locationAccess
                      : "-"}{" "}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size">Start Date</p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size">
                    {this.state.startDate
                      ? moment(this.state.startDate).format("Do MMMM  YYYY")
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size">End Date</p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size">
                    {this.state.endDate ? this.state.endDate : "-"}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Search handle function
  _handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, search: value }, () => {
      this.employeeApi();
    });
  };

  // Sorting handler function
  sortingHandler = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, filter: value }, () => {
      this.employeeApi();
    });
  };

  viewGuestDetails = () => {
    this.setState({ viewGuestDetails: true });
  };

  // Sorting handler function
  sortingHandler = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, role_id: value }, () => {
      this.employeeApi();
    });
  };
  // Delete project confirm modal
  deleteUser = (id) => {
    this.setState({ viewUserConfirmModal: true, deleteId: id });
  };

  // Edit project modal
  editUser = (id, role_id) => {
    let guestuser = false;
    if (role_id === 5) {
      guestuser = true;
    }
    this.setState({ editUserId: id, status: true, guestuser });
  };

  // Delete project api function
  getUserSuccess = () => {
    const id = this.state.deleteId;
    deleteEmployee(id).then((response) => {
      if (response && response.status === "success") {
        const myArray = this.state.rows.filter((obj) => {
          return obj.id !== id;
        });
        this.setState({
          viewUserConfirmModal: false,
          rows: [...myArray],
        });
        // this.setState({ viewUserConfirmModal: false, rows: [] }, () =>
        //   this.employeeApi()
        // );
        // User.setRefresh(true);
        AppConfig.setMessage("User Deleted Successfully", false);
      } else {
        this.setState({ viewUserConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  // Delete Multiple Lead
  handleDeleteMultiple = async () => {
    const requestBody = {
      id: [...this.state.employeeIdsArr],
    };
    console.log("requestBody", requestBody);
    try {
      const response = await deleteMultipleEmployees(requestBody);
      console.log("response", response);
      if (response && response.status === "success") {
        this.setState(
          { rows: [], totalData: 0, employeeIdsArr: [], current_page: 1 },
          () => {
            this.employeeApi();
            AppConfig.setMessage(response.result, false);
          }
        );
      } else {
        AppConfig.setMessage(response.result);
      }
    } catch (err) {
      console.log("deleteMultipleLeads_err", err);
    }
  };
}
export default observer(Employee);
