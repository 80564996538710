import { callApi } from "../Api";
import "../Global";

/**
 *
 * @param{id, name, address)data
 */
export const createEmployee = async (requestData = {}) => {
  const functionUrl = "user";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get user list , filter, search)data
 */
export const employeeList = async (requestData = {}) => {
  const functionUrl = "user_list";
  try {
    console.log("project fun url", functionUrl);
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{update user) by id
 */
export const updateEmployee = async (requestData, id) => {
  const functionUrl = "user";
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{delete user) by id
 */
export const deleteEmployee = async (id) => {
  const functionUrl = "user";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get user) by id
 */
export const getEmployee = async (id) => {
  const functionUrl = "user";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get lot no) by id
 */
export const getLotNoDropdown = async (id) => {
  const functionUrl = "lotno_list";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

// Delete multiple Leads
export const deleteMultipleEmployees = async (requestBody = {}) => {
  const functionUrl = "delete_multiple_users";
  try {
    return await callApi(functionUrl, requestBody, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
