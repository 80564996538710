import React from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import { percentageCalculations } from './Element';

const options = {
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

export const DoughnutChart = (props) => {
    let { label, value } = props;
    return (
        <>
            <div className="d-flex flex-column col-3 border p-2">
                <div className='col-12'>
                    {value.length > 0 && value[1] !== 0 ?
                        <Doughnut
                            data={{
                                datasets: [
                                    {
                                        label: 'Prospect',
                                        data: value,
                                        backgroundColor: [
                                            'rgba(160, 50, 56, 1)',
                                            'rgba(160, 50, 56, 0.1)',
                                        ],
                                        borderColor: [
                                            'rgba(160, 50, 56, 1)',
                                            'rgba(160, 50, 56, 0.1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                                labels: label,
                            }}
                            plugins={[{
                                beforeDraw: function (chart) {
                                    const datasets = chart.data.datasets[0].data;
                                    const data = [datasets[0], datasets[1]]
                                    const percentage = percentageCalculations(data);
                                    var width = chart.width,
                                        height = chart.height,
                                        ctx = chart.ctx;
                                    ctx.restore();
                                    var fontSize = (height / 160).toFixed(2);
                                    ctx.font = fontSize + "em sans-serif";
                                    ctx.textBaseline = "top";
                                    var text = percentage + " %",
                                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                                        textY = height / 1.7;
                                    ctx.fillText(text, textX, textY);
                                    ctx.save();
                                }
                            }]}
                        />
                        : null}
                </div>
                <div className='py-2'>
                    <p className='font-weight-bold normal-font-size mb-0 text-center'><strong>Lead to Prospect Ratio</strong></p>
                </div>
            </div>
        </>
    );
}

export const DoughnutCustomerChart = (props) => {
    let { label, value } = props;
    return (
        <>
            <div className="d-flex flex-column col-3 border p-2">
                <div className='col-12'>
                    {value.length > 0 && value[1] !== 0 ?
                        <Doughnut
                            data={{
                                labels: label,
                                datasets: [{
                                    label: "Customer",
                                    data: value,
                                    backgroundColor: [
                                        'rgba(160, 50, 56, 1)',
                                        'rgba(160, 50, 56, 0.1)',
                                    ],
                                    borderColor: [
                                        'rgba(160, 50, 56, 1)',
                                        'rgba(160, 50, 56, 0.1)',
                                    ],
                                    borderWidth: 1,
                                }]
                            }}
                            plugins={[{
                                beforeDraw: function (chart) {
                                    const datasets = chart.data.datasets[0].data;
                                    const data = [datasets[0], datasets[1]]
                                    const percentage = percentageCalculations(data);
                                    var width = chart.width,
                                        height = chart.height,
                                        ctx = chart.ctx;
                                    ctx.restore();
                                    var fontSize = (height / 160).toFixed(2);
                                    ctx.font = fontSize + "em sans-serif";
                                    ctx.textBaseline = "top";
                                    var text = percentage + " %",
                                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                                        textY = height / 1.7;
                                    ctx.fillText(text, textX, textY);
                                    ctx.save();
                                }
                            }]}
                        />
                        : null}
                </div>
                <div className='py-2'>
                    <p className='font-weight-bold normal-font-size mb-0 text-center'><strong>Lead to Customer Ratio</strong></p>
                </div>
            </div>
        </>
    );
}


export const VerticalBar = (props) => {
    let { label, value } = props;
    return (
        <>
            <div className="d-flex flex-column justify-content-end col-5">
                <div className='col-12'>
                    {(label.length > 0 && value.length > 0) ?
                        <Line
                            options={options}
                            width={350}
                            height={275}
                            data={{
                                labels: label,
                                datasets: [
                                    {
                                        label: 'Leads',
                                        data: value,
                                        backgroundColor: [
                                            'rgba(160, 50, 56, 0.5)',
                                        ],
                                        borderColor: [
                                            'rgba(160, 50, 56, 0.5)'
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                        />
                        : null}
                </div>
            </div>
        </>
    )
};