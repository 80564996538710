import React from "react";
import { Layout } from "../../common/Element";
import "../../css/Common.css";
import Header from "../../common/Header";
import AddProduct from "./create/AddProduct";
import CreateLocation from "./create/CreateLocation";
import CreateEmployee from "./create/CreateEmployee";
import CreateArchitect from "./create/CreateArchitect";
import CreateShades from "./create/CreateShades";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { observer } from "mobx-react";
import Product from "../inventory/Product";
import Location from "./Location";
import Employee from "./Employee";
import Shade from "./Shade";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Card from "react-bootstrap/Card";

class Admin extends React.Component {
	state = {
		menuTab: "",
		user: "",
	};

	render() {
		return (
			<Layout>
				<Header>
					<h3 className="font-style">Admin</h3>
					<div className="col-md-10 normal-font-size">
						<Tabs
							defaultActiveKey="overview"
							id="controlled-tab-example"
							onSelect={(menuTab) => this.setState({ menuTab })}
						>
							<Tab eventKey="overview" title="Overview" active />
							<Tab eventKey="location" title="Location" />
							<Tab eventKey="product" title="Product" />
							<Tab eventKey="shade" title="Shade" />
						</Tabs>
					</div>
				</Header>
				<div className="m-3">
					<CreateButton />
				</div>
				{this.renderPage()}
			</Layout>
		);
	}
	renderPage = () => {
		if (this.state.menuTab === "location") {
			return <Location />;
		} else if (this.state.menuTab === "product") {
			return <Product admin={true} />;
		} else if (this.state.menuTab === "shade") {
			return <Shade />;
		} else {
			return <Employee />;
		}
	};
}

class CreateButton extends React.Component {
	state = {
		anchorEl: null,
		user: null,
		status: false,
	};

	handleClose = () => {
		this.setState({ anchorEl: false });
	};

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	render() {
		const { anchorEl } = this.state;
		return (
			<>
				{!anchorEl ? (
					<i
						className="fas fa-plus-circle theme-font-color"
						style={{ fontSize: 30 }}
						onClick={this.handleClick}
					/>
				) : (
					<i
						className="fas fa-times-circle theme-font-color"
						style={{ fontSize: 30 }}
						onClick={this.handleClick}
					/>
				)}
				<Popper
					placement="bottom-start"
					id={this.state.anchorEl}
					open={this.state.anchorEl}
					anchorEl={this.state.anchorEl}
				>
					<ClickAwayListener onClickAway={this.handleClose}>
						<Card className="p-3 shadow">
							<ul
								className="px-0 m-0"
								style={{ listStyleType: "none" }}
							>
								<li>
									<CreateLocation
										hideButton={true}
										closeModel={this.handleClose}
									/>
								</li>
								<li>
									<CreateEmployee
										hideButton={true}
										user={"employee"}
										closeModel={this.handleClose}
										reRenderTable={() => {
											window.location.reload();
										}}
									/>
								</li>
								<li>
									<AddProduct
										hideButton={true}
										closeModel={this.handleClose}
									/>
								</li>
								<li>
									<CreateEmployee
										hideButton={true}
										user={"guest"}
										closeModel={this.handleClose}
										reRenderTable={() => {
											window.location.reload();
										}}
									/>
								</li>
								<li>
									<CreateArchitect />
								</li>
								<li>
									<CreateShades
										hideButton={true}
										closeModel={this.handleClose}
									/>
								</li>
							</ul>
						</Card>
					</ClickAwayListener>
				</Popper>
			</>
		);
	}
}
export default observer(Admin);
