// Add product modal
import React, { createRef } from "react";
import "../../css/Common.css";
import {
  CustomInput,
  CustomSelect,
  CustomDatePicker,
} from "../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../css/Modal.css";
import AppConfig from "../../modal/AppConfig";
import {
  leadTypeOptions,
  leadStatusOptions,
} from "../../common/DropdownOptions";
import moment from "moment";
import { createLead, getLead, updateLead } from "../../apis/Lead";
import User from "../../modal/User";
import { getLocationDropdown } from "../../apis/admin/Location";
import { getLeadAssignedTo } from "../../apis/Lead";
import {
  CheckName,
  CheckPhone,
  CheckEmail,
  DropDownCheck,
  CheckAddress,
} from "../../common/Validation";
import Lead from "../../modal/Lead";
import CustomSpinner from "../../common/Spinner";

class CreateLead extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    leadType: "",
    architect: "",
    selectArchitect: "",
    phoneNo: "",
    description: "",
    timeSlot: new Date(),
    leadAssignTo: "",
    leadStatus: "",
    city: "",
    storeNearYou: "",
    locationNameList: [],
    userList: [],
    isEdit: false,
    isApiResponse: false,
  };

  async componentDidMount() {
    // Api call for location dropdown
    const response = await getLocationDropdown();
    let locationNameList = [];
    if (response && response.status === "success") {
      const result = response.result.location_dropdown;
      for (let i in result) {
        let locationName = {
          value: result[i].id,
          label: result[i].name,
        };
        locationNameList.push(locationName);
      }
    }
    // Api call for user dropdown
    const userResponse = await getLeadAssignedTo();
    let userList = [];
    if (userResponse && userResponse.status === "success") {
      const result = userResponse.result.users_dropdown;
      for (let i in result) {
        let user = {
          value: result[i].id,
          label: result[i].name,
        };
        userList.push(user);
      }
    }
    this.setState({ locationNameList: locationNameList, userList: userList });
  }

  async componentDidUpdate(prevProps) {
    Lead.setEditId(this.props.editId);
    if (
      this.props.editId !== prevProps.editId ||
      Lead.editId === prevProps.editId
    ) {
      if (Lead.isApiCall) {
        const id = this.props.editId;
        const response = await getLead(id);
        const result = response.result.leadByid[0];
        if (response && response.status === "success") {
          this.setState({
            isApiResponse: true,
            id: id,
            firstName: result.first_name,
            lastName: result.last_name,
            email: result.email,
            leadType: result.type,
            project: result.project,
            phoneNo: result.mobile,
            address: result.address,
            description: result.enquiry,
            timeSlot: result.time_slot,
            leadAssignTo: result.assigned_to,
            leadStatus: result.status,
            storeNearYou: result.location_id,
            isEdit: id ? true : false,
          });
        }
        Lead.setIsApiCall(false);
      }
    }
    console.log("store near you", this.state.storeNearYou);
  }

  render() {
    return <>{this.renderCreateLeadModal()}</>;
  }

  // Render carete lead modal function

  renderCreateLeadModal() {
    console.log("this.props", this.props);
    console.log("this.props.status_createLead", this.props.status);
    console.log("this.state.isEdit_createLead", this.props.isEdit);
    console.log("this.state.leadAssignTo_createLead", this.state.leadAssignTo);
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <div className="d-flex">
              <p
                className="font-style normal-font-size"
                style={{ marginRight: "10px" }}
              >
                {this.props.isEdit ? "Update Lead" : "Create Lead"}
              </p>
              {this.props.isEdit && !this.state.isApiResponse ? (
                <CustomSpinner animationType="grow" spinnerSize="sm" />
              ) : null}
            </div>
            <i
              className="fas fa-times-circle theme-font-color pointer"
              onClick={this.handleClose}
            />
          </div>
          <Modal.Body>
            <form onSubmit={this.onSubmitCreateLead}>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="first_name"
                  placeholder="First Name*"
                  value={this.state.firstName}
                  tabIndex="1"
                  onChange={(e) =>
                    this.setState({
                      firstName: e.target.value,
                      firstNameError: "",
                    })
                  }
                  onFocus={() => this.setState({ firstNameError: "" })}
                  error={this.state.firstNameError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="last_name"
                  placeholder="Last Name*"
                  tabIndex="2"
                  value={this.state.lastName}
                  onChange={(e) =>
                    this.setState({
                      lastName: e.target.value,
                      lastNameError: "",
                    })
                  }
                  onFocus={() => this.setState({ lastNameError: "" })}
                  error={this.state.lastNameError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="email"
                  placeholder="Email*"
                  tabIndex="3"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ email: e.target.value, emailError: "" })
                  }
                  onFocus={() => this.setState({ emailError: "" })}
                  error={this.state.emailError}
                />
              </div>
              <div className="py-1">
                <CustomSelect
                  className="white"
                  placeholder="Select the Lead Type*"
                  value={this.state.leadType}
                  tabIndex="4"
                  onChange={(e) =>
                    this.setState({
                      leadType: e.target.value,
                      leadTypeError: "",
                    })
                  }
                  options={leadTypeOptions}
                  onFocus={() => this.setState({ leadTypeError: "" })}
                  error={this.state.leadTypeError}
                />
              </div>
              <p className="xsmall-font-size font-style">
                If Architect then write the name of your project?
              </p>
              {this.state.leadType === "IS_ARCHITECT" ? (
                <div className="py-1">
                  <CustomInput
                    type="text"
                    id="project"
                    tabIndex="5"
                    placeholder="Enter the Project Name"
                    value={this.state.project}
                    onChange={(e) => this.setState({ project: e.target.value })}
                    error={this.state.projectError}
                  />
                </div>
              ) : null}
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="phoneNo"
                  tabIndex="6"
                  placeholder="Phone No*"
                  value={this.state.phoneNo}
                  onChange={(e) =>
                    this.setState({
                      phoneNo: e.target.value,
                      phoneNumberError: "",
                    })
                  }
                  onFocus={() => this.setState({ phoneNumberError: "" })}
                  error={this.state.phoneNumberError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="address"
                  tabIndex="7"
                  placeholder="Address*"
                  value={this.state.address}
                  onChange={(e) =>
                    this.setState({ address: e.target.value, addressError: "" })
                  }
                  onFocus={() => this.setState({ addressError: "" })}
                  error={this.state.addressError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="description"
                  tabIndex="8"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({
                      description: e.target.value,
                      descriptionError: "",
                    })
                  }
                  onFocus={() => this.setState({ descriptionError: "" })}
                />
              </div>
              <div className="py-1">
                <CustomDatePicker
                  placeholder="Select your time slot"
                  selected={this.state.timeSlot}
                  value={this.state.timeSlot}
                  tabIndex="9"
                  onChange={(value) => {
                    this.setState({ timeSlot: value });
                  }}
                  onSelect={this.timeSlotHandleChange}
                  onFocus={() => this.setState({ timeSlotError: "" })}
                  error={this.state.timeSlotError}
                  minDate={this.state.timeSlot}
                />
              </div>
              <div className="py-1">
                <CustomSelect
                  className="white"
                  tabIndex="10"
                  placeholder="Lead Assigned to..*"
                  value={this.state.leadAssignTo}
                  onChange={(e) =>
                    this.setState({ leadAssignTo: e.target.value })
                  }
                  options={this.state.userList}
                  onFocus={() => this.setState({ leadAssignToError: "" })}
                  error={this.state.leadAssignToError}
                />
              </div>
              <div className="py-1">
                <CustomSelect
                  className="white"
                  tabIndex="11"
                  placeholder="Lead Status*"
                  value={this.state.leadStatus}
                  onChange={(e) =>
                    this.setState({ leadStatus: e.target.value })
                  }
                  onFocus={() => this.setState({ leadStatusError: "" })}
                  options={leadStatusOptions}
                  error={this.state.leadStatusError}
                />
              </div>
              <div className="py-1">
                <CustomSelect
                  className="white"
                  tabIndex="12"
                  placeholder="Select Store Near You*"
                  value={this.state.storeNearYou}
                  onChange={(e) =>
                    this.setState({ storeNearYou: e.target.value })
                  }
                  options={this.state.locationNameList}
                  onFocus={() => this.setState({ storeNearYouError: "" })}
                  error={this.state.storeNearYouError}
                />
              </div>
              <button
                type="submit"
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
              >
                {this.props.isEdit ? "Update Lead" : "Create Lead"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  // Handle arrival change
  timeSlotHandleChange = (timeSlot) => {
    this.setState({ timeSlot, timeSlotErr: "" });
  };

  // Validation for first  name
  validateFirstName = () => {
    const firstNameError = CheckName(this.state.firstName);
    if (firstNameError === 1) {
      this.setState({ firstNameError: "First name empty" });
      return false;
    } else if (firstNameError === 2) {
      this.setState({ firstNameError: "Invalid first name" });
      return false;
    } else return true;
  };

  // Validation for last name
  validateLastName = () => {
    const lastNameError = CheckName(this.state.lastName);
    if (lastNameError === 1) {
      this.setState({ lastNameError: "Last name empty" });
      return false;
    } else if (lastNameError === 2) {
      this.setState({ lastNameError: "Invalid last name" });
      return false;
    } else return true;
  };

  // Validation for email

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Email empty" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email" });
      return false;
    } else return true;
  };

  // Validation for phone Number
  validatePhoneNumber = () => {
    const phoneNumberError = CheckPhone(this.state.phoneNo);
    if (phoneNumberError === 1) {
      this.setState({ phoneNumberError: " Phone Number empty" });
      return false;
    } else if (phoneNumberError === 2) {
      this.setState({ phoneNumberError: "Please enter 10 digits phone no." });
      return false;
    } else return true;
  };

  // Validation for address
  validateAddress = () => {
    const addressError = CheckAddress(this.state.address);
    if (addressError === 1) {
      this.setState({ addressError: "Address empty" });
      return false;
    } else if (addressError === 2) {
      this.setState({ addressError: "Invalid Address" });
      return false;
    } else return true;
  };

  // // Validation for address
  // validateDescription = () => {
  //     const descriptionError = CheckDescription(this.state.description);
  //     if (descriptionError === 2) {
  //          this.setState({descriptionError:''});
  //         return false;
  //     } else return true;
  // };

  // Validation for property type
  validateLeadType = () => {
    const dropDownError = DropDownCheck(this.state.leadType);
    if (dropDownError === 1) {
      this.setState({
        leadTypeError: "Select lead type",
      });
      return false;
    } else return true;
  };

  // Validation for property type
  validateLeadAssignTo = () => {
    const dropDownError = DropDownCheck(this.state.leadAssignTo);
    if (dropDownError === 1) {
      this.setState({ leadAssignToError: "Select lead assigned to" });
      return false;
    } else return true;
  };
  // Validation for property type
  validateLeadStatus = () => {
    const dropDownError = DropDownCheck(this.state.leadStatus);
    if (dropDownError === 1) {
      this.setState({ leadStatusError: "Select lead status" });
      return false;
    } else return true;
  };
  // Validation for store near you
  validateStoreNearYou = () => {
    const dropDownError = DropDownCheck(this.state.storeNearYou);
    if (dropDownError === 1) {
      this.setState({ storeNearYouError: "Select store near you" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const firstNameInput = this.validateFirstName();
    const lastNameInput = this.validateLastName();
    const emailInput = this.validateEmail();
    const phoneNumberInput = this.validatePhoneNumber();
    const leadTypeInput = this.validateLeadType();
    const addressInput = this.validateAddress();
    const leadAssignToInput = this.validateLeadAssignTo();
    const leadStatusInput = this.validateLeadStatus();
    const storeNearYouInput = this.validateStoreNearYou();

    if (
      firstNameInput &&
      lastNameInput &&
      emailInput &&
      phoneNumberInput &&
      leadTypeInput &&
      addressInput &&
      leadAssignToInput &&
      leadStatusInput &&
      storeNearYouInput
    ) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit function for create lead inputs

  onSubmitCreateLead = async (e) => {
    e.preventDefault();
    const id = this.props.editId;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      const requestData = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        type: this.state.leadType,
        project: this.state.project,
        mobile: this.state.phoneNo,
        enquiry: this.state.description,
        time_slot: moment(this.state.timeSlot).format("Do MMMM  YYYY"),
        status: this.state.leadStatus,
        assigned_to: this.state.leadAssignTo,
        address: this.state.address,
        location_id: this.state.storeNearYou,
        user_id: User.user_id,
      };
      if (id) {
        requestData["updated_by"] = User.user_id;
        const response = await updateLead(requestData, id);
        if (response && response.status === "success") {
          this.props.closeModel(false);
          User.setRefresh(true);
          AppConfig.setMessage("Lead updated successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      } else {
        const response = await createLead(requestData);
        if (response && response.status === "success") {
          this.handleClose();
          User.setRefresh(true);
          AppConfig.setMessage("Lead created successfully", false);
        } else if (response.status === "error") {
          this.handleClose();
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      }
    }
  };

  handleClose = () => {
    this.props.closeModel(false);
    this.setState({
      isApiResponse: false,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      leadType: "",
      project: "",
      phoneNo: "",
      address: "",
      description: "",
      timeSlot: new Date(),
      leadAssignTo: "",
      leadStatus: "",
      storeNearYou: "",
      isEdit: false,
    });
    Lead.setIsApiCall(false);
  };
}
export default CreateLead;
