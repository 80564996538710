import React from "react";
import Modal from "react-bootstrap/Modal";
import '../css/Common.css';

class ConfirmModal extends React.Component {
  	render() {
		return (
			<Modal
				show={this.props.visible}
				onHide={() => this.props.handleClose(false)}
				dialogClassName="modal-100w"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
						<div className="d-flex justify-content-center text-center theme-font-color">
							<span className="smallText text-center">
								{this.props.heading}
							</span>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.delete && (
						<div className="d-flex justify-content-center mt-5 mb-4">
							<i className="fas fa-trash pointer mx-2 theme-font-color" style={{fontSize:'50px'}} />  
						</div>
					)}
					<div className="d-flex justify-content-center mt-5 mb-4">
						<p className="smallText" style={{ textAlign: "center" }}>
							{this.props.title}
						</p>
					</div>
					<div className="d-flex justify-content-center mt-5 mb-4">
						<button
							className="btn theme-bg-color col-md-3 text-white"
							onClick={() => this.props.confirm()}
						>
							Confirm
						</button>
							&nbsp;&nbsp;&nbsp;
						<button
							className="btn rounded-border border-white col-md-3 theme-font-color "
							onClick={() => this.props.handleClose(false)}
						>
							Cancel
						</button>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default ConfirmModal;
