import React from "react";
import { useLocation } from "react-router-dom";
import { Item } from "./Item";
import { Menu } from "react-pro-sidebar";
import User from "../../modal/User";

const MenuList = () => {
  const currentPath = useLocation();
  const role_id = User.role_id;
  return (
    <>
      <Menu style={{ backgroundColor: "#F3F3F3" }}>
        {/* --------------------------------------------
						Dashboard
			---------------------------------------------- */}
        {role_id === 1 ||
        role_id === 3 ||
        role_id === 2 ||
        role_id === 4 ||
        role_id === 6 ? (
          <Item
            title={"Dashboard"}
            link={"/dashboard"}
            className={"fas fa-border-all"}
            // toolTipLabel={"Dashboard"}
            active={currentPath.pathname === "/dashboard" ? true : false}
          ></Item>
        ) : null}
        {role_id === 1 ||
        role_id === 2 ||
        role_id === 3 ||
        role_id === 4 ||
        role_id === 6 ? (
          <Item
            title={"Lead"}
            link={"/leads"}
            className={"fas fa-chart-bar"}
            // toolTipLabel={"Lead"}
            active={currentPath.pathname === "/leads" ? true : false}
          ></Item>
        ) : null}
        {role_id === 1 ||
        role_id === 2 ||
        role_id === 3 ||
        role_id === 4 ||
        role_id === 5 ||
        role_id === 6 ? (
          <Item
            title={"Inventory"}
            link={"/inventory"}
            className={"fas fa-boxes"}
            // toolTipLabel={"Inventory"}
            active={currentPath.pathname === "/inventory" ? true : false}
          ></Item>
        ) : null}
        {role_id === 1 ? (
          <Item
            title={"Admin"}
            link={"/admin"}
            className={"fas fa-user-cog"}
            // toolTipLabel={"Dispatch & Track"}
            active={currentPath.pathname === "/admin" ? true : false}
          ></Item>
        ) : null}
      </Menu>
    </>
  );
};

export default MenuList;
