// Header
import React, { useState, useRef, useEffect } from "react";
import "../css/Common.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Profile from "../pages/general/Profile";
import Notification from "../pages/general/Notification";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "../css/menu.css";
import Settings from "../pages/general/Settings";
import Badge from "@mui/material/Badge";
import User from "../modal/User";
import { notificationList } from "../apis/Notification";
import { observer } from "mobx-react";

const Header = observer((props) => {
	return (
		<>
			<div className="col-md-11 px-2 mx-2 no-printme">
				<Navbar expand="lg" className="flex-grow-1 py-0">
					<Nav className="menuItems flex-row flex-grow-1 top-menu-border-line">
						{props.children}
						<Nav.Link>
							<NotificationIcon />
						</Nav.Link>
						<Nav.Link>
							<SettingsIcon />
						</Nav.Link>
						<Profile />
					</Nav>
				</Navbar>
			</div>
		</>
	);
});
export default Header;

// Notification icon function
const NotificationIcon = observer(() => {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	useEffect(() => {
		(async function () {
			const response = await notificationList(User.user_id);
			const result = response?.result;
			User.setUnreadCount(result.count);
		})();
	}, []);

	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	return (
		<div ref={ref}>
			<Badge
				badgeContent={User.unreadCount ? User.unreadCount : "0"}
				color="primary"
			>
				<i
					className="far fa-bell giga-font-size theme-font-color align-self-center"
					onClick={handleClick}
				/>
			</Badge>
			<Overlay
				show={show}
				target={target}
				placement="bottom-end"
				container={ref}
				containerPadding={20}
				rootClose={true}
				onHide={() => setShow(!show)}
			>
				<Popover
					id="popover-contained"
					className="border-style col-md-4"
				>
					<div className="p-2 d-flex justify-content-between">
						<span className="xsmall-font-size fontColor">
							Recent Notifications
						</span>
						<i
							className="fas fa-times-circle pointer theme-font-color"
							onClick={() => setShow(!show)}
						/>
					</div>
					<Notification closeNotification={() => setShow(!show)} />
				</Popover>
			</Overlay>
		</div>
	);
});

//Setting icon function
function SettingsIcon() {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);

	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	return (
		<div ref={ref}>
			<i
				className="fas fa-cog giga-font-size theme-font-color align-self-center"
				onClick={handleClick}
			/>
			<Overlay
				show={show}
				target={target}
				placement="bottom-end"
				container={ref}
				containerPadding={20}
				rootClose={true}
				onHide={() => setShow(!show)}
			>
				<Popover className=" col-md-4">
					<Settings />
				</Popover>
			</Overlay>
		</div>
	);
}
