// Common api call function
import AppConfig from '../modal/AppConfig';
import './Global';

export const callApi = (
	functionUrl = null,
	requestBody = {},
	method = 'POST',
	urlValue = null,
	headerVal = false,
	metaBody = false,
) => {
	// setting Body
	const requestInfo = JSON.stringify(requestBody);
	// Setting the Header
	let headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		// 'Access-Control-Allow-Origin': global.baseUrl
	};

	if(AppConfig.api_key) {
        headers['Authorization'] = 'Bearer ' + AppConfig.api_key;
    }

	let requestObj = {
		method,
		headers: headers,
		body: requestInfo
	};

	if (metaBody) {
		requestBody['meta'] = metaBody;
	}
	if (headerVal !== false) {
		for (let i in headerVal) {
			headers[i] = headerVal[i];
		}
	}

	if(method === 'GET') {
		requestObj = {};
	}

	console.log(requestObj, 'requestobj');
	let url = global.baseUrl + functionUrl;
	if (global.apiUrls[functionUrl]) {
		url = global.baseUrl + global.apiUrls[functionUrl];
	}
	if (urlValue) {
		url = functionUrl ? (url +"/"+urlValue) : (url + urlValue);
	}
	console.log(url, 'urlll');
	AppConfig.setLoader(true);
	try {
	// All core API will be called from here
	return fetch(url, requestObj)
		.then(response => {
			const result = response.json();
			result.then(data => {
				AppConfig.setLoader(false)
				
				// We are checking common errors
				if (data.status === false && data.result && data.result.error === 31) {
					// Logout the user
					console.log('Logout the user');
				}
			});
			return result;
		})
		.catch(async error => {
			AppConfig.setLoader(false)
			console.log('Erorr ', error);
			// if (error.message === 'Network request failed') {
				
			// }
			throw error;
		});
	} catch(error) {
		console.log(error);
	}
};


export const uploadApi = (functionUrl, pictureData, callBack, type) => {
	const url = global.baseUrl + global.apiUrls[functionUrl];

	let formData = new FormData();
	formData.append('image', pictureData);
	formData.append('fileType', pictureData && pictureData.type ? pictureData.type : 'image/png');
	// formData.append('documentType', type);

	// Starting Http Request
	const xhr = new XMLHttpRequest();

	xhr.open('POST', url);
	console.log('OPENED', xhr.status);
	addListeners(xhr, callBack);
	xhr.onprogress = function() {
		console.log('LOADING', xhr.status);
	};
	xhr.onload = function() {
		console.log('DONE', xhr.status);
		console.log('Response ', xhr.response);
		if (xhr.status === 200){
			const response = JSON.parse(xhr.response);
			// returning the response in callBack page 
			callBack(response);
        }
	};
	// Ending Http Request
	xhr.send(formData);
};

const addListeners = (xhr, callBack) => {
	xhr.addEventListener('loadstart', callBack);
	xhr.addEventListener('load', callBack);
	xhr.addEventListener('loadend', callBack);
	xhr.addEventListener('progress', callBack);
	xhr.addEventListener('error', callBack);
	xhr.addEventListener('abort', callBack);
	xhr.addEventListener('response', callBack);
};

export const getQRXMLFile = (functionUrl, callBack, urlValue) => {
	let url = global.baseUrl + functionUrl;
	if (global.apiUrls[functionUrl]) {
		url = global.baseUrl + global.apiUrls[functionUrl];
	}
	if (urlValue) {
		url = functionUrl ? (url +"/"+urlValue) : (url + urlValue);
	}

	// let formData = new FormData();
	// formData.append('image', pictureData);
	// formData.append('fileType', pictureData && pictureData.type ? pictureData.type : 'image/png');
	// formData.append('documentType', type);

	// Starting Http Request
	const xhr = new XMLHttpRequest();

	xhr.open('GET', url);
	console.log('OPENED', xhr.status);
	// addListeners(xhr, callBack);
	xhr.onprogress = function() {
		console.log('LOADING', xhr.status);
	};
	xhr.onload = function() {
		// if(xhr.response) {
		// 	callBack(xhr.response);
		// }
		if (xhr.status === 200){
			const response = xhr.response;
			// returning the response in callBack page 
			callBack(response);
        }
	};
	// Ending Http Request
	xhr.send();
};