import React from 'react';
import '../../css/Common.css';

const Main =  props   => {
	let {handleToggleSidebar} = props;
	return (
		<main className="mx-4" >
			<header className="d-flex">
				<div
					className="btn-toggle pointer mx-3 no-printme"
					onClick={()=> handleToggleSidebar(true)}
				>
					&#9776;
				</div>
			</header>
			<div className="h-100">
				{props.children}
			</div>	
		</main>
	);
};

export default Main;