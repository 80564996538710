// Settings popup

import React from 'react';
import '../../css/Common.css';
import {Link, useHistory} from 'react-router-dom';
import User from '../../modal/User';
import AppConfig from '../../modal/AppConfig';


function Settings () {
    const history = useHistory();
    // onClick logout function
    function onClickLogout ()  {
        User.clearStoredDate();
        AppConfig.clearStoredDate();
        history.push("/sign-in");
        console.log('logout', history)
    }
    
        return(
            <div className="p-3">
                <div className="pl-3">
                    <Link to='/change-password' className='text-decoration-none'>
                        <p className="normal-font-size theme-font-color font-weight-bold pointer" >Change Password</p>  
                    </Link>
                </div>
                <button className='bg-transparent border-0' onClick={onClickLogout}>
                    <div className='d-flex'>
                        <i className="fas fa-sign-out-alt theme-font-color mega-font-size ml-3 pl-3" style={{paddingRight:'10px'}}/>
                        <p className="medium-font-size theme-font-color font-weight-bold pointer ml-3 pl-3">Logout</p>
                    </div>
                </button>
                
            </div>
        )
    }

export default Settings;