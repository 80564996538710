import { makeAutoObservable} from "mobx"
import { makePersistable , clearPersistedStore, getPersistedStore, hydrateStore, isHydrated, isPersisting, startPersisting,  pausePersisting, stopPersisting } from 'mobx-persist-store';

class User {
	user_id = '';
	role_id = '';
	name='';
	mobile = '';
	email = '';
	address ='';
	profile_image = '';
	unreadCount = '';


	refresh = false;

	constructor() {
        makeAutoObservable(this)
		makePersistable(this, { name: 'user', properties: ['user_id', 'role_id', 'unreadCount', 'mobile', 'email', 'address', 'name', 'profile_image' ], storage: window.sessionStorage });
    }
	get isHydrated() {
		return isHydrated(this);
	}
	
	get isPersisting() {
		return isPersisting(this);
	}

	startPersist() {
		startPersisting(this);
	}


	async hydrateStore() {
		await hydrateStore(this);
	}

	async clearStoredDate() {
		this.user_id = '';
		this.role_id = '';
		this.unreadCount = '';
		this.mobile = '';
		this.email = '';
		this.address = '';
		this.name = '';
		this.profile_image = '';
		await clearPersistedStore(this);
	}
	  
	async getStoredData() {
		return getPersistedStore(this);
	}

	pausePersist(){
		pausePersisting(this);
	}
	
	disposePersist(){
		stopPersisting(this);
	}
	
	async rehydrateStore() {
		await hydrateStore(this);
	}

	setUserId(value) {
		this.user_id = value;
	}
	setRoleId(value) {
		this.role_id = value;
	}

	setName(value) {
		this.name = value;
	}

	setMobile(value) {
		this.mobile = value;
	}

	setAddress(value) {
		this.address = value;
	}

	setEmail(value) {
		this.email = value;
	}

	setProfilePic(value) {
		this.profile_image = value;
	}

	setRefresh(value) {
		this.refresh = value;
	}

	setUnreadCount(value) {
		this.unreadCount = value;
	}

	setReadCount(value) {
		console.log(this.unreadCount,'decrese')
		this.unreadCount = parseInt(value) - 1;
	}
}


export default new User();