import React from "react";
import { Layout, CustomSelect } from "../../common/Element";
import { leadStatusOptions } from "../../common/DropdownOptions";
import { leadList, deleteLead, deleteMultipleLeads } from "../../apis/Lead";
import "../../css/Common.css";
import Header from "../../common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { observer } from "mobx-react";
import CreateLead from "./CreateLead";
import User from "../../modal/User";
import AppConfig from "../../modal/AppConfig";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../css/DataTable.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ConfirmModal from "../../common/ConfirmModal";
import Lead from "../../modal/Lead";

class Leads extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    status: false,
    rows: [],
    columns: [],
    totalData: 0,
    sorting: [],
    filter: "",
    search: "",
    editLeadId: "",
    viewLeadConfirmModal: false,
    isEdit: false,
    isMultipleDelete: false,
    leadIdsArr: [],
  };

  async componentDidMount() {
    const columns = [
      { field: "Id", hide: true },
      { field: "name", headerName: "Name", minWidth: 200 },
      { field: "email", headerName: "Email", minWidth: 200 },
      { field: "mobile", headerName: "Phone", minWidth: 150 },
      { field: "status", headerName: "Status", minWidth: 150 },
      { field: "type", headerName: "Type", minWidth: 150 },
    ];
    if (!(User.role_id === 4 || User.role_id === 5)) {
      columns.push({
        field: "action",
        headerName: "Action",
        minWidth: 150,
        renderCell: (cellValues) => (
          <div>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="right"
              id="popover-basic"
              overlay={this.popover(cellValues.id)}
            >
              <i className="fas fa-ellipsis-v pointer theme-font-color mx-3" />
            </OverlayTrigger>
            <i
              className="fas fa-arrow-right pointer mx-2 theme-font-color"
              onClick={() => this.viewLead(cellValues.id)}
            />
          </div>
        ),
      });
    }

    this.leadApi();
    this.setState({ columns: columns });
  }

  leadApi = async (sorting = []) => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
      sortBy: sorting,
      filter: this.state.filter,
      search: this.state.search,
      user_id: User.user_id,
      role_id: User.role_id,
    };
    const response = await leadList(requestData);
    console.log("response_leadApi", response);
    if (response && response.status === "success") {
      const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      if (rows.length > 0) {
        for (let i in rows) {
          apiRows.push(rows[i]);
        }
      }
      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          Id: result.data[i].id,
          name: result.data[i].first_name + " " + result.data[i].last_name,
          email: result.data[i].email,
          mobile: result.data[i].mobile,
          status: result.data[i].status,
          type: result.data[i].type,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  // Render Menu function for data table data
  popover = (id, currentUserRole) => {
    const role_id = User.role_id;
    return (
      <Popover id="popover-basic" className="border-0 p-3">
        <p className="my-1 pointer ">
          <i
            className="far fa-edit mr-2"
            onClick={() => this.editLead(id, currentUserRole)}
          >
            {" "}
            Edit
          </i>
        </p>
        {!role_id === 2 || !role_id === 3 || role_id === 1 ? (
          <p className="my-1 pointer " onClick={() => this.deleteLead(id)}>
            <i className="far fa-trash-alt mr-2"> Delete</i>
          </p>
        ) : null}
      </Popover>
    );
  };

  render() {
    if (User.refresh) {
      this.leadApi();
      User.setRefresh(false);
    }
    return (
      <Layout>
        <Header>
          <h3 className="font-style">Leads</h3>
          <div className="col-md-10 normal-font-size">
            <Tabs
              defaultActiveKey="overview"
              id="controlled-tab-example"
              onSelect={(menu) => this.setState({ menu })}
            >
              <Tab eventKey="overview" title="Overview" active />
            </Tabs>
          </div>
        </Header>
        <div className="m-3">
          <CreateLead
            id={User.user_id}
            editId={this.state.editLeadId}
            status={this.state.status}
            closeModel={() => this.setState({ status: false, isEdit: false })}
            isEdit={this.state.isEdit}
          />
          {this.renderLeadContent()}
          <div className="my-4">{this.renderTable()}</div>
        </div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewLeadConfirmModal}
          heading="Delete Lead"
          title="Are you sure you want to delete the leads?"
          confirm={() => this.getLeadSuccess()}
          handleClose={() => this.setState({ viewLeadConfirmModal: false })}
        />
      </Layout>
    );
  }
  renderLeadContent = () => {
    const role_id = User.role_id;
    return (
      <div className="row">
        {role_id === 2 ||
        role_id === 3 ||
        role_id === 4 ||
        role_id === 1 ||
        role_id === 6 ? (
          <div className="col-md-2 my-2">
            <button
              type="btn"
              className="btn theme-bg-color text-white fw-bold normal-font-size"
              onClick={() => this.setState({ status: true })}
            >
              Create Lead
            </button>
          </div>
        ) : null}
        <div className="border col-md-4 bg-white rounded border-secondary d-flex justify-content-between mx-3">
          <input
            type="text"
            className="no-outline input-style small-font-size w-100 my-3"
            placeholder="Search"
            onChange={this._handleSearchChange}
          />
          <i className="fas fa-search align-self-center" />
        </div>
        <div className="col-md-3">
          <CustomSelect
            className="black"
            placeholder="Filter by status"
            value={this.state.filter}
            onChange={this.sortingHandler}
            options={leadStatusOptions}
          />
        </div>
        {role_id === 2 || role_id === 3 || role_id === 1 || role_id === 6 ? (
          this.state.isMultipleDelete ? (
            <div className="col-md-2 my-2">
              <button
                type="btn"
                className="btn theme-bg-color text-white fw-bold normal-font-size"
                onClick={() => this.handleDeleteMultiple()}
              >
                Delete
              </button>
            </div>
          ) : null
        ) : null}
      </div>
    );
  };

  renderTable = () => {
    const CustomToolbar = () => {
      return (
        <div className="d-flex justify-content-end px-3 py-2">
          <GridToolbarExport />
        </div>
      );
    };
    return (
      <div style={{ height: 580, width: "100%" }}>
        <DataGrid
          className="black"
          rows={this.state.rows}
          columns={this.state.columns}
          pageSize={this.state.size}
          page={this.state.current_page - 1}
          onPageSizeChange={(size) =>
            this.setState({ size: size, current_page: 1 }, () => this.leadApi())
          }
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(current_page) =>
            this.setState({ current_page: parseInt(current_page) + 1 }, () =>
              this.leadApi()
            )
          }
          rowCount={this.state.totalData}
          checkboxSelection={true}
          disableSelection={true}
          hideFooterSelectedRowCount={true}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(ids) => {
            if (ids.length > 0) {
              console.log("selection_ids", ids);
              this.setState({ isMultipleDelete: true, leadIdsArr: [...ids] });
            } else {
              this.setState({ isMultipleDelete: false, leadIdsArr: [...ids] });
            }
          }}
        />
      </div>
    );
  };

  // Search handle function
  _handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, search: value }, () => {
      this.leadApi();
    });
  };

  // Sorting handler function
  sortingHandler = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, filter: value }, () => {
      this.leadApi();
    });
  };
  viewLead = (id) => {
    let path = "/view-leads/" + id;
    this.props.history.push(path);
  };

  // Edit project modal
  editLead = (id) => {
    console.log("editLead_id", id);
    Lead.setIsApiCall(true);
    this.setState({ editLeadId: id, status: true, isEdit: true });
  };

  // Delete project confirm modal
  deleteLead = (id) => {
    this.setState({ viewLeadConfirmModal: true, deleteId: id });
  };

  // Delete project api function
  getLeadSuccess = () => {
    const id = this.state.deleteId;
    deleteLead(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewLeadConfirmModal: false, rows: [] });
        AppConfig.setMessage("Lead Deleted Successfully", false);
        User.setRefresh(true);
        this.leadApi();
      } else {
        this.setState({ viewLeadConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  // Delete Multiple Lead
  handleDeleteMultiple = async () => {
    const requestBody = {
      id: [...this.state.leadIdsArr],
    };
    console.log("requestBody", requestBody);
    try {
      const response = await deleteMultipleLeads(requestBody);
      console.log("response", response);
      if (response && response.status === "success") {
        this.setState({ rows: [], totalData: 0, leadIdsArr: [] }, () => {
          this.leadApi();
          AppConfig.setMessage(response.result, false);
        });
      } else {
        AppConfig.setMessage(response.result);
      }
    } catch (err) {
      console.log("deleteMultipleLeads_err", err);
    }
  };
}
export default observer(Leads);
