import { makeAutoObservable } from "mobx";
import {
  makePersistable,
  clearPersistedStore,
  getPersistedStore,
  hydrateStore,
  isHydrated,
  isPersisting,
  startPersisting,
  pausePersisting,
  stopPersisting,
} from "mobx-persist-store";

class Lead {
  editId = "";
  isApiCall = false;

  constructor() {
    makeAutoObservable(this);
  }

  setEditId(id) {
    this.editId = id;
  }

  setIsApiCall(status) {
    this.isApiCall = status;
  }
}

export default new Lead();
