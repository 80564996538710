// Base url

global.baseUrl = "https://admin.madhusudanimpex.com/mm/api/v1/"; //admin.madhusudanimpex

// Function url

global.apiUrls = {
  // General urls
  login: "login",
  forgot: "request_otp",
  verify_otp: "verify_otp",
  resend_otp: "resend_otp",
  reset_password: "reset_password",
  change_password: "change_password",

  // Profile urls
  update_profile: "update_profile",
  upload_profile_pic: "upload_profileimage",

  // Lead urls
  lead: "lead",
  lead_list: "leads",
  lead_detail: "lead_detail",
  lead_assigned_to: "assigned_users",
  delete_multiple_leads: "delete_multiple_leads",

  // product urls

  product_list: "products",
  product: "product",
  qr_code: "barcode",
  delete_multiple_products: "delete_multiple_products",

  // gallery urls
  file: "upload_image",
  gallery: "productimage",

  // Admin Urls

  // location
  location: "location",
  location_list: "locations",
  location_dropdown: "location_dropdown",
  shade: "shade",
  shade_tablelist: "shades",
  shade_list: "shades_dropdown",
  architect: "architect",
  delete_multiple_locations: "delete_multiple_locations",
  delete_multiple_shades: "delete_multiple_shades",

  // Employee
  user: "user",
  user_list: "users",
  lotno_list: "lotno_dropdown",
  delete_multiple_users: "delete_multiple_users",

  // dashboard
  dashboard_list: "follow_up_leads",

  // notification
  notification_list: "notifications",
  notification: "notification",
  readall_notification: "readall_notification",
  clearall_notification: "clearall_notification",
};
