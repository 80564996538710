import { callApi, getQRXMLFile } from "../Api";
import "../Global";

/**
 *
 * @param{id,lot no, width, height,quantity, price etc..)data
 */
export const createProduct = async (requestData = {}) => {
  const functionUrl = "product";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{product list)data
 */
export const productList = async (requestData = {}) => {
  const functionUrl = "product_list";
  try {
    console.log("project fun url", functionUrl);
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single product) by id
 */
export const getProduct = async (id) => {
  const functionUrl = "product";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single product) by id
 */
export const updateProduct = async (requestData, id) => {
  const functionUrl = "product";
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{delete product) by id
 */
export const deleteProduct = async (id) => {
  const functionUrl = "product";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get qrcode) by product id
 */
export const getQRCode = async (id, callBack) => {
  const functionUrl = "qr_code";
  try {
    getQRXMLFile(functionUrl, callBack, id);
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

// Delete multiple Leads
export const deleteMultipleProducts = async (requestBody = {}) => {
  const functionUrl = "delete_multiple_products";
  try {
    return await callApi(functionUrl, requestBody, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
