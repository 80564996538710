// Add product modal
import React from "react";
import "../../../css/Common.css";
import { CustomInput } from "../../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../../css/Modal.css";
import { createArchitect } from "../../../apis/admin/Architect";
import AppConfig from "../../../modal/AppConfig";
import User from "../../../modal/User";
import { CheckName, CheckLocationName } from "../../../common/Validation";

class CreateArchitect extends React.Component {
  state = {
    createArchitectModal: false,
    name: "",
    projectName: "",
  };

  render() {
    return (
      <>
        <div className="d-flex justify-content-between w-100">
          <p
            className="my-0 py-2  mr-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ createArchitectModal: true });
            }}
          >
            Create Architect
          </p>
          <i className="fas fa-arrow-right align-self-center"></i>
        </div>
        {this.renderCreateArchitectModal()}
      </>
    );
  }

  // Render add Product modal function

  renderCreateArchitectModal() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.createArchitectModal}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <p className="font-style normal-font-size">Create Architect</p>
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={() => {
                this.setState({ createArchitectModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <form>
              <div className="py-1">
                <CustomInput
                  tabIndex="1"
                  type="text"
                  id="name"
                  placeholder="Name*"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({ name: e.target.value, nameError: "" })
                  }
                  onFocus={() => this.setState({ nameError: "" })}
                  error={this.state.nameError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  tabIndex="2"
                  type="text"
                  id="project-name"
                  placeholder="Project Name*"
                  value={this.state.projectName}
                  onChange={(e) =>
                    this.setState({
                      projectName: e.target.value,
                      projectNameError: "",
                    })
                  }
                  onFocus={() => this.setState({ projectNameError: "" })}
                  error={this.state.projectNameError}
                />
              </div>
              <button
                type="button"
                onClick={this.onSubmitCreateArchitect}
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
              >
                Save
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  // Validation for location
  validateArchitect = () => {
    const nameError = CheckName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Name empty" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Invalid Name" });
      return false;
    } else return true;
  };

  // Validation for address
  validateProject = () => {
    const projectNameError = CheckLocationName(this.state.projectName);
    if (projectNameError === 1) {
      this.setState({ projectNameError: "Project empty" });
      return false;
    } else if (projectNameError === 2) {
      this.setState({ projectNameError: "Invalid project" });
      return false;
    } else return true;
  };

  ValidateAll = () => {
    const nameInput = this.validateArchitect();
    const projectInput = this.validateProject();

    if (nameInput && projectInput) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit function for add product inputs

  onSubmitCreateArchitect = async (e) => {
    e.preventDefault();
    const allValidation = this.ValidateAll();
    if (allValidation) {
      const requestData = {
        name: this.state.name,
        project_name: this.state.projectName,
        created_by: User.user_id,
        user_id: User.user_id,
      };
      const response = await createArchitect(requestData);
      if (response.status === "success") {
        this.setState({
          createArchitectModal: false,
          name: "",
          projectName: "",
        });
        AppConfig.setMessage("Architect created successfully", false);
      } else if (response.status === "error") {
        // this.setState({ createArchitectModal: false });
        const result = response.result;
        let message = result;
        if (result[Object.keys(response.result)[0]]) {
          message = result[Object.keys(response.result)[0]];
        }
        AppConfig.setMessage(message);
      }
    }
  };
}
export default CreateArchitect;
