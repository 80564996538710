import User from '../modal/User';
import {callApi, uploadApi} from './Api';
import './Global';

/**
 *
 * @param{email, password)data
 */
export const login = async(requestData={}) => {
    const functionUrl = "login";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{email)data
 */
export const forgot = async(requestData={}) => {
    const functionUrl = "forgot";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};



/**
 *
 * @param{otp)data
 */
export const otp = async(requestData={}) => {
    const functionUrl = "verify_otp";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{otp)data
 */
 export const resendOTP = async(requestData={}) => {
    const functionUrl = "resend_otp";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};



/**
 *
 * @param{new & old password)data
 */
 export const resetPassword = async(requestData={}) => {
    const functionUrl = "reset_password";
    const id = User.user_id
    console.log("api id" , id);
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};



/**
 *
 * @param{new & old password)data
 */
export const changePassword = async(requestData={}) => {
    const functionUrl = "change_password";
    const id = User.user_id
    // console.log("api id" , id);
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 * Common Api for upload file or profile pic.
 * @param(file, callback) 
 * @return API response
*/
export const uploadFile = async (image, callBack) => {
	const functionUrl = 'file';
	try {
		const response = await uploadApi(functionUrl, image, callBack);
		if (response) {
			return callBack(response);
		}
	} catch (error) {
		console.log('Error from catch => ', error);
	}
}



