import {callApi} from '../Api';
import '../Global';

/**
 *
 * @param{add product image)data
 */
export const createGallery = async(requestData={}) => {
    const functionUrl = "gallery";
    console.log("api");
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{get single projectimage) by id
 */
export const updateGallery = async(requestData, id) => {
    const functionUrl = "gallery";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{delete projectimage ) by id
 */
export const deleteGallery = async(id) => {
    const functionUrl = "gallery";
    try {
        return await callApi(functionUrl, {}, 'DELETE', id )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single projectimage) by id
 */
export const getGallery = async(id) => {
    const functionUrl = "gallery";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};
