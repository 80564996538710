import { callApi } from "../Api";
import "../Global";

/**
 *
 * @param{id, name)data
 */
export const createShades = async (requestData = {}) => {
  const functionUrl = "shade";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get shade) form dropdown and checkbox
 */
export const shadesList = async (requestData = {}) => {
  const functionUrl = "shade_list";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{shades list)data
 */
export const shadeTableList = async (requestData = {}) => {
  const functionUrl = "shade_tablelist";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{update single shades) by id
 */
export const updateShade = async (requestData, id) => {
  const functionUrl = "shade";
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{delete shades) by id
 */
export const deleteShade = async (id) => {
  const functionUrl = "shade";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single shade) by id
 */
export const getShade = async (id) => {
  const functionUrl = "shade";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

// Delete multiple Leads
export const deleteMultipleShades = async (requestBody = {}) => {
  const functionUrl = "delete_multiple_shades";
  try {
    return await callApi(functionUrl, requestBody, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
