import {callApi} from './Api';
import './Global';

/**
 *
 * @param{dashboard list)data
 */
 export const dashboardList = async( requestData = {} ) => {
    const functionUrl = "dashboard_list";
    try {
        console.log('project fun url', functionUrl)
        return await callApi(functionUrl, requestData )
       
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};