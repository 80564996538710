// Notification popup

import React from "react";
import "../../css/Common.css";
import moment from "moment";
import {
  notificationList,
  clearAll,
  readAll,
  updateNotification,
} from "../../apis/Notification";
import User from "../../modal/User";

class Notification extends React.Component {
  state = {
    notifications: [],
    show: false,
  };

  async componentDidMount() {
    this.notificationListApi();
  }

  notificationListApi = async () => {
    const requestData = {
      user_id: User.user_id,
    };
    const response = await notificationList(User.user_id);
    const result = response?.result;
    const notification = result.notifications;
    let notifications = [];
    for (let i in notification?.data) {
      notifications.push(notification.data[i]);
    }
    this.setState({ notifications: notifications });
    User.setUnreadCount(result.count);
  };

  clearAllApi = async () => {
    let notificationId = [];
    let { notifications } = this.state;
    for (let i in notifications) {
      notificationId.push(notifications[i]["id"]);
    }
    const requestData = {
      clearAll: notificationId,
    };
    await clearAll(requestData);
    this.setState({ notifications: "" }, () => this.notificationListApi());
    this.props.closeNotification();
  };

  readAllApi = async () => {
    let notificationId = [];
    let { notifications } = this.state;
    for (let i in notifications) {
      notificationId.push(
        notifications[i]["id"],
        notifications[i]["status"] === "1"
      );
    }
    const requestData = {
      readAll: notificationId,
    };
    await readAll(requestData);
    this.setState({ notifications }, () => this.notificationListApi());
  };

  updateReadNotifificationApi = async (id) => {
    const requestData = {
      status: 1,
    };
    const response = await updateNotification(requestData, id);
    if (response && response.status === "success") {
      let { notifications } = this.state;
      for (let i in notifications) {
        if (notifications[i]["id"] === id) {
          notifications[i]["status"] = "1";
          break;
        }
      }
      this.setState({ notifications });
      User.setReadCount(User.unreadCount);
      console.log("noti", User.unreadCount);
    }
  };

  render() {
    console.log("this.state.notifications", this.state.notifications);
    return (
      <>
        <div className="p-2" style={{ height: "300px", overflow: "scroll" }}>
          {this.state.notifications.length > 0 &&
            this.state.notifications.map((notifications) => {
              let classStatus = "bg-white";
              if (notifications.status === "1") {
                classStatus = "notification-read";
              }
              return (
                <div
                  className={`my-2 p-2  ${classStatus}`}
                  key={notifications.id}
                  onClick={() =>
                    this.updateReadNotifificationApi(notifications.id)
                  }
                >
                  <span className="theme-font-color my-0">
                    {`${notifications.first_name} ${notifications.last_name}`}{" "}
                  </span>
                  <p className="small-font-size my-0 py-0 text-dark">
                    {notifications.message ? notifications.message : "-"}
                  </p>
                  {/* <p className="small-font-size theme-font-color text-right my-0 py-0">{notifications.name}</p> */}
                  <div className="d-flex justify-content-between">
                    <p className="xsmall-font-size theme-font-color">
                      {moment(notifications.created_at).format(
                        "DD-MM-YYYY HH:mm A"
                      )
                        ? moment(notifications.created_at).format(
                            "DD-MM-YYYY HH:mm A"
                          )
                        : "-"}
                    </p>
                    <p className="xsmall-font-size theme-font-color">
                      {notifications.mobile ? notifications.mobile : "-"}
                    </p>
                  </div>
                </div>
              );
            })}
          {this.state.notifications.length === 0 ? (
            <div className="text-center">
              <i
                className="far fa-bell theme-font-color pt-5 mt-5"
                style={{ fontSize: "50px" }}
              />{" "}
              <br />
              <p className="normal-font-size theme-font-color pt-2">
                You currently have no notfications.
              </p>
            </div>
          ) : null}
        </div>
        <div className="p-2 d-flex justify-content-between">
          <button
            className="small-font-size font-weight-bold border-0 no-outline"
            onClick={() => this.readAllApi()}
          >
            All Read
          </button>
          <button
            className="small-font-size font-weight-bold border-0 no-outline"
            onClick={() => this.clearAllApi()}
          >
            Clear All
          </button>
        </div>
      </>
    );
  }
}
export default Notification;
