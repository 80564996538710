import LeftMenu from "./menu/LeftMenu";
import { observer } from "mobx-react";
import AppConfig from "../modal/AppConfig";
import { Route, Redirect, useLocation } from "react-router-dom";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import "../css/Common.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  small: {
    width: 25,
    height: 25,
  },
}));

// Render layout
export const Layout = (props) => {
  return <LeftMenu children={props.children} />;
};

export const percentageCalculations = (allValue) => {
  const total = allValue[1];
  const value = allValue[0];
  if (total !== undefined && value !== undefined) {
    const afterPercentage = Math.round((value / total) * 100);
    return afterPercentage;
  }
  return 0;
};

/**
 *
 * @param{props} route elements
 * @returns page route
 */
export const PublicRoute = observer((props) => {
  const currentPath = useLocation();
  const routes = ["/", "/sign-in", "/forgot-password"];
  if (AppConfig.api_key && routes.includes(currentPath.pathname)) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...props} />;
});
/**
 *
 * @param {*} route elements
 * @returns square page route
 */
export const PrivateRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        AppConfig.api_key !== "" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
};

// Render custom input

export const CustomInput = (props) => {
  const classes = useStyles();
  let {
    placeholder,
    id,
    fieldStyle,
    wapperClass,
    fieldClass,
    customError,
    rightIcon,
    inputColor,
    icon,
    onClickIcon,
    error,
    ...rest
  } = props;
  wapperClass = wapperClass ? wapperClass : "";
  fieldClass = fieldClass ? fieldClass : "";
  return (
    <>
      <TextField
        className={classes.margin + " col-md-12 fontStyle"}
        id={id}
        label={placeholder}
        variant={fieldStyle}
        endAdornment={rightIcon}
        {...rest}
        InputProps={{
          endAdornment: (
            <i
              className={icon}
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={onClickIcon}
            />
          ),
        }}
      />
      {error ? (
        <small
          className="small-font-size theme-font-color"
          style={{ paddingLeft: "10px" }}
        >
          {error}
        </small>
      ) : null}
    </>
  );
};
export const CustomSelect = (props) => {
  let {
    wapperClass,
    fieldClass,
    error,
    placeholder,
    id,
    options,
    fieldStyle,
    defaultUnit,
    ...rest
  } = props;
  wapperClass = wapperClass ? wapperClass : "";
  fieldClass = fieldClass ? fieldClass : "";
  const fillData = () => {
    var rows = [];
    if (!defaultUnit || defaultUnit !== true) {
      rows.push(<option aria-label="None" value="" />);
    }
    if (options) {
      for (const [index, value] of options.entries()) {
        rows.push(
          <option key={index} value={value.value}>
            {value.label}
          </option>
        );
      }
      return <>{rows}</>;
    } else {
      return null;
    }
  };
  return (
    <>
      <FormControl className={wapperClass + "w-100"} variant={fieldStyle}>
        <InputLabel htmlFor={id ? id : null}>{placeholder}</InputLabel>
        <Select native {...rest}>
          {fillData()}
        </Select>
      </FormControl>
      {error ? (
        <small
          className="small-font-size theme-font-color"
          style={{ paddingLeft: "10px" }}
        >
          {error}
        </small>
      ) : null}
    </>
  );
};

// Render custom date picker

export const CustomDatePicker = (props) => {
  let { wrapperClass, fieldClass, error, label, id, onChange, value, ...rest } =
    props;
  wrapperClass = wrapperClass ? wrapperClass : "";
  fieldClass = fieldClass ? fieldClass : "";

  return (
    <div className={wrapperClass}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <KeyboardDateTimePicker
            id={id}
            label={label}
            format="dd/MM/yyyy"
            value={value}
            onChange={(val) => {
              onChange(val);
            }}
            InputLabelProps={{
              shrink: value ? true : false,
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
              shrink: true,
            }}
            className="w-100"
            {...rest}
          />
        </Grid>
      </MuiPickersUtilsProvider>

      {error ? (
        <small
          className="small-font-size theme-font-color"
          style={{ paddingLeft: "10px" }}
        >
          {error}
        </small>
      ) : null}
    </div>
  );
};
