// View lead page

import React from "react";
import { Layout, CustomDatePicker } from "../../common/Element";
import moment from "moment";
import Header from "../../common/Header";
import "../../css/Common.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createViewLead } from "../../apis/Lead";
import User from "../../modal/User";
import Avatar from "@material-ui/core/Avatar";
import "../../css/ViewLead.css";
import { getLead } from "../../apis/Lead";
import CreateLead from "./CreateLead";
import AppConfig from "../../modal/AppConfig";
import {
  CheckDate,
  CheckDescription,
  CheckMessage,
} from "../../common/Validation";

class ViewLead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuTab: "",
      comments: "",
      viewLeads: [],
      followupDate: null,
      status: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("id", params);
    if (params.id) {
      const id = params.id;
      this.setState({ id });
      const response = await getLead(id);
      let viewLeads = [];
      let result = response.result;
      for (let i in result.leaddetails) {
        viewLeads.push(result.leaddetails[i]);
      }
      if (response && response.status) {
        this.setState({
          id: result.id,
          name: result.leads.first_name + " " + result.leads.last_name,
          date: moment(result.leads.time_slot).format("Do MMMM  YYYY"),
          mobile: result.leads.mobile,
          email: result.leads.email,
          firstletter: result.firstletter,
          viewLeads: result.leaddetails,
        });
      }
    }
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  render() {
    return (
      <Layout>
        <Header>
          <h3 className="font-style">Leads</h3>
          <div className="col-md-10 normal-font-size">
            <Tabs
              defaultActiveKey="overview"
              id="controlled-tab-example"
              onSelect={(menuTab) => this.setState({ menuTab })}
            >
              <Tab eventKey="overview" title="Overview" active />
            </Tabs>
          </div>
        </Header>
        <div className="d-flex m-3">
          <button
            type="btn"
            className="btn theme-bg-color text-white fw-bold normal-font-size px-3"
            onClick={() => this.setState({ status: true })}
          >
            Create Lead
          </button>
          <div className="mx-3">
            <a
              className="text-decoration-none text-white normal-font-size font-style"
              href={"mailto:" + this.state.email}
            >
              <i className="theme-font-color pointer far fa-envelope giga-font-size" />
              <p className="xsmall-font-size theme-font-color mb-0 pointer">
                Mail
              </p>
            </a>
          </div>
          <div className="mx-3">
            <a
              className="text-decoration-none text-white normal-font-size font-style"
              href={"tel:" + this.state.mobile}
            >
              <i className="fas fa-phone-alt theme-font-color pointer giga-font-size"></i>
              <p className="xsmall-font-size theme-font-color mb-0 pointer">
                Call
              </p>
            </a>
          </div>
        </div>
        <div className="m-3">{this.renderViewLeads()}</div>
        <CreateLead
          id={User.user_id}
          editId={this.state.editLeadId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false })}
        />
      </Layout>
    );
  }

  // Render view lead function

  renderViewLeads() {
    const { viewLeads } = this.state;
    return (
      <div className="row">
        <div className="col-md-6 bg-white shadow rounded-lg p-3">
          <div className="d-flex justify-content-between">
            <div className="col-md-8">
              <div className="d-flex">
                <Avatar
                  variant="rounded"
                  className="text-dark"
                  style={{
                    backgroundColor: "#C1C1C3",
                    width: "60px",
                    height: "60px",
                    fontSize: "30px",
                  }}
                >
                  {this.state.firstletter}
                </Avatar>
                <div className="p-2 px-3">
                  <p className="small-font-size fw-bold ">
                    {this.state.name ? this.state.name : "-"}
                  </p>
                  <p className="small-font-size">
                    Contact Date: {this.state.date ? this.state.date : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <p className="small-font-size">
                {this.state.email ? this.state.email : "-"}
              </p>
              <p className="small-font-size">
                {this.state.mobile ? this.state.mobile : "-"}
              </p>
            </div>
          </div>
          <ul
            className="timeline"
            style={{ height: "450px", overflow: "scroll" }}
          >
            {viewLeads.length > 0 &&
              viewLeads.map((viewLead) => (
                <li>
                  <div className="mx-0 row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                      <div style={{ width: 50 }}>
                        <p
                          className="small-font-size my-0 py-0 text-dark text-justify"
                          dangerouslySetInnerHTML={{
                            __html: viewLead.comments,
                          }}
                        />
                      </div>
                      <div className="col-md-9 d-flex justify-content-between ml-0 pl-0 mt-2">
                        <p className="xsmall-font-size">
                          {moment(viewLead.follow_up_date).format("DD-MM-YYYY")}
                        </p>
                        <p className="xsmall-font-size">
                          {moment(viewLead.follow_up_date).format("HH:mm:ss a")}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6 px-5">
          <form>
            <div className="py-1">
              <CustomDatePicker
                className="black w-100"
                label="Next follow up date & time"
                selected={this.state.followupDate}
                value={this.state.followupDate}
                onChange={(value) => {
                  this.setState({ followupDate: value });
                }}
                onSelect={this.followupDateHandleChange}
                error={this.state.followupDateErr}
                minDate={moment("07/07/2021").format("DD/MM/YYYY")}
                onFocus={() => this.setState({ followupDateErr: "" })}
                disablePast
              />
            </div>
            <div className="my-3 editor-style">
              <ReactQuill
                value={this.state.comments}
                onChange={this.handleChange}
                modules={this.modules}
                formats={this.formats}
                placeholder="Comments"
                onFocus={() => this.setState({ descriptionError: "" })}
              />

              <div className="mx-4 my-3 d-flex justify-content-end">
                <button
                  type="submit"
                  className="theme-bg-color btn text-white normal-font-size fw-bold px-3"
                  onClick={this.onSubmitViewLead}
                >
                  Save
                </button>
              </div>
            </div>
            {this.state.descriptionError ? (
              <span className="small-font-size theme-font-color">
                {this.state.descriptionError}
              </span>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
  // Handle arrival change
  followupDateHandleChange = (followupDate) => {
    this.setState({ followupDate, followupDateErr: "" });
  };
  handleChange(value) {
    this.setState({ comments: value });
  }

  // Validation for follow up date
  validateFollowupDate = () => {
    const followupDateError = CheckDate(this.state.followupDate);
    if (followupDateError === 1) {
      this.setState({ followupDateErr: "Follow up date empty" });
      return false;
    }
    this.setState({ followupDateErr: "" });
    return true;
  };

  // Validation for comments
  validateDescription = () => {
    const descriptionError = CheckMessage(this.state.comments);
    if (descriptionError === 1) {
      this.setState({ descriptionError: "Comments empty" });
      return false;
    } else if (descriptionError === 2) {
      this.setState({ descriptionError: "Comments are short" });
      return false;
    } else {
      this.setState({ descriptionError: "" });
      return true;
    }
  };

  validateAll = () => {
    const commentsInput = this.validateDescription();
    const followupDateInput = this.validateFollowupDate();
    if (commentsInput && followupDateInput) {
      return true;
    } else {
      return false;
    }
  };

  onSubmitViewLead = async (e) => {
    e.preventDefault();
    const {
      match: { params },
    } = this.props;
    const id = params.id;

    const allValidation = await this.validateAll();
    console.log("allValidation", allValidation);

    // const allValidation = await this.validateAll();
    // if (allValidation) {
    if (allValidation) {
      const requestData = {
        lead_id: id,
        follow_up_date: moment(this.state.followupDate).format(
          "MMMM Do YYYY, h:mm:ss a"
        ),
        comments: this.state.comments,
        user_id: User.user_id,
      };
      const response = await createViewLead(requestData);
      if (response.status === "success") {
        this.props.history.push("/leads");
        AppConfig.setMessage("Comments added successfully", false);
      } else if (response.status === "error") {
        AppConfig.setMessage(response.result);
      }
    }
  };
  // }
}

export default ViewLead;
