
import { Link} from "react-router-dom";
import '../../css/Common.css';
import React from 'react';
import{ MenuItem} from "react-pro-sidebar";
// import '../../css/menu.css';


export const  Item = props => {
  const {title, active, link, className } = props;

  return (
        <MenuItem 
            style={{backgroundColor:'#F3F3F3'}}
            icon={
                <i 
                    className={className}  
                    size={20}
                    style={
                        active 
                            ? {color:"#a03238"}
                            : {color:"#0c0f12"}
                    }
                />
            }
        > 
            <Link
                className="text-decoration-none"
                to={link}
                style={
                    active 
                        ? { color: "#a03238" }
                        : { color: "#0c0f12" }    
                }  
            > 
                <span 
                    style={
                        active 
                            ? { color: "#a03238" }
                            : { color: "#0c0f12" }
                    }> 
                    {title}
                </span>
            </Link>
        </MenuItem>
    );

}

