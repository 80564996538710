import { makeAutoObservable} from "mobx"
import { makePersistable , clearPersistedStore, getPersistedStore, hydrateStore, isHydrated, isPersisting, startPersisting,  pausePersisting, stopPersisting } from 'mobx-persist-store';

class AppConfig {
	loader = false;
    message = '';
    status = false;
    error = '';
	api_key = '';
	qrCode = '';

	constructor() {
        makeAutoObservable(this)
		makePersistable(this, { name: 'App', properties: ['api_key'], storage: window.sessionStorage });
    }

	setApiKey(value) {
		this.api_key = value;
	}

	setLoader(value) {
		this.loader = value;
	}

	setQRCode(value) {
		this.qrCode = value;
	}
	get isHydrated() {
		return isHydrated(this);
	}
	
	get isPersisting() {
		return isPersisting(this);
	}

	startPersist() {
		startPersisting(this);
	}


	async hydrateStore() {
		await hydrateStore(this);
	}

	async clearStoredDate() {
		this.api_key = '';
		this.user_id = '';
		await clearPersistedStore(this);
	}
	  
	async getStoredData() {
		return getPersistedStore(this);
	}

	pausePersist(){
		pausePersisting(this);
	}
	
	disposePersist(){
		stopPersisting(this);
	}
	
	async rehydrateStore() {
		await hydrateStore(this);
	}

	setMessage(message, error=true) {
		if(error) {
			this.error = message;
		}else {
			this.success = message;
		}
		if(message) {
			this.status = true;
		}else {
			this.status = false;
		}
	}

};

export default new AppConfig();
