// Forgot passsword Page

import React from "react";
import {
	CheckUserName,
	CheckPassword,
	ComparePassword,
	OtpCheck,
} from "../../common/Validation";
import { forgot, otp, resetPassword, resendOTP } from "../../apis/General";
import { Link } from "react-router-dom";
import AppConfig from "../../modal/AppConfig";
import { CustomInput } from "../../common/Element";
import LoginBgImg from "../../assets/login/loginbg.jpg";
import Otp from "../../common/Otp";
import User from "../../modal/User";

class ForgotPassword extends React.Component {
	state = {
		username: "",
		otp: "",
		password: "",
		confirmPassword: "",
		page: "",
		showPassword: false,
		showConfirmPassword: "",
		status: false,
	};

	render() {
		return (
			<div
				className="login-bg"
				style={{ backgroundImage: `url(${LoginBgImg})` }}
			>
				<div className="col-md-4">
					<div className="login-form-container">
						{this.renderPage()}
					</div>
				</div>
			</div>
		);
	}

	renderPage() {
		if (this.state.page === "otp") {
			return this.renderOtpForm();
		} else if (this.state.page === "password") {
			return this.renderResetForm();
		} else {
			return this.renderForgotPasswordForm();
		}
	}

	// Render forgot password function

	renderForgotPasswordForm() {
		return (
			<div className="col-md-12  p-4 my-5">
				<h4 className="font-style text-center py-3">Forgot Password</h4>
				<p className="font-style medium-font-size">
					Please enter your mail or mobile number.
				</p>
				<form onSubmit={this.onSubmitForgotPassword}>
					<div className="py-3">
						<CustomInput
							type="text"
							id="email-mobile"
							tabIndex="1"
							label="Email / Mobile"
							value={this.state.userName}
							onChange={(e) =>
								this.setState({
									username: e.target.value,
									usernameError: "",
								})
							}
							onFocus={() => this.setState({ usernameError: "" })}
							error={this.state.usernameError}
						/>
					</div>
					<Link to="/sign-in" className="text-decoration-none">
						<p className="small-font-size text-dark font-weight-bold my-3  mx-2">
							Login
						</p>
					</Link>
					<button
						type="submit"
						className="btn theme-bg-color text-white col-md-12 font-style my-3"
					>
						Continue
					</button>
				</form>
			</div>
		);
	}

	//Render otp function

	renderOtpForm() {
		return (
			<div className="col-md-12 p-4 my-5">
				<h4 className="theme-font-color font-style mb-3 text-center">
					OTP
				</h4>
				<form onSubmit={this.onSubmitOtp}>
					<Otp getOtp={(otp) => this.getOtp(otp)} />
					{this.state.otpError ? (
						<div className="d-flex justify-content-center">
							<span className="small-font-size theme-font-color text-center">
								{this.state.otpError}
							</span>
						</div>
					) : (
						""
					)}
					<div className="d-flex justify-content-between">
						<Link to="/sign-in" className="text-decoration-none">
							<p className="small-font-size text-dark font-weight-bold my-3  mx-2">
								Login
							</p>
						</Link>
						<button
							className="no-outline border-0 bg-transparent"
							onClick={this.onPressResendOTP}
						>
							<u classname="small-font-size">Resend OTP</u>
						</button>
					</div>
					<button
						type="submit"
						className="btn theme-bg-color text-white col-md-12 font-style my-3"
					>
						Verify
					</button>
				</form>
			</div>
		);
	}

	// render reset form

	renderResetForm = () => {
		return (
			<div className="col-md-12 p-4 my-5">
				<h4 className="font-style mb-3 text-center">Reset Password</h4>
				<form onSubmit={this.onSubmitResetPassword}>
					<div className="py-3">
						<CustomInput
							tabIndex="1"
							type={this.state.showPassword ? "text" : "password"}
							id="password"
							placeholder="New Password"
							value={this.state.password}
							onChange={(e) =>
								this.setState({
									password: e.target.value,
									newPasswordError: "",
								})
							}
							onFocus={() =>
								this.setState({ newPasswordError: "" })
							}
							icon={
								this.state.showPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showPassword: !this.state.showPassword,
								})
							}
							error={this.state.newPasswordError}
						/>
					</div>
					<div className="py-3">
						<CustomInput
							tabIndex="2"
							type={
								this.state.showConfirmPassword
									? "text"
									: "password"
							}
							id="password"
							placeholder="Confirm Password"
							value={this.state.confirmPassword}
							onChange={(e) =>
								this.setState({
									confirmPassword: e.target.value,
									confirmPasswordError: "",
								})
							}
							onFocus={() =>
								this.setState({ confirmPasswordError: "" })
							}
							icon={
								this.state.showConfirmPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showConfirmPassword:
										!this.state.showConfirmPassword,
								})
							}
							error={this.state.confirmPasswordError}
						/>
					</div>
					<Link to="/sign-in" className="text-decoration-none">
						<p className="small-font-size text-dark font-weight-bold my-3  mx-2">
							Login
						</p>
					</Link>
					<button
						type="submit"
						className="btn theme-bg-color text-white col-md-12 font-style my-3"
					>
						Submit
					</button>
				</form>
			</div>
		);
	};

	// Validation for username

	validateUserName = () => {
		const usernameErr = CheckUserName(this.state.username);
		if (usernameErr && usernameErr === 1) {
			this.setState({ usernameError: "Empty email / phone no." });
			return false;
		} else if (usernameErr && usernameErr === 2) {
			this.setState({ usernameError: "Enter valid email" });
			return false;
		} else if (usernameErr && usernameErr === 3) {
			this.setState({ usernameError: "Enter valid phone" });
			return false;
		}
		return true;
	};

	// Validation for password
	passwordCheck = () => {
		const newPasswordError = CheckPassword(this.state.password);
		if (newPasswordError && newPasswordError === 1) {
			this.setState({ newPasswordError: "Empty new password" });
			return false;
		} else if (newPasswordError && newPasswordError === 2) {
			this.setState({ newPasswordError: "Enter valid new password" });
			return false;
		}
		return true;
	};

	// Validation for confirm password
	confirmPasswordCheck = () => {
		const conPasswordError = CheckPassword(this.state.confirmPassword);
		if (conPasswordError && conPasswordError === 1) {
			this.setState({ confirmPasswordError: "Empty Confirm password" });
			return false;
		}
		const comPasswordError = ComparePassword(
			this.state.password,
			this.state.confirmPassword
		);
		if (comPasswordError && comPasswordError === 1) {
			this.setState({ confirmPasswordError: "Password doesn't match" });
			return false;
		}
		return true;
	};

	// Empty input validation
	validateAll = async () => {
		const passwordInput = await this.passwordCheck();
		const confirmPasswordInput = await this.confirmPasswordCheck();
		if (passwordInput && confirmPasswordInput) {
			return true;
		}
		return false;
	};

	getOtp = (otp) => {
		this.setState({ otp });
	};

	// onsubmit function for forget password

	onSubmitForgotPassword = async (e) => {
		e.preventDefault();
		const usernameErr = this.validateUserName();
		if (usernameErr) {
			const requestData = {
				username: this.state.username,
			};
			const response = await forgot(requestData);
			if (response && response.status === "success") {
				this.setState({ page: "otp" });
				User.setUserId(response.result.user_id);
			} else if (response.status === "error") {
				AppConfig.setMessage(response.result);
			}
		}
	};

	// onsubmit function for otp

	onSubmitOtp = async (e) => {
		e.preventDefault();
		const otpError = OtpCheck(this.state.otp);
		if (otpError && otpError === 1) {
			this.setState({ otpError: "Empty otp" });
			return false;
		} else if (otpError && otpError === 2) {
			this.setState({ otpError: "Enter valid otp" });
			return false;
		}
		this.setState({ otpError: "" });
		const requestBody = {
			otp: this.state.otp,
			user_id: User.user_id,
		};
		const response = await otp(requestBody);
		if (response && response.status === "success") {
			AppConfig.setMessage(response.result, false);
			this.setState({ page: "password" });
		} else if (response.status === "error") {
			AppConfig.setMessage(response.result);
		}
		return false;
	};

	// onsubmit function for reset password

	onSubmitResetPassword = async (e) => {
		e.preventDefault();
		const allValidation = await this.validateAll();
		if (allValidation) {
			const requestData = {
				password: this.state.password,
				confirmPassword: this.state.confirmPassword,
			};
			const response = await resetPassword(requestData, User.user_id);
			if (response && response.status === "success") {
				this.props.history.push("./sign-in");
			} else if (response.status === "error") {
				AppConfig.setMessage(response.result);
			}
		}
	};

	onPressResendOTP = async (event) => {
		console.log("User id", User.user_id);
		event.preventDefault();
		const requestData = {
			user_id: User.user_id,
		};
		const response = await resendOTP(requestData);
		if (response && response.status === "success") {
			this.setState({ page: "otp" });
		} else if (response.status === "error") {
			AppConfig.setMessage(response.result);
		}
		return false;
	};
}

export default ForgotPassword;
