import React from "react";
import { observer } from "mobx-react";
import Toast from 'react-bootstrap/Toast';
import AppConfig from '../modal/AppConfig';
import '../css/Common.css';

class ToastMessage extends React.Component {
    render(){
        const status = AppConfig.status;
        const success = AppConfig.success;
        const error = AppConfig.error;
        let message = 'Error!';
        let classMessage = 'alert-danger';
        let classIconMessage = 'fas fa-times';
        if(success) {
            message = 'Success!';
            classMessage = 'alert-success';
            classIconMessage = 'fas fa-check';
        }
        return (
            <>
            {success !== '' || error !== '' ? 
                <div className="shadow position-absolute" style={{right: '2%', top: '10%', zIndex:'999'}}>
                    <Toast onClose={this.closeMessage}  show={status} delay={5000} autohide >
                        <Toast.Header className={`normal-font-size fw-bold ${classMessage}`} closeButton={false}>
                            <i className={`${classIconMessage} mr-3`}/>
                            <span className={`normal-font-size font-style fw-bold ${classMessage}`}>{message}</span>
                        </Toast.Header>
                        <Toast.Body className='normal-font-size bg-white'>
                            <p className='normal-font-size font-style text-dark'>{success ? success : error}</p>
                        </Toast.Body>
                    </Toast>
                </div>
                :
                null
            }
            </>
        );
    }
    
    //close message 
    closeMessage = () => {
        AppConfig.setMessage('', true);
        AppConfig.setMessage('', false);
    }
}

export default observer(ToastMessage);