// Add product modal
import React from "react";
import "../../../css/Common.css";
import {
  CustomInput,
  CustomSelect,
  CustomDatePicker,
} from "../../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../../css/Modal.css";
import AppConfig from "../../../modal/AppConfig";
import {
  unitOptions,
  quantityUnitOptions,
} from "../../../common/DropdownOptions";
import { getLocationDropdown } from "../../../apis/admin/Location";
import moment from "moment";
import {
  createProduct,
  updateProduct,
  getProduct,
} from "../../../apis/admin/Product";
import { shadesList } from "../../../apis/admin/Shades";
import User from "../../../modal/User";
import {
  CheckDate,
  CheckLotNo,
  CheckProductCode,
  DropDownCheck,
  CheckName,
} from "../../../common/Validation";
import CustomSpinner from "../../../common/Spinner";
import { uploadFile } from "../../../apis/General";
import { callApi } from "../../../apis/Api";

class AddProduct extends React.Component {
  constructor() {
    super();
    // this.shadeTypeChange = this.shadeTypeChange.bind(this);
    this.state = {
      addProductModal: false,
      productCode: "",
      lotNo: "",
      shades: "",
      width: "",
      widthUnit: "",
      height: "",
      heightUnit: "",
      thickness: "",
      thicknessUnit: "",
      quantity: "",
      quantityUnit: "",
      sellingPrice: "",
      location: "",
      lotArrivalDate: null,
      remarks: "",
      locationNameList: [],
      shadeList: [],
      shadeType: [],
      isApiResponse: false,
      productImagesArr: [],
      actualProductImagesArr: [],
      isDisablePast: true,
    };
  }

  async componentDidMount() {
    // Api call for location dropdown
    const response = await getLocationDropdown();
    let locationNameList = [];
    if (response && response.status === "success") {
      const result = response.result.location_dropdown;
      for (let i in result) {
        let locationName = {
          value: result[i].id,
          label: result[i].name,
        };
        locationNameList.push(locationName);
      }
    }
    // Api call for shade dropdown
    const shadeResponse = await shadesList();
    let shadeList = [];
    if (shadeResponse && shadeResponse.status === "success") {
      const result = shadeResponse.result.shades_dropdown;
      for (let i in result) {
        let shade = {
          value: result[i].id,
          label: result[i].name,
        };
        shadeList.push(shade);
      }
    }
    this.setState({ locationNameList: locationNameList, shadeList: shadeList });
  }

  async componentDidUpdate(prevProps) {
    const id = this.props.editProductId;
    if (this.props.status !== prevProps.status) {
      this.setState({ addProductModal: this.props.status });
      const response = await getProduct(id);
      console.log("API Response => ", response);
      if (response && response.status === "success") {
        let result = response.result?.products[0];
        this.setState({
          isApiResponse: true,
          productCode: result.product_code,
          lotNo: result.lot_no,
          location: result.location_id,
          shades: result.shade_id,
          width: result.width,
          widthUnit: result.width_unit,
          height: result.height,
          heightUnit: result.height_unit,
          thickness: result.thickness,
          thicknessUnit: result.thickness_unit,
          lotArrivalDate: result.lot_arrival_date,
          isDisablePast: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isDisablePast: true });
  }

  render() {
    return (
      <>
        {this.props.hideButton ? (
          <div className="d-flex justify-content-between w-100">
            <p
              className="my-0 py-2 mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ addProductModal: true });
              }}
            >
              Add Product
            </p>
            <i className="fas fa-arrow-right align-self-center"></i>
          </div>
        ) : null}
        {this.renderAddProductModal()}
      </>
    );
  }

  // Render add Product modal function

  renderAddProductModal() {
    console.log("this.props.status_addProduct", this.props.isEdit);
    console.log("this.state.lotArrivalDate", this.state.lotArrivalDate);
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.addProductModal}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <div className="d-flex">
              <p
                className="font-style normal-font-size"
                style={{ marginRight: "10px" }}
              >
                {this.props.isEdit ? "Update Product" : "Create Product"}
              </p>
              {this.props.isEdit && !this.state.isApiResponse ? (
                <CustomSpinner animationType="grow" spinnerSize="sm" />
              ) : null}
            </div>
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={this.handelClose}
            />
          </div>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="py-1">
                  <CustomInput
                    tabIndex="1"
                    type="text"
                    id="productCode"
                    placeholder="Product Code*"
                    value={this.state.productCode}
                    onChange={(e) =>
                      this.setState({
                        productCode: e.target.value,
                        productCodeError: "",
                      })
                    }
                    onFocus={() => this.setState({ productCodeError: "" })}
                    error={this.state.productCodeError}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <CustomInput
                    tabIndex="2"
                    type="text"
                    id="lot-no"
                    placeholder="Lot No.*"
                    value={this.state.lotNo}
                    onChange={(e) =>
                      this.setState({ lotNo: e.target.value, lotNoError: "" })
                    }
                    onFocus={() => this.setState({ lotNoError: "" })}
                    error={this.state.lotNoError}
                  />
                </div>
                <div className="col-md-6 py-2">
                  <CustomSelect
                    tabIndex="3"
                    placeholder="Select Shades*"
                    value={this.state.shades}
                    onChange={(e) => this.setState({ shades: e.target.value })}
                    options={this.state.shadeList}
                    error={this.state.shadeError}
                  />
                </div>
              </div>
              {/* <div className="form-check form-check-inline">
                                <input className="form-check-input" 
                                    type="checkbox" 
                                    id="WALL_SHADE" 
                                    value='WALL_SHADE'
                                   onChange = {(e) => this.shadeTypeChange(e, 'WALL_SHADE')}    
                                />
                                <label className="form-check-label" for="WALL_SHADE">Wall Shade</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="FLOOR_SHADE" 
                                    value='FLOOR_SHADE'
                                     onChange = {(e) => this.shadeTypeChange(e, 'FLOOR_SHADE')}    
                                />
                                <label className="form-check-label" for="FLOOR_SHADE">Floor Shade</label>
                            </div> */}
              <div className="row">
                <span className="normal-font-size my-3">Dimension</span>
                <div className="d-flex justify-content-between col-md-4">
                  <div className="col-md-7">
                    <CustomInput
                      tabIndex="4"
                      type="tel"
                      id="width"
                      placeholder="Width*"
                      value={this.state.width}
                      onChange={(e) => {
                        if (e.target.value > "0") {
                          console.log("e.target,value", e.target.value.length);
                          if (e.target.value.length < "8") {
                            this.setState({
                              width: e.target.value,
                              widthError: "",
                            });
                          }
                        } else if (e.target.value < "0") {
                          if (e.target.value === "") {
                            this.setState({
                              width: "",
                              widthError: "",
                            });
                            return false;
                          }
                          this.setState({
                            width: "",
                            widthError: "Enter a valid width",
                          });
                        }
                      }}
                      onFocus={() => this.setState({ widthError: "" })}
                      error={this.state.widthError}
                    />
                  </div>
                  <div className="col-md-4 pt-2">
                    <CustomSelect
                      tabIndex="5"
                      id="width-unit"
                      placeholder="Ut"
                      value={this.state.widthUnit}
                      onChange={(e) =>
                        this.setState({ widthUnit: e.target.value })
                      }
                      options={unitOptions}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-4">
                  <div className="col-md-7">
                    <CustomInput
                      tabIndex="6"
                      type="tel"
                      id="height"
                      placeholder="Height*"
                      value={this.state.height}
                      onChange={(e) => {
                        if (e.target.value > "0") {
                          if (e.target.value.length < "8") {
                            this.setState({
                              height: e.target.value,
                              heightError: "",
                            });
                          }
                        } else if (e.target.value < "0") {
                          if (e.target.value === "") {
                            this.setState({
                              height: "",
                              heightError: "",
                            });
                            return false;
                          }
                          this.setState({
                            height: "",
                            heightError: "Enter a valid height",
                          });
                        }
                      }}
                      onFocus={() => this.setState({ heightError: "" })}
                      error={this.state.heightError}
                    />
                  </div>
                  <div className="col-md-4 pt-2">
                    <CustomSelect
                      tabIndex="7"
                      placeholder="Ut"
                      value={this.state.heightUnit}
                      onChange={(e) =>
                        this.setState({ heightUnit: e.target.value })
                      }
                      options={unitOptions}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-4">
                  <div className="col-md-7">
                    <CustomInput
                      tabIndex="8"
                      type="tel"
                      id="thickness"
                      placeholder="Thick*"
                      value={this.state.thickness}
                      onChange={(e) => {
                        if (e.target.value > "0") {
                          if (e.target.value.length < "8") {
                            this.setState({
                              thickness: e.target.value,
                              thicknessError: "",
                            });
                          }
                        } else if (e.target.value < "0") {
                          if (e.target.value === "") {
                            this.setState({
                              thickness: "",
                              thicknessError: "",
                            });
                            return false;
                          }
                          this.setState({
                            thickness: "",
                            thicknessError: "Enter a valid thickness",
                          });
                        }
                      }}
                      onFocus={() => this.setState({ thicknessError: "" })}
                      error={this.state.thicknessError}
                    />
                  </div>
                  <div className="col-md-4 py-2">
                    <CustomSelect
                      tabIndex="9"
                      placeholder="Ut"
                      value={this.state.thicknessUnit}
                      onChange={(e) =>
                        this.setState({ thicknessUnit: e.target.value })
                      }
                      options={unitOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between col-md-6">
                  <div className="col-md-7 mr-2">
                    <CustomInput
                      tabIndex="10"
                      type="tel"
                      id="quantity"
                      placeholder="Quantity"
                      value={this.state.quantity}
                      onChange={(e) => {
                        if (e.target.value > "0") {
                          if (e.target.value.length < "8") {
                            this.setState({
                              quantity: e.target.value,
                              quantityError: "",
                            });
                          }
                        } else if (e.target.value < "0") {
                          if (e.target.value === "") {
                            this.setState({
                              quantity: "",
                              quantityError: "",
                            });
                            return false;
                          }
                          this.setState({
                            quantity: "",
                            quantityError: "Enter a valid quantity",
                          });
                        }
                      }}
                      onFocus={() => this.setState({ quantityError: "" })}
                      error={this.state.quantityError}
                    />
                  </div>
                  <div className="col-md-4 py-2">
                    <CustomSelect
                      tabIndex="11"
                      placeholder="Ut"
                      value={this.state.quantityUnit}
                      onChange={(e) =>
                        this.setState({ quantityUnit: e.target.value })
                      }
                      options={quantityUnitOptions}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomInput
                    type="tel"
                    tabIndex="12"
                    id="selling-price"
                    placeholder="Selling Price"
                    value={this.state.sellingPrice}
                    onChange={(e) => {
                      if (e.target.value > "0") {
                        this.setState({
                          sellingPrice: e.target.value,
                          sellingPriceError: "",
                        });
                      } else if (e.target.value < "0") {
                        if (e.target.value === "") {
                          this.setState({
                            sellingPrice: "",
                            sellingPriceError: "",
                          });
                          return false;
                        }
                        this.setState({
                          sellingPrice: "",
                          sellingPriceError: "Enter a valid price",
                        });
                      }
                    }}
                    onFocus={() => this.setState({ sellingPriceError: "" })}
                    error={this.state.sellingPriceError}
                  />
                </div>
              </div>
              <CustomSelect
                tabIndex="13"
                placeholder="Select Location*"
                value={this.state.location}
                onChange={(e) => this.setState({ location: e.target.value })}
                options={this.state.locationNameList}
                error={this.state.locationError}
              />
              <CustomDatePicker
                tabIndex="14"
                label="Lot Arrival Date*"
                selected={this.state.lotArrivalDate}
                value={this.state.lotArrivalDate}
                onChange={(value) => {
                  this.setState({ lotArrivalDate: value });
                }}
                onSelect={this.lotArrivalDateHandleChange}
                error={this.state.lotArrivalError}
                disablePast={this.state.isDisablePast}
                onClick={() => this.setState({ isDisablePast: true })}
              />
              <div className="py-1">
                <CustomInput
                  tabIndex="15"
                  type="text"
                  id="remarks"
                  placeholder="Give your remarks"
                  value={this.state.remarks}
                  multiline
                  rows={4}
                  onChange={(e) =>
                    this.setState({ remarks: e.target.value, remarksError: "" })
                  }
                  onFocus={() => this.setState({ remarksError: "" })}
                  error={this.state.remarksError}
                />
              </div>
              <div
                style={{
                  width: "30%",
                  // height: "100px",
                  // backgroundColor: "rgba(191,191,191, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="theme-font-color">Upload Image</p>
                <i
                  className="fas fa-plus-circle theme-font-color"
                  style={{
                    fontSize: 15,
                    alignSelf: "baseline",
                    marginTop: "5px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={this.handleUploadImage}
                />
                <input
                  type="file"
                  multiple
                  id="uploadProductPics"
                  className="d-none"
                  accept=".png, .jpg, .jpeg"
                  onChange={this.selectUploadPics}
                />
              </div>

              <div className="d-flex flex-row flex-wrap">
                {this.state.productImagesArr.length > 0 &&
                  this.state.productImagesArr.map((data, index) => (
                    <div className="d-flex flex-column align-items-center">
                      <img
                        src={data}
                        style={{
                          width: "100px",
                          height: "100px",
                          marginRight:
                            index === 0
                              ? "10px"
                              : index % 3 === 0
                              ? "0px"
                              : "10px",
                          marginBottom: "7px",
                        }}
                      />
                      <i
                        className="far fa-trash-alt pointer"
                        style={{ marginBottom: "10px" }}
                        onClick={() => {
                          this.deleteGallery(index);
                        }}
                      />
                    </div>
                  ))}
              </div>

              <button
                type="button"
                onClick={this.onSubmitAddProduct}
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
              >
                {this.props.isEdit ? "Update Product" : "Create Product"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  // shadeTypeChange(e) {
  //     console.log(e.target.value, 'check');
  //     this.setState({
  //         shadeType: e.target.value,
  //     });
  // }

  // shadeTypeChange(e, key) {
  //     const value = e.target.value;
  //     let isChecked = e.target.checked;
  //     let shadeType = this.state.shadeType;
  //     shadeType[key] = '';
  //     if(isChecked) {
  //         shadeType[key] = value;
  //     }
  //     this.setState({
  //         shadeType: shadeType,
  //     });
  //     console.log('shade type', this.state.shadeType)
  // }

  handelClose = () => {
    this.setState({ addProductModal: false });
    this.props?.closeModel(false);
  };

  // Delete gallery confirm modal
  deleteGallery = (id) => {
    this.state.productImagesArr.splice(id, 1);
    this.setState({ productImagesArr: [...this.state.productImagesArr] });
  };

  // Render product image

  renderProductImage = () => {
    return (
      <div className="d-flex">
        <div className="col-md-10 border border-dark rounded bg-white">
          <div className="d-flex">
            <i className="fas fa-images theme-font-color big-font-size m-2 pointer" />
            <p className="xSmallText d-flex align-self-center m-2">
              {this.state.productImage.name
                ? this.state.productImage.name
                : null}
            </p>
          </div>
        </div>
        <div className="col-md-2">
          <i
            className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer"
            onClick={() => this.setState({ productImage: "" })}
          ></i>
        </div>
      </div>
    );
  };

  selectUploadPics = (e) => {
    e.preventDefault();
    const [file] = e.target.files;
    console.log("file", file);
    const image = URL.createObjectURL(file);
    const uploadPics = e.target.files[0];
    console.log("uploadPics=>", uploadPics);
    this.state.productImagesArr.push(image);
    this.state.actualProductImagesArr.push(uploadPics);
    this.setState({
      productImagesArr: this.state.productImagesArr,
      actualProductImagesArr: this.state.actualProductImagesArr,
      // uploadPics: response.result.productimage,
      galleryCollection: [],
    });
    // const type = "uploadProductPics";
    // uploadFile(uploadPics, this.callBackUploadPics, type);
  };

  callBackUploadPics = (response = false) => {
    if (response && response.status === "success") {
      console.log("callback_response", response);
      const imagesArr = [];
      imagesArr.push(response.result.productimage);
      console.log("imagesArr", imagesArr);
      this.state.productImagesArr.push(response.result.productimage);
      this.setState({
        productImagesArr: this.state.productImagesArr,
        // uploadPics: response.result.productimage,
        galleryCollection: [],
      });
    }
  };

  handleUploadImage = () => {
    // e.preventDefault();
    const fileSelector = document.getElementById("uploadProductPics");
    fileSelector.click();
  };

  // Handle arrival change
  lotArrivalDateHandleChange = (lotArrivalDate) => {
    this.setState({ lotArrivalDate, lotArrivalDateErr: "" });
  };

  // Validation for product code
  validateProductCode = () => {
    const productCodeError = CheckProductCode(this.state.productCode);
    if (productCodeError === 1) {
      this.setState({ productCodeError: "Product code empty" });
      return false;
    } else if (productCodeError === 2) {
      this.setState({
        productCodeError:
          "Product code should be minimum 1 to 5 alphanumeric chracters (Eg: PR001)",
      });
      return false;
    } else return true;
  };

  // Validation for lot no
  validateLotNo = () => {
    const lotNoError = CheckLotNo(this.state.lotNo);
    if (lotNoError === 1) {
      this.setState({ lotNoError: "Lot no. empty" });
      return false;
    } else if (lotNoError === 2) {
      this.setState({
        lotNoError:
          "Lot No should be minimum 1 to 5 alphanumeric chracters (Eg: PR001)",
      });
      return false;
    } else return true;
  };

  // Validation for shade
  validateShade = () => {
    const shadeError = DropDownCheck(this.state.shades);
    if (shadeError === 1) {
      this.setState({ shadeError: "Select shades" });
      return false;
    } else return true;
  };

  // Validation for width
  validateWidth = () => {
    const widthError = DropDownCheck(this.state.width, this.state.widthUnit);
    if (widthError === 1) {
      this.setState({ widthError: "Width & width unit empty" });
      return false;
    } else return true;
  };

  // Validation for height
  validateHeight = () => {
    const heightError = DropDownCheck(this.state.height, this.state.heightUnit);
    if (heightError === 1) {
      this.setState({ heightError: "Height & height unit empty" });
      return false;
    } else return true;
  };

  // Validation for thickness
  validateThickness = () => {
    const thicknessError = DropDownCheck(
      this.state.thickness,
      this.state.thicknessUnit
    );
    if (thicknessError === 1) {
      this.setState({
        thicknessError: "Thickness & thickness unit empty",
      });
      return false;
    } else return true;
  };

  // Validation for location
  validateLocation = () => {
    const locationError = DropDownCheck(this.state.location);
    if (locationError === 1) {
      this.setState({ locationError: "Select location empty" });
      return false;
    } else return true;
  };

  // Validation for shade
  validateLotArrivalDate = () => {
    const lotArrivalError = CheckDate(this.state.lotArrivalDate);
    if (lotArrivalError === 1) {
      this.setState({ lotArrivalError: "Select lot arrival date empty" });
      return false;
    } else return true;
  };

  // Validation for remarks
  validateRemarks = () => {
    const remarksError = CheckName(this.state.remarks);
    if (remarksError === 2) {
      this.setState({ remarksError: "Invalid remarks" });
      return false;
    } else return true;
  };

  ValidateAll = () => {
    const productCodeInput = this.validateProductCode();
    const lotNoInput = this.validateLotNo();
    const shadeInput = this.validateShade();
    const widthInput = this.validateWidth();
    const heightInput = this.validateHeight();
    const thicknessInput = this.validateThickness();
    const locationInput = this.validateLocation();
    const lotArrivalDateInput = this.validateLotArrivalDate();

    if (
      productCodeInput &&
      lotNoInput &&
      shadeInput &&
      widthInput &&
      heightInput &&
      thicknessInput &&
      locationInput &&
      lotArrivalDateInput
    ) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit function for add product inputs

  onSubmitAddProduct = async (e) => {
    e.preventDefault();
    const id = this.props.editProductId;
    const allValidation = this.ValidateAll();
    const shadeType = this.state.shadeType;
    let shadeValue = [];
    for (let i in shadeType) {
      shadeValue.push(shadeType[i]);
    }
    console.log("this.state.productImagesArr", this.state.productImagesArr);
    console.log(
      "this.state.actualProductImagesArr",
      this.state.actualProductImagesArr
    );

    console.log("allValidation", allValidation);
    if (allValidation) {
      let requestData = {
        lot_no: this.state.lotNo,
        shade_id: this.state.shades,
        width: this.state.width,
        width_unit: this.state.widthUnit,
        height: this.state.height,
        height_unit: this.state.heightUnit,
        thickness: this.state.thickness,
        thickness_unit: this.state.thicknessUnit,
        quantity: this.state.quantity,
        quantity_unit: this.state.quantityUnit,
        price: this.state.sellingPrice,
        location_id: this.state.location,
        lot_arrival_date: this.state.lotArrivalDate,
        comments: this.state.remarks,
        product_code: this.state.productCode,
        selected_shade: shadeValue,
        images: this.state.actualProductImagesArr,
        user_id: User.user_id,
      };

      console.log("requestData", requestData);

      const requestFormData = new FormData();
      requestFormData.append("lot_no", this.state.lotNo);
      requestFormData.append("shade_id", this.state.shades);
      requestFormData.append("width", this.state.width);
      requestFormData.append("width_unit", this.state.widthUnit);
      requestFormData.append("height", this.state.height);
      requestFormData.append("height_unit", this.state.heightUnit);
      requestFormData.append("thickness", this.state.thickness);
      requestFormData.append("thickness_unit", this.state.thicknessUnit);
      requestFormData.append("quantity", this.state.quantity);
      requestFormData.append("quantity_unit", this.state.quantityUnit);
      requestFormData.append("price", this.state.sellingPrice);
      requestFormData.append("location_id", this.state.location);
      requestFormData.append(
        "lot_arrival_date",
        moment(this.state.lotArrivalDate).format("YYYY-MM-DD hh:mm:ss")
      );
      requestFormData.append("comments", this.state.remarks);
      requestFormData.append("product_code", this.state.productCode);
      if (!id) requestFormData.append("selected_shade", shadeValue);
      requestFormData.append("user_id", User.user_id);
      for (let i = 0; i < this.state.actualProductImagesArr.length; i++) {
        requestFormData.append(
          "images[]",
          this.state.actualProductImagesArr[i],
          this.state.actualProductImagesArr[i].type
        );
      }

      for (var pair of requestFormData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      if (id) {
        requestFormData.append("updated_by", User.user_id);
        requestFormData.set("_method", "PUT");

        const addListeners = (xhr, callBack) => {
          xhr.addEventListener("loadstart", callBack);
          xhr.addEventListener("load", callBack);
          xhr.addEventListener("loadend", callBack);
          xhr.addEventListener("progress", callBack);
          xhr.addEventListener("error", callBack);
          xhr.addEventListener("abort", callBack);
          xhr.addEventListener("response", callBack);
        };

        // Starting Http Request
        const xhr = new XMLHttpRequest();

        xhr.open(
          "POST",
          `https://admin.madhusudanimpex.com/mm/api/v1/product/${id}`
        );
        console.log("xhr.status", xhr.status);
        addListeners(xhr, (response = false) => {
          if (response && response.status === "success") {
            console.log("callback_response", response);
          }
        });
        xhr.onprogress = function () {
          console.log("LOADING", xhr.status);
        };
        xhr.onload = () => {
          console.log("DONE", xhr.status);
          console.log("Response ", xhr.response);
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            console.log("xhr_response", response);
            this.setState({ addProductModal: false });
            this.props.closeModel(false);
            AppConfig.setMessage("Product updated successfully", false);
            // returning the response in callBack page
          } else {
            const response = JSON.parse(xhr.response);
            // this.setState({ addProductModal: false });
            // this.props.closeModel(false);
            AppConfig.setMessage("Failed to update product", true);
          }
        };
        // Ending Http Request
        xhr.send(requestFormData);
        // requestData["updated_by"] = User.user_id;
        // const response = await updateProduct(requestData, id);
        // if (response.status === "success") {
        //   console.log("response_eidt", response);
        //   this.props.closeModel(false);
        //   AppConfig.setMessage("Product updated successfully", false);
        // } else if (response.status === "error") {
        //   this.props.closeModel(false);
        //   const result = response.result;
        //   let message = result;
        //   if (result[Object.keys(response.result)[0]]) {
        //     message = result[Object.keys(response.result)[0]];
        //   }
        //   AppConfig.setMessage(message);
        // }
      } else {
        const addListeners = (xhr, callBack) => {
          xhr.addEventListener("loadstart", callBack);
          xhr.addEventListener("load", callBack);
          xhr.addEventListener("loadend", callBack);
          xhr.addEventListener("progress", callBack);
          xhr.addEventListener("error", callBack);
          xhr.addEventListener("abort", callBack);
          xhr.addEventListener("response", callBack);
        };
        // formData.append('documentType', type);

        // Starting Http Request
        const xhr = new XMLHttpRequest();

        xhr.open("POST", "https://admin.madhusudanimpex.com/mm/api/v1/product");
        console.log("xhr.status", xhr.status);
        addListeners(xhr, (response = false) => {
          if (response && response.status === "success") {
            console.log("callback_response", response);
          }
        });
        xhr.onprogress = function () {
          console.log("LOADING", xhr.status);
        };
        xhr.onload = () => {
          console.log("DONE", xhr.status);
          console.log("Response ", xhr.response);
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            console.log("xhr_response", response);
            this.setState({ addProductModal: false });
            this.props.closeModel(false);
            AppConfig.setMessage("Product created successfully", false);
            // returning the response in callBack page
          } else {
            // ********* Commented the below in order to stop the modal closing if error ********
            // this.setState({ addProductModal: false });
            // this.props.closeModel(false);
            AppConfig.setMessage("Failed to create product", true);
          }
        };
        // Ending Http Request
        xhr.send(requestFormData);

        // const response = await createProduct(requestData);
        // if (response.status === "success") {
        //   console.log("response_add", response);
        //   this.setState({ addProductModal: false });
        //   this.props.closeModel(false);
        //   AppConfig.setMessage("Product created successfully", false);
        // } else if (response.status === "error") {
        //   this.setState({ addProductModal: false });
        //   this.props.closeModel(false);
        //   const result = response.result;
        //   let message = result;
        //   if (result[Object.keys(response.result)[0]]) {
        //     message = result[Object.keys(response.result)[0]];
        //   }
        //   AppConfig.setMessage(message);
        // }
      }
    }
  };
}
export default AddProduct;
