import {callApi, uploadApi} from './Api';
import './Global';

/**
 *
 * @param{update profile) by id
 */
 export const updateProfile = async(requestData, id) => {
    const functionUrl = "update_profile";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id)
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};


/**
 * Common Api for upload file or profile pic.
 * @param(file, callback) 
 * @return API response
*/
export const uploadProfilePic = async (image, callBack) => {
	const functionUrl = 'upload_profile_pic';
	try {
		const response = await uploadApi(functionUrl, image, callBack);
		if (response) {
			return callBack(response);
		}
	} catch (error) {
		console.log('Error from catch => ', error);
	}
}