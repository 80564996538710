// Add product modal
import React from "react";
import "../../../css/Common.css";
import { CustomInput } from "../../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../../css/Modal.css";
import {
  createShades,
  getShade,
  updateShade,
} from "../../../apis/admin/Shades";
import AppConfig from "../../../modal/AppConfig";
import User from "../../../modal/User";
import { CheckLocationName } from "../../../common/Validation";

class CreateShades extends React.Component {
  state = {
    createShadesModal: false,
    name: "",
  };

  async componentDidUpdate(prevProps) {
    const id = this.props.editShadeId;
    console.log("id shade", id);
    if (this.props.status !== prevProps.status) {
      this.setState({ createShadesModal: this.props.status });
      const response = await getShade(id);
      console.log("API Response => ", response);
      if (response && response.status === "success") {
        let result = response.result;
        this.setState({
          name: result.name,
        });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.hideButton ? (
          <div className="d-flex justify-content-between w-100">
            <p
              className="my-0 py-2 mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ createShadesModal: true });
              }}
            >
              Create Shades
            </p>
            <i className="fas fa-arrow-right align-self-center"></i>
          </div>
        ) : null}
        {this.renderCreateShadesModal()}
      </>
    );
  }

  // Render add shade modal function

  renderCreateShadesModal() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.createShadesModal}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <p className="font-style normal-font-size">
              {this.props.editShadeId ? "Update Shades" : "Create Shades"}
            </p>
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={() => {
                this.props.closeModel(false);
                this.setState({ createShadesModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <form>
              <div className="py-1">
                <CustomInput
                  tabIndex="1"
                  type="text"
                  id="name"
                  placeholder="Name*"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                      shadeError: "",
                    })
                  }
                  onFocus={() => this.setState({ shadeError: "" })}
                  error={this.state.shadeError}
                />
              </div>
              <button
                type="button"
                onClick={this.onSubmitCreateShades}
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
              >
                {this.props.editShadeId ? "Update Shades" : "Create Shades"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  // Validation for shade
  validateShade = () => {
    const shadeError = CheckLocationName(this.state.name);
    if (shadeError === 1) {
      this.setState({ shadeError: "Shade name empty" });
      return false;
    } else if (shadeError === 2) {
      this.setState({ shadeError: "Invalid shade name" });
      return false;
    } else return true;
  };

  // onsubmit function for add product inputs

  onSubmitCreateShades = async (e) => {
    e.preventDefault();
    const shadeNameErr = this.validateShade();
    const id = this.props.editShadeId;
    if (shadeNameErr) {
      let requestData = {
        name: this.state.name,
        user_id: User.user_id,
      };
      if (id) {
        requestData["updated_by"] = User.user_id;
        const response = await updateShade(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          User.setRefresh(true);
          AppConfig.setMessage("Shade updated successfully", false);
        } else if (response.status === "error") {
          // this.props.closeModel(false);
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      } else {
        const response = await createShades(requestData);
        if (response.status === "success") {
          this.setState({ createShadesModal: false });
          AppConfig.setMessage("Shade created successfully", false);
        } else if (response.status === "error") {
          // this.setState({ createShadesModal: false });
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      }
    }
  };
}
export default CreateShades;
