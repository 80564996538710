// Profile popup

import React from "react";
import Popper from "@material-ui/core/Popper";
import "../../css/Common.css";
import { CustomInput, CustomSelect } from "../../common/Element";
import Avtar from "../../assets/avtar.png";
import Avatar from "@material-ui/core/Avatar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { uploadProfilePic, updateProfile } from "../../apis/Profile";
import { getEmployee } from "../../apis/admin/Employee";
import User from "../../modal/User";
import AppConfig from "../../modal/AppConfig";
import {
  CheckEmail,
  CheckName,
  CheckPhone,
  CheckAddress,
} from "../../common/Validation";
import { roleOptions } from "../../common/DropdownOptions";
import { observer } from "mobx-react";

class Profile extends React.Component {
  state = {
    anchorEl: null,
    name: User.name,
    email: User.email,
    address: User.address,
    mobile: User.mobile,
    profileImage: User.profile_image,
    role: User.role_id,
    imagePreviewUrl: "",
  };

  async componentDidMount() {
    const response = await getEmployee(User.user_id);
    if (response && response.status === "success") {
      if (User.role_id === 5) {
        this.setState({
          name: response.result.guestusers.name,
          email: response.result.guestusers.email,
          mobile: response.result.guestusers.mobile,
          address: response.result.guestusers.address,
          role: response.result.guestusers.role_id,
          profileImage: response.result.guestusers.profile_image,
        });
      } else {
        this.setState({
          name: response.result.users[0].name,
          email: response.result.users[0].email,
          mobile: response.result.users[0].mobile,
          address: response.result.users[0].address,
          role: response.result.users[0].role_id,
          profileImage: response.result.users[0].profile_image,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <Avatar
          alt="Avtar"
          src={User.profile_image}
          style={{ alignSelf: "center", cursor: "pointer" }}
          onClick={this.handleClick}
        />
        <Popper
          className="col-md-4"
          id="popper"
          open={this.state.anchorEl}
          anchorEl={this.state.anchorEl}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className="d-flex justify-content-end mx-5">
              <div className="col-md-12  border-style border-radius bg-white p-3">
                <form encType="multipart/form-data">
                  <div className="d-flex justify-content-start">
                    {this.state.profileImage ? (
                      <img
                        alt="profile-pic"
                        className="rounded-circle pointer"
                        src={this.state.profileImage}
                        width="60"
                        height="60"
                        onClick={this.handleUploadPicsSelect}
                      />
                    ) : (
                      <img
                        alt="Avtar"
                        className="rounded-circle pointer"
                        src={Avtar}
                        width="60"
                        height="60"
                        onClick={this.handleUploadPicsSelect}
                      />
                    )}
                    <input
                      type="file"
                      // ref={(ref) => this.upload = ref}
                      id="profilePic"
                      accept=".jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      onChange={this.selectUploadPics}
                    />
                  </div>
                  <div className="my-3">
                    <CustomInput
                      className="black w-100"
                      placeholder="Name"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                        })
                      }
                      error={this.state.nameError}
                    />
                  </div>
                  <div className="my-3">
                    <CustomSelect
                      className="black w-100"
                      placeholder="Role"
                      value={this.state.role}
                      disabled={true}
                      options={roleOptions}
                      onChange={(e) =>
                        this.setState({
                          role: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="my-3">
                    <CustomInput
                      className="black w-100"
                      placeholder="Contact No."
                      value={this.state.mobile}
                      onChange={(e) =>
                        this.setState({
                          mobile: e.target.value,
                        })
                      }
                      error={this.state.phoneNumberError}
                    />
                  </div>
                  <div className="my-3">
                    <CustomInput
                      className="black w-100"
                      placeholder="Mail"
                      value={this.state.email}
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                      error={this.state.emailError}
                    />
                  </div>
                  <div className="my-3">
                    <CustomInput
                      className="black w-100"
                      placeholder="Address"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({
                          address: e.target.value,
                        })
                      }
                      multiline={4}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn theme-bg-color col-md-12 text-white normal-font-size mt-3 mx-1"
                    onClick={this.onSubmitProfileInfo}
                  >
                    Save{" "}
                  </button>
                </form>
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickAway = () => {
    this.setState({ anchorEl: false });
  };

  // Handle file select

  handleUploadPicsSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("profilePic");
    fileSelector.click();
  };

  // Select file

  selectUploadPics = (e) => {
    const profileImage = e.target.files[0];
    uploadProfilePic(profileImage, this.callBackUploadPics);
  };

  //  Call back function for uploaded pics
  callBackUploadPics = (response = false) => {
    if (response && response.status === "success") {
      this.setState({ profileImage: response.result.profileimage });
      User.setProfilePic(response.result.profileimage);
    }
  };

  // Validation for name
  validateName = () => {
    const nameError = CheckName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Name empty" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Invalid name" });
      return false;
    } else return true;
  };

  // Validation for email

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Email empty" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email" });
      return false;
    } else return true;
  };

  // Validation for phone Number
  validatePhoneNumber = () => {
    const phoneNumberError = CheckPhone(this.state.mobile);
    if (phoneNumberError === 1) {
      this.setState({ phoneNumberError: " Phone Number empty" });
      return false;
    } else if (phoneNumberError === 2) {
      this.setState({
        phoneNumberError: "Phone number should have 10 digits",
      });
      return false;
    } else return true;
  };

  // Validation for address
  validateAddress = () => {
    if (this.state.address) {
      const addressError = CheckAddress(this.state.address);
      if (addressError === 1) {
        this.setState({ addressError: "Address empty" });
        return false;
      } else if (addressError === 2) {
        this.setState({ addressError: " Invalid Address" });
        return false;
      } else return true;
    } else {
      return true;
    }
  };

  // Empty input validation
  ValidateAll = () => {
    const nameInput = this.validateName();
    const emailInput = this.validateEmail();
    const phoneNumberInput = this.validatePhoneNumber();
    const addressInput = this.validateAddress();
    if (nameInput && emailInput && phoneNumberInput && addressInput) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit function for profile input
  onSubmitProfileInfo = async (e) => {
    e.preventDefault();
    const id = User.user_id;
    const allValidation = await this.ValidateAll();
    console.log("allValidation", allValidation);
    if (allValidation) {
      let requestData = {
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        address: this.state.address ? this.state.address : null,
        user_id: User.user_id,
        profile_image: this.state.profileImage,
      };
      const response = await updateProfile(requestData, id);
      if (response && response.status === "success") {
        this.setState({ anchorEl: false });
        User.setRefresh(true);
        AppConfig.setMessage("User profile updated successfully", false);
      } else if (response && response.status === "error") {
        this.setState({ anchorEl: false });
        const result = response.result;
        let message = result;
        if (result[Object.keys(response.result)[0]]) {
          message = result[Object.keys(response.result)[0]];
        }
        AppConfig.setMessage(message);
      }
    }
  };
}
export default observer(Profile);
