// Add product modal
import React from "react";
import "../../../css/Common.css";
import { CustomInput } from "../../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../../css/Modal.css";
import {
  createLocation,
  getLocation,
  updateLocation,
} from "../../../apis/admin/Location";
import AppConfig from "../../../modal/AppConfig";
import User from "../../../modal/User";
import {
  CheckLocationName,
  CheckLocationId,
  CheckAddress,
} from "../../../common/Validation";

class CreateLocation extends React.Component {
  state = {
    createLocationModal: false,
    locationId: "",
    locationName: "",
    locationAddress: "",
  };

  async componentDidUpdate(prevProps) {
    const id = this.props.editLocationId;
    console.log("id location", id);
    if (this.props.status !== prevProps.status) {
      this.setState({ createLocationModal: this.props.status });
      const response = await getLocation(id);
      console.log("API Response => ", response);
      if (response && response.status === "success") {
        let result = response.result;
        this.setState({
          locationId: result.short_code,
          locationName: result.name,
          locationAddress: result.address,
        });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.hideButton ? (
          <div className="d-flex justify-content-between w-100">
            <p
              className="my-0 py-2  mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ createLocationModal: true });
              }}
            >
              Create Location
            </p>
            <i className="fas fa-arrow-right align-self-center"></i>
          </div>
        ) : null}
        {this.renderCreateLocationModal()}
      </>
    );
  }

  // Render add Product modal function

  renderCreateLocationModal() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.createLocationModal}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <p className="font-style normal-font-size pl-5">
              {this.props.editLocationId
                ? "Update Location"
                : "Create Location"}
            </p>
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={this.handelClose}
            />
          </div>
          <Modal.Body>
            <form>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="loacation-id"
                  placeholder="Location Id*"
                  value={this.state.locationId}
                  onChange={(e) =>
                    this.setState({
                      locationId: e.target.value,
                      locationIdError: "",
                    })
                  }
                  onFocus={() => this.setState({ locationIdError: "" })}
                  inputProps={{ tabIndex: "1" }}
                  autoFocus={true}
                  error={this.state.locationIdError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="loacation-name"
                  placeholder="Location Name*"
                  value={this.state.locationName}
                  onChange={(e) =>
                    this.setState({
                      locationName: e.target.value,
                      locationNameError: "",
                    })
                  }
                  onFocus={() => this.setState({ locationNameError: "" })}
                  inputProps={{ tabIndex: "2" }}
                  error={this.state.locationNameError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  id="loacation-address"
                  placeholder="Location Address*"
                  value={this.state.locationAddress}
                  onChange={(e) =>
                    this.setState({
                      locationAddress: e.target.value,
                      locationAddressError: "",
                    })
                  }
                  onFocus={() =>
                    this.setState({
                      locationAddressError: "",
                    })
                  }
                  inputProps={{ tabIndex: "3" }}
                  error={this.state.locationAddressError}
                />
              </div>
              <button
                type="button"
                onClick={this.onSubmitCreateLocation}
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
              >
                {this.props.editLocationId
                  ? "Update Location"
                  : "Create Location"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  handelClose = () => {
    this.setState({ createLocationModal: false });
    this.props?.closeModel(false);
  };

  // Validation for location Number
  validateLocationId = () => {
    const locationIdError = CheckLocationId(this.state.locationId);
    if (locationIdError === 1) {
      this.setState({ locationIdError: "Location Id empty" });
      return false;
    } else if (locationIdError === 2) {
      this.setState({
        locationIdError: "Location Id should be 7 alphanumeric characters",
      });
      return false;
    } else return true;
  };

  // Validation for location
  validateLocation = () => {
    const locationNameError = CheckLocationName(this.state.locationName);
    if (locationNameError === 1) {
      this.setState({ locationNameError: "Location Name empty" });
      return false;
    } else if (locationNameError === 2) {
      this.setState({ locationNameError: "Invalid location" });
      return false;
    } else return true;
  };

  // Validation for address
  validateAddress = () => {
    const locationAddressError = CheckAddress(this.state.locationAddress);
    if (locationAddressError === 1) {
      this.setState({ locationAddressError: "Location Address empty" });
      return false;
    } else if (locationAddressError === 2) {
      this.setState({ locationAddressError: "Invalid address" });
      return false;
    } else return true;
  };

  ValidateAll = () => {
    const locationInput = this.validateLocation();
    const locationIdInput = this.validateLocationId();
    const addressInput = this.validateAddress();

    if (locationInput && locationIdInput && addressInput) {
      return true;
    } else {
      console.log(locationInput, locationIdInput, addressInput);
      return false;
    }
  };

  // onsubmit function for add location inputs

  onSubmitCreateLocation = async (e) => {
    e.preventDefault();
    const allValidation = this.ValidateAll();
    const id = this.props.editLocationId;
    if (allValidation) {
      let requestData = {
        short_code: this.state.locationId,
        name: this.state.locationName,
        address: this.state.locationAddress,
        user_id: User.user_id,
      };
      if (id) {
        requestData["updated_by"] = User.user_id;
        const response = await updateLocation(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          User.setRefresh(true);
          AppConfig.setMessage("Location updated successfully", false);
        } else if (response.status === "error") {
          // this.props.closeModel(false);
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      } else {
        const response = await createLocation(requestData);
        if (response.status === "success") {
          this.props.closeModel(false);
          User.setRefresh(true);
          this.setState({ createLocationModal: false });
          AppConfig.setMessage("Location created successfully", false);
        } else if (response.status === "error") {
          // this.setState({ createLocationModal: false });
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      }
    }
  };
}
export default CreateLocation;
