import React from "react";
import { observer } from "mobx-react";
import NoImage from "../../assets/no-image.png";
import "../../css/Common.css";
import { CustomInput, CustomSelect } from "../../common/Element";
import { unitOptions } from "../../common/DropdownOptions";
import { shadesList } from "../../apis/admin/Shades";
import { productList } from "../../apis/admin/Product";
import TablePagination from "@mui/material/TablePagination";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../apis/Api";
import ImageGallery from "react-image-gallery";
import User from "../../modal/User";
import AppConfig from "../../modal/AppConfig";

class ProductGallery extends React.Component {
  state = {
    width: "",
    width_unit: "",
    widthUnit: "cm",
    height: "",
    height_unit: "",
    heightUnit: "cm",
    thickness: "",
    thickness_unit: "",
    thicknessUnit: "cm",
    productGallery: [],
    shade: [],
    selectedShadeId: [],
    size: 12,
    current_page: 1,
    totalData: 0,
    viewProductGalleryId: "",
    galleryCollection: [],
    isLoading: true,
  };

  async componentDidMount() {
    // Api call for shade dropdown
    const requestData = {
      user_id: User.user_id,
      role_id: User.role_id,
    };
    const response = await shadesList(requestData);
    const result = response?.result;

    let shade = [];
    for (let i in result?.shades_dropdown) {
      shade.push(result.shades_dropdown[i]);
    }

    {
      this.productApi();
    }
    this.setState({ shade: shade });
  }
  productApi = async () => {
    let requestData = {
      size: this.state.size,
      page: this.state.current_page,
      width: this.state.width,
      height: this.state.height,
      thickness: this.state.thickness,
      width_unit: this.state.width_unit,
      height_unit: this.state.height_unit,
      thickness_unit: this.state.thickness_unit,
      shade_id: this.state.selectedShadeId,
      user_id: User.user_id,
      role_id: User.role_id,
    };
    if (this.state.width) {
      requestData["width"] = this.state.width;
      requestData["widthUnit"] = this.state.widthUnit;
    }
    if (this.state.height) {
      requestData["height"] = this.state.height;
      requestData["heightUnit"] = this.state.heightUnit;
    }
    if (this.state.thickness) {
      requestData["thickness"] = this.state.thickness;
      requestData["thicknessUnit"] = this.state.thicknessUnit;
    }
    const response = await productList(requestData);
    const result = response?.result;
    let productGallery = [];
    for (let i in result?.data) {
      productGallery.push(result.data[i]);
    }
    this.setState({
      productGallery: productGallery,
      totalData: result?.total,
      isLoading: false,
    });
  };

  galleryApi = async () => {
    // View Gallery api
    const { viewProductGalleryId } = this.state;
    let galleryFunctionUrl = "productimage";
    const response = await callApi(
      galleryFunctionUrl,
      {},
      "GET",
      viewProductGalleryId
    );
    const result = response.result;
    let galleryCollection = [];
    for (let i in result.productmages) {
      galleryCollection.push({
        original: result.productmages[i].image_path,
        thumbnail: result.productmages[i].image_path,
      });
    }
    this.setState({ galleryCollection: galleryCollection });
  };
  render() {
    return (
      <div className="m-3">
        <div className="row">
          <div className="col-md-3">{this.renderSearchProduct()}</div>
          <div className="col-md-9">{this.renderProductGallery()}</div>
        </div>
        {this.renderProductGalleryModal()}
      </div>
    );
  }

  // Render product gallery function
  renderProductGallery = () => {
    return (
      <div className="row my-3">
        {this.state.isLoading ? null : this.state.productGallery &&
          this.state.productGallery.length > 0 ? (
          this.state.productGallery.map((productGallery) => (
            <div className="col-md-3">
              <div
                className="product-gallery-container bg-white"
                style={{ cursor: "pointer" }}
              >
                <div
                  className="col-md-12 border-radius"
                  onClick={() => this.viewProductGallery(productGallery.id)}
                >
                  {productGallery?.product_image?.image_path ? (
                    <img
                      className="border-radius"
                      src={productGallery?.product_image?.image_path}
                      alt="productGallery"
                      width="100%"
                      height="150px"
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="productGallery"
                      width="100%"
                      height="150px"
                    />
                  )}
                  <div className="border-radius product-gallery-content  px-3">
                    <p className="font-style text-white small-font-size pb-0 pt-1 py-0 my-0 px-2">
                      {productGallery.shade_name}
                    </p>
                    <div className="d-flex">
                      <p className="xsmall-font-size text-white text-center py-0">
                        {" "}
                        W <br />
                        <span className="xsmall-font-size text-white ml-2">
                          {productGallery.width +
                          " " +
                          productGallery.width_unit
                            ? productGallery.width +
                              " " +
                              productGallery.width_unit
                            : "-"}{" "}
                        </span>
                      </p>
                      <p className="xsmall-font-size text-white text-center mx-2 py-0">
                        {" "}
                        H <br />
                        <span className="xsmall-font-size text-white">
                          {productGallery.height +
                          " " +
                          productGallery.height_unit
                            ? productGallery.height +
                              " " +
                              productGallery.height_unit
                            : "-"}
                        </span>
                      </p>

                      <p className="xsmall-font-size text-white text-center mx-2 py-0">
                        {" "}
                        T <br />
                        <span className="xsmall-font-size text-white">
                          {productGallery.thickness +
                          " " +
                          productGallery.thickness_unit
                            ? productGallery.thickness +
                              " " +
                              productGallery.thickness_unit
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5>No product for the given input</h5>
        )}

        <TablePagination
          className="black"
          component="div"
          count={this.state.totalData}
          onPageChange={(event, page) => {
            this.setState({ current_page: parseInt(page) + 1 }, () =>
              this.productApi()
            );
          }}
          page={this.state.current_page - 1}
          onRowsPerPageChange={(event) =>
            this.setState({ size: event.target.value, current_page: 1 }, () =>
              this.productApi()
            )
          }
          rowsPerPageOptions={[12, 24, 48]}
          rowsPerPage={this.state.size}
        />
      </div>
    );
  };

  // Render search product function
  renderSearchProduct = () => {
    return (
      <div className="theme-bg-color border-radius p-3">
        <p className="text-white font-style medium-font-size">Search Product</p>
        <div
          className="bg-white rounded p-3"
          style={{ height: "300px", overflow: "scroll" }}
        >
          <p className="theme-font-color ">Shade</p>
          {this.state.shade.map((shade) => (
            <div className="d-flex justify-content-between">
              <label
                className="woo small-font-size"
                style={{ marginLeft: "5%" }}
                htmlFor="marble-shade"
              >
                {shade.name ? shade.name : "-"}
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="marble-shade"
                name="marble-shade"
                onChange={(e) => this.shadeHandler(e, shade.id)}
              />
            </div>
          ))}
        </div>
        <div className="d-flex bg-light rounded mt-3 p-3">
          <div className="col-md-8">
            <CustomInput
              tabIndex="1"
              type="number"
              className="black w-100"
              id="width"
              placeholder="Width*"
              value={this.state.width}
              onChange={(e) => {
                if (e.target.value > "0") {
                  this.setState({ width: e.target.value, widthError: "" });
                } else if (e.target.value < "0") {
                  this.setState({
                    width: "",
                    widthError: "Enter a valid width",
                  });
                }
              }}
              onFocus={() => this.setState({ widthError: "" })}
              error={this.state.widthError}
              onBlur={this.productApi}
            />
          </div>
          <div className="col-md-4">
            <CustomSelect
              tabIndex="2"
              id="width-unit"
              className="black w-100"
              placeholder="unit"
              value={this.state.widthUnit}
              onChange={(e) => this.setState({ widthUnit: e.target.value })}
              options={unitOptions}
              error={this.state.unitError}
              onBlur={this.productApi}
              defaultUnit={true}
            />
          </div>
        </div>
        <div className="d-flex bg-white rounded mt-3 p-3">
          <div className="col-md-8">
            <CustomInput
              tabIndex="3"
              className="black w-100"
              type="number"
              id="height"
              placeholder="Height*"
              value={this.state.height}
              onChange={(e) => {
                if (e.target.value > "0") {
                  this.setState({ height: e.target.value, heightError: "" });
                } else if (e.target.value < "0") {
                  this.setState({
                    height: "",
                    heightError: "Enter a valid height",
                  });
                }
              }}
              onFocus={() => this.setState({ heightError: "" })}
              error={this.state.heightError}
              onBlur={this.productApi}
            />
          </div>
          <div className="col-md-4">
            <CustomSelect
              tabIndex="4"
              className="black w-100"
              placeholder="unit"
              value={this.state.heightUnit}
              onChange={(e) => this.setState({ heightUnit: e.target.value })}
              options={unitOptions}
              error={this.state.heightUnitError}
              onBlur={this.productApi}
              defaultUnit={true}
            />
          </div>
        </div>
        <div className="d-flex bg-white rounded mt-3 p-3">
          <div className="col-md-8">
            <CustomInput
              tabIndex="5"
              className="black w-100"
              type="number"
              id="thickness"
              placeholder="Thickness*"
              value={this.state.thickness}
              onChange={(e) => {
                if (e.target.value > "0") {
                  this.setState({
                    thickness: e.target.value,
                    thicknessError: "",
                  });
                } else if (e.target.value < "0") {
                  this.setState({
                    thickness: "",
                    thicknessError: "Enter a valid thickness",
                  });
                }
              }}
              onFocus={() => this.setState({ thicknessError: "" })}
              error={this.state.thicknessError}
              onBlur={this.productApi}
            />
          </div>
          <div className="col-md-4">
            <CustomSelect
              tabIndex="6"
              className="black w-100"
              placeholder="unit"
              value={this.state.thicknessUnit}
              onChange={(e) => this.setState({ thicknessUnit: e.target.value })}
              options={unitOptions}
              error={this.state.thicknessUnitError}
              onBlur={this.productApi}
              defaultUnit={true}
            />
          </div>
        </div>
      </div>
    );
  };
  // shade handler function
  shadeHandler = (e, id) => {
    // const { name } = e.target;
    const { selectedShadeId } = this.state;
    let ids = selectedShadeId;
    let isChecked = e.target.checked;
    if (isChecked) {
      ids.push(id);
    } else {
      const index = ids.indexOf(id);
      if (index > -1) {
        ids.splice(index, 1);
      }
    }
    this.setState({ selectedShadeId: ids }, () => {
      this.productApi();
    });
  };
  // Render upload picture modal function

  renderProductGalleryModal = () => {
    const { galleryCollection } = this.state;
    return (
      <Modal
        size="xl"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewProductGallery}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              Product Gallery
            </p>
            <i
              className="fas fa-times-circle  pointer theme-font-color"
              onClick={() => {
                this.setState({ viewProductGallery: false });
              }}
            />
          </div>
          <Modal.Body>
            <div className="row p-3">
              {galleryCollection ? (
                <ImageGallery
                  items={galleryCollection}
                  thumbnailPosition="left"
                  showBullets={true}
                  showIndex={true}
                  showThumbnails={true}
                  lazyLoad={true}
                  showPlayButton={true}
                  autoPlay={true}
                />
              ) : null}
              {/* { this.state.original.length === '0' ?
                             <img src={NoImage} alt='no-img'/> :
                             null} */}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  viewProductGallery = (id) => {
    this.setState(
      { viewProductGallery: true, viewProductGalleryId: id },
      () => {
        this.galleryApi();
      }
    );
  };
}
export default observer(ProductGallery);
