import React from "react";
import { observer } from "mobx-react";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../css/DataTable.css";
import Popover from "react-bootstrap/Popover";
import {
  shadeTableList,
  deleteShade,
  deleteMultipleShades,
} from "../../apis/admin/Shades";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ConfirmModal from "../../common/ConfirmModal";
import CreateShades from "../../pages/admin/create/CreateShades";
import AppConfig from "../../modal/AppConfig";
import User from "../../modal/User";

class Shade extends React.Component {
  state = {
    rows: [],
    columns: [],
    size: 10,
    current_page: 1,
    totalData: 0,
    search: "",
    viewShadeConfirmModal: "",
    editShadeId: "",
    hideButton: false,
    isMultipleDelete: false,
    shadeIdsArr: [],
  };

  async componentDidMount() {
    const columns = [
      { field: "Id", hide: true },
      {
        field: "icon",
        headerName: (
          <div>
            <i className="fas fa-ellipsis-v pointer theme-font-color" />
          </div>
        ),
        renderCell: (cellValues) => (
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="right"
            id="popover-basic"
            overlay={this.popover(cellValues.id)}
          >
            <i className="fas fa-ellipsis-v pointer theme-font-color" />
          </OverlayTrigger>
        ),
        minWidth: 20,
      },
      { field: "name", headerName: "Shades", minWidth: 200 },
    ];

    this.shadeApi();
    this.setState({ columns: columns });
  }

  shadeApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
      search: this.state.search,
    };
    const response = await shadeTableList(requestData);
    if (response && response.status === "success") {
      const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      if (rows.length > 0) {
        for (let i in rows) {
          apiRows.push(rows[i]);
        }
      }
      for (let i in result) {
        const row = {
          id: result[i].id,
          name: result[i].name,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  // Render Menu function for data table data
  popover = (id) => {
    return (
      <Popover id="popover-basic" className="border-0 p-3">
        <p className="my-1 pointer">
          <i className="far fa-edit mr-2" onClick={() => this.editShade(id)}>
            {" "}
            Edit
          </i>
        </p>
        <p className="my-1 pointer" onClick={() => this.deleteShade(id)}>
          <i className="far fa-trash-alt mr-2">Delete</i>
        </p>
      </Popover>
    );
  };

  render() {
    if (User.refresh) {
      this.shadeApi();
      User.setRefresh(false);
    }
    const role_id = User.role_id;

    return (
      <div className="m-3">
        <div className="d-flex flex-row">
          <div className="border col-md-4 bg-white rounded border-secondary d-flex justify-content-between p-3">
            <input
              type="text"
              className="no-outline input-style small-font-size w-100"
              placeholder="Search..."
              onChange={this._handleSearchChange}
            />
            <i className="fas fa-search align-self-center" />
          </div>
          {role_id === 2 || role_id === 3 || role_id === 4 || role_id === 1 ? (
            this.state.isMultipleDelete ? (
              <div className="col-md-2 my-2" style={{ marginLeft: "15px" }}>
                <button
                  type="btn"
                  className="btn theme-bg-color text-white fw-bold normal-font-size"
                  onClick={() => this.handleDeleteMultiple()}
                >
                  Delete
                </button>
              </div>
            ) : null
          ) : null}
        </div>
        <div className="my-3">{this.renderTable()}</div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewShadeConfirmModal}
          heading="Delete Shade"
          title="Are you sure you want to delete the shade?"
          confirm={() => this.getShadeSuccess()}
          handleClose={() => this.setState({ viewShadeConfirmModal: false })}
        />
        <CreateShades
          hideButton={this.state.hideButton}
          status={this.state.status}
          editShadeId={this.state.editShadeId}
          closeModel={() => this.setState({ status: false })}
          reRenderTable={() => this.shadeApi()}
        />
      </div>
    );
  }

  renderTable = () => {
    const CustomToolbar = () => {
      return (
        <div className="px-3 py-2 d-flex justify-content-between">
          <p className="theme-font-color normal-font-size align-self-center">
            Shade Details
          </p>
          <GridToolbarExport />
        </div>
      );
    };
    return (
      <div style={{ height: 550, width: "40%" }}>
        <DataGrid
          className="black"
          rows={this.state.rows}
          columns={this.state.columns}
          pageSize={this.state.size}
          page={this.state.current_page - 1}
          onPageSizeChange={(size) =>
            this.setState({ size: size, current_page: 1 }, () =>
              this.shadeApi()
            )
          }
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(current_page) =>
            this.setState({ current_page: parseInt(current_page) + 1 }, () =>
              this.shadeApi()
            )
          }
          rowCount={this.state.totalData}
          checkboxSelection={true}
          disableSelection={true}
          hideFooterSelectedRowCount={true}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(ids) => {
            if (ids.length > 0) {
              console.log("selection_product_ids", ids);
              this.setState({
                isMultipleDelete: true,
                shadeIdsArr: [...ids],
              });
            } else {
              this.setState({
                isMultipleDelete: false,
                shadeIdsArr: [...ids],
              });
            }
          }}
        />
      </div>
    );
  };
  // Delete project confirm modal
  deleteShade = (id) => {
    this.setState({ viewShadeConfirmModal: true, deleteId: id });
  };

  // Edit project modal
  editShade = (id) => {
    this.setState({ editShadeId: id, status: true });
  };

  // Delete project api function
  getShadeSuccess = () => {
    const id = this.state.deleteId;
    deleteShade(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ rows: [] });
        this.shadeApi();
        User.setRefresh(true);
        this.setState({ viewShadeConfirmModal: false });
        AppConfig.setMessage("Shade Deleted Successfully", false);
      }
    });
  };

  // Search handle function
  _handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, search: value }, () => {
      this.shadeApi();
    });
  };

  // Delete Multiple Lead
  handleDeleteMultiple = async () => {
    const requestBody = {
      id: [...this.state.shadeIdsArr],
    };
    console.log("requestBody", requestBody);
    try {
      const response = await deleteMultipleShades(requestBody);
      console.log("response", response);
      if (response && response.status === "success") {
        this.setState({ rows: [], totalData: 0, shadeIdsArr: [] }, () => {
          this.shadeApi();
          AppConfig.setMessage(response.result, false);
        });
      } else {
        AppConfig.setMessage(response.result);
      }
    } catch (err) {
      console.log("deleteMultipleLeads_err", err);
    }
  };
}
export default observer(Shade);
