// Forgot passsword Page

import React from "react";
import { CheckPassword, ComparePassword } from "../../common/Validation";
import { changePassword } from "../../apis/General";
import { CustomInput, Layout } from "../../common/Element";
import Header from "../../common/Header";
import "../../css/Common.css";
import "../../css/Modal.css";
import AppConfig from "../../modal/AppConfig";
import { observer } from "mobx-react";
import "../../css/Modal.css";

class ChangePassword extends React.Component {
	state = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
		showOldPassword: false,
		showNewPassword: false,
		showConfirmPassword: false,
		error: "",
		changePasswordModal: false,
	};

	render() {
		return (
			<Layout>
				<Header>
					<h4 className="font-style ">Change Password</h4>
					<div className="col-md-8 normal-font-size"></div>
				</Header>
				<div className="d-flex justify-content-center">
					{this.renderChangePassword()}
				</div>
			</Layout>
		);
	}

	// Render forgot password function

	renderChangePassword() {
		return (
			<div className="col-md-4 border-style border-radius p-3 my-5">
				<form onSubmit={this.onSubmitChangePassword}>
					<div className="py-3">
						<CustomInput
							className="black w-100"
							type={
								this.state.showOldPassword ? "text" : "password"
							}
							id="oldPassword"
							label="Old Password"
							tabIndex="1"
							value={this.state.oldPassword}
							onChange={(e) =>
								this.setState({
									oldPassword: e.target.value,
									oldPasswordError: "",
								})
							}
							onFocus={() =>
								this.setState({ oldPasswordError: "" })
							}
							icon={
								this.state.showOldPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showOldPassword:
										!this.state.showOldPassword,
								})
							}
							error={this.state.oldPasswordError}
						/>
					</div>
					<div className="py-3">
						<CustomInput
							className="black w-100"
							type={
								this.state.showNewPassword ? "text" : "password"
							}
							id="newPassword"
							tabIndex="2"
							label="New Password"
							value={this.state.newPassword}
							onChange={(e) =>
								this.setState({
									newPassword: e.target.value,
									newPasswordError: "",
								})
							}
							onFocus={() =>
								this.setState({ newPasswordError: "" })
							}
							icon={
								this.state.showNewPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showNewPassword:
										!this.state.showNewPassword,
								})
							}
							error={this.state.newPasswordError}
						/>
					</div>
					<div className="py-3">
						<CustomInput
							className="black w-100"
							type={
								this.state.showConfirmPassword
									? "text"
									: "password"
							}
							id="confirmPassword"
							tabIndex="3"
							label="Confirm Password"
							value={this.state.confirmPassword}
							onChange={(e) =>
								this.setState({
									confirmPassword: e.target.value,
									confirmPasswordError: "",
								})
							}
							onFocus={() =>
								this.setState({ confirmPasswordError: "" })
							}
							icon={
								this.state.showConfirmPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showConfirmPassword:
										!this.state.showConfirmPassword,
								})
							}
							error={this.state.confirmPasswordError}
						/>
					</div>
					<button
						type="submit"
						className="btn theme-bg-color text-white col-md-12 mx-2 font-style my-3"
					>
						Submit
					</button>
				</form>
			</div>
		);
	}

	oldPasswordCheck = () => {
		const oldPasswordError = CheckPassword(this.state.oldPassword);
		if (oldPasswordError && oldPasswordError === 1) {
			this.setState({ oldPasswordError: "Old password empty" });
			return false;
		} else if (oldPasswordError && oldPasswordError === 2) {
			this.setState({ oldPasswordError: "Enter valid old password" });
			return false;
		}
		return true;
	};
	passwordCheck = () => {
		const passwordError = CheckPassword(this.state.newPassword);
		if (passwordError && passwordError === 1) {
			this.setState({ newPasswordError: "Empty new password" });
			return false;
		} else if (passwordError && passwordError === 2) {
			this.setState({ newPasswordError: "Enter valid new password" });
			return false;
		}
		return true;
	};
	confirmPasswordCheck = () => {
		const conPasswordError = CheckPassword(this.state.confirmPassword);
		if (conPasswordError && conPasswordError === 1) {
			this.setState({ confirmPasswordError: "Empty Confirm password" });
			return false;
		}
		const comPasswordError = ComparePassword(
			this.state.newPassword,
			this.state.confirmPassword
		);
		if (comPasswordError === 1) {
			this.setState({ confirmPasswordError: "Password doesn't match" });
			return false;
		}
		return true;
	};
	validateAll = async () => {
		const oldPasswordInput = await this.oldPasswordCheck();
		const passwordInput = await this.passwordCheck();
		const confirmPasswordInput = await this.confirmPasswordCheck();
		if (oldPasswordInput && passwordInput && confirmPasswordInput) {
			return true;
		}
		return false;
	};

	// onsubmit function for forget password

	onSubmitChangePassword = async (e) => {
		e.preventDefault();
		const allValidation = await this.validateAll();
		if (allValidation) {
			const requestData = {
				oldPassword: this.state.oldPassword,
				newPassword: this.state.newPassword,
			};
			const response = await changePassword(requestData);
			if (response && response.status === "success") {
				AppConfig.setMessage(response.result, false);
			} else if (response.status === "error") {
				AppConfig.setMessage(response.result);
			}
		}
	};
}

export default observer(ChangePassword);
