import { callApi } from "./Api";
import "./Global";

/**
 *
 * @param{form input)data
 */
export const createLead = async (requestData = {}) => {
  const functionUrl = "lead";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{lead list)data
 */
export const leadList = async (requestData = {}) => {
  const functionUrl = "lead_list";

  try {
    console.log("project fun url", functionUrl);
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{updated lead) by id
 */
export const updateLead = async (requestData, id) => {
  const functionUrl = "lead";
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{delete lead) by id
 */
export const deleteLead = async (id) => {
  const functionUrl = "lead";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single lead) by id
 */
export const getLead = async (id) => {
  const functionUrl = "lead";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{follow up date, comments)data
 */
export const createViewLead = async (requestData = {}) => {
  const functionUrl = "lead_detail";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get lead assigned to dropdown ) data
 */
export const getLeadAssignedTo = async () => {
  const functionUrl = "lead_assigned_to";
  try {
    return await callApi(functionUrl, {}, "GET")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

// Delete multiple Leads
export const deleteMultipleLeads = async (requestBody = {}) => {
  const functionUrl = "delete_multiple_leads";
  try {
    return await callApi(functionUrl, requestBody, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
