// create validation for Name
export const CheckName = (name) => {
  const regex = /^[a-zA-Z ]{2,30}$/;
  let errorCode = null;
  if (name === "" || typeof name === "undefined") {
    errorCode = 1;
  } else if (!regex.test(name)) {
    errorCode = 2;
  }
  return errorCode;
};

// check user name
export const CheckUserName = (username) => {
  const stringCheck = /[a-zA-Z]/;
  let errorCode = null;
  if (!username) {
    errorCode = 1;
  } else if (stringCheck.test(username)) {
    const emailError = CheckEmail(username);
    emailError ? (errorCode = 2) : (errorCode = null);
  } else {
    const phoneError = CheckPhone(username);
    phoneError ? (errorCode = 3) : (errorCode = null);
  }
  return errorCode;
};

// Email validation
export const CheckEmail = (email) => {
  const regex =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  let errorCode = null;
  if (email === "" || typeof email === "undefined") {
    errorCode = 1;
  } else if (!regex.test(email)) {
    errorCode = 2;
  }
  return errorCode;
};

// Phone validation
export const CheckPhone = (phone) => {
  const regex = /^[0-9]{10}$/;
  let errorCode = null;
  if (phone === "" || typeof phone === "undefined") {
    errorCode = 1;
  } else if (!regex.test(phone)) {
    errorCode = 2;
  }
  return errorCode;
};

// Dropdown validation

// create Validation for dropdown
export const DropDownCheck = (value) => {
  let errorCode = null;
  if (value === "" || typeof value === "undefined") {
    errorCode = 1;
  }
  return errorCode;
};

// create validation for date
export const CheckDob = (value) => {
  let errorCode = null;
  if (!value) {
    errorCode = 1;
  }
  return errorCode;
};

// Password validation

export const CheckPassword = (password) => {
  const regex1 =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$|!%*#?&])[A-Za-z\d@$!%*#?&-]{8,}$/;
  let errorCode = null;
  if (password === "") {
    errorCode = 1;
  } else if (!regex1.test(password)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(OTP)
 * @return Error code or null
 */
export const OtpCheck = (otp) => {
  const numberCheck = /[0-9]/;
  let errorCode = null;
  if (!otp) {
    errorCode = 1;
  } else if (!numberCheck.test(otp) || otp.length < 4) {
    errorCode = 2;
  }
  return errorCode;
};

// compare Password in password and confirm password
export const ComparePassword = (password, confirmPassword) => {
  let errorCode = null;
  if (password !== confirmPassword) {
    errorCode = 1;
  }
  return errorCode;
};

/**
 *
 * @param(Date)
 * @return Error code or null
 */
export const CheckDate = (value) => {
  let errorCode = null;
  if (!value) {
    errorCode = 1;
  }
  return errorCode;
};

/**
 *
 * @param(location id)
 * @return Error code or null
 */
export const CheckLocationId = (locationId) => {
  const regex = /^[a-zA-Z0-9-]{1,7}$/;
  let errorCode = null;
  if (locationId === "" || typeof locationId === "undefined") {
    errorCode = 1;
  } else if (!regex.test(locationId)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(location name)
 * @return Error code or null
 */
export const CheckLocationName = (locationName) => {
  const regex = /^[a-zA-Z- ]{2,20}$/;
  let errorCode = null;
  if (locationName === "" || typeof locationName === "undefined") {
    errorCode = 1;
  } else if (!regex.test(locationName)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(product code)
 * @return Error code or null
 */
export const CheckProductCode = (productCode) => {
  const regex = /^[a-zA-Z0-9]{1,5}$/;
  let errorCode = null;
  if (productCode === "" || typeof productCode === "undefined") {
    errorCode = 1;
  } else if (!regex.test(productCode)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(lot no)
 * @return Error code or null
 */
export const CheckLotNo = (lotNo) => {
  const regex = /^[a-zA-Z0-9]{1,5}$/;
  let errorCode = null;
  if (lotNo === "" || typeof lotNo === "undefined") {
    errorCode = 1;
  } else if (!regex.test(lotNo)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(address)
 * @return Error code or null
 */
export const CheckAddress = (address) => {
  const regex = /^[a-zA-Z0-9-#.,/ ]{2,}$/;
  let errorCode = null;
  if (address === "" || typeof address === "undefined") {
    errorCode = 1;
  } else if (!regex.test(address)) {
    errorCode = 2;
  }
  return errorCode;
};

/**
 *
 * @param(description or comments)
 * @return Error code or null
 */
export const CheckDescription = (name = "") => {
  const regex = /[a-zA-Z0-9-!$%^&*()_+|~=#`{}[\]:";'<>?/ ] {1,160}/g;
  let errorCode = null;
  if (name === "" || typeof name === "undefined") {
    errorCode = 1;
  } else if (!regex.test(name)) {
    errorCode = 2;
  } else if (CheckSymbol(name)) {
    errorCode = 3;
  } else if (name && name.length < 2) {
    errorCode = 4;
  }
  return errorCode;
};

/**
 *
 * @param(symbols)
 * @return Error code or null
 */
export const CheckSymbol = (name) => {
  const regex = /[-!$%^&*()_+|~=#`{}[\]:";'<>?/]/;
  let errorCode = null;
  if (regex.test(name)) {
    errorCode = 1;
  }
  return errorCode;
};

// Message validation
export const CheckMessage = (name = "") => {
  const regex = /[a-zA-Z0-9-!$%^&*()_+|~=#`{}[\]:";'<>?/] {1,160}/g;
  let errorCode = null;
  if (name === "" || typeof name === "undefined") {
    errorCode = 1;
  } else if (!regex.test(name)) {
    errorCode = 2;
  }
  return errorCode;
};
