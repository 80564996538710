import React from "react";
import moment from "moment";
import ReactQuill from "react-quill";
import Tab from "react-bootstrap/Tab";
import { observer } from "mobx-react";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import {
  VerticalBar,
  DoughnutChart,
  DoughnutCustomerChart,
} from "../common/Chart";
import {
  CustomSelect,
  Layout,
  percentageCalculations,
  CustomDatePicker,
} from "../common/Element";
import { dateFilter } from "../common/DropdownOptions";
import { dashboardList } from "../apis/Dashboard";
import { notificationList } from "../apis/Notification";
import { createViewLead } from "../apis/Lead";
import Header from "../common/Header";
import { callApi } from "../apis/Api";
import "react-quill/dist/quill.snow.css";
import User from "../modal/User";
import "../css/Modal.css";
import "../css/Common.css";
import AppConfig from "../modal/AppConfig";
import { CheckDate, CheckMessage } from "../common/Validation";
import { getEmployee } from "../apis/admin/Employee";

class Dashboard extends React.Component {
  state = {
    rows: [],
    columns: [],
    size: 10,
    current_page: 1,
    totalData: 0,
    actionForm: false,
    comments: "",
    followupDate: null,
    totalCount: 0,
    todaysLead: 0,
    totalLeads: 0,
    totalProspect: 0,
    totalCustomer: 0,
    lineChartLabel: [],
    lineChartValue: [],
    filter: 7,
    name: "",
    address: "",
    email: "",
    mobile: "",
    profile_image: "",
    role_id: "",
  };
  async componentDidMount() {
    const columns = [
      { field: "Id", hide: true },
      { field: "name", headerName: "Name", minWidth: 200 },
      {
        field: "comments",
        headerName: "Comments",
        minWidth: 200,
        renderCell: (params) => {
          return (
            <div>
              <p
                className="normal-font-size my-2 py-0 text-dark text-justify"
                dangerouslySetInnerHTML={{
                  __html: params.row.comments.slice(0, 16, +"..."),
                  // __html: params.row.comments.slice(0, 25, +"..."),
                }}
              />
            </div>
          );
        },
      },
      { field: "mobile", headerName: "Mobile Number", minWidth: 200 },
      {
        field: "follow_up_date",
        headerName: "Follow Up Date",
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 150,
        renderCell: (params) => {
          const role_id = User.role_id;
          return (
            <div>
              {role_id === 1 ||
              role_id === 2 ||
              role_id === 3 ||
              role_id === 6 ? (
                <button
                  className="btn text-white small-font-size theme-bg-color"
                  onClick={() =>
                    this.setState({
                      actionForm: true,
                      id: params.row.id,
                      email: params.row.email,
                      mobile: params.row.mobile,
                    })
                  }
                >
                  Action
                </button>
              ) : null}
            </div>
          );
        },
      },
    ];
    this.userInfoApi();
    this.dashboardListApi();
    this.setState({ columns: columns });
    this.dashboardApi();
    const response = await notificationList(User.user_id);
    console.log("response", response);
    const result = response?.result;
    User.setUnreadCount(result.count);
  }

  dashboardApi = () => {
    const functionUrl = "dashboard";
    const requestData = {
      date: this.state.filter,
    };
    callApi(functionUrl, requestData).then((response) => {
      if (response && response.status === "success") {
        const result = response.result;
        const leadsDayCounts = result?.leadsDayCount;
        const reverseLeadCounts = Object.keys(leadsDayCounts).reverse();
        let lineChartLabel = [];
        let lineChartValue = [];
        reverseLeadCounts.forEach(function (key) {
          lineChartLabel.push(moment(key).format("DD-MMM"));
          lineChartValue.push(leadsDayCounts[key]);
        });
        this.setState({
          lineChartLabel,
          lineChartValue,
          totalCount: result.totalCount,
          totalLeads: result.leadsCount,
          totalProspect: result.prospectCount,
          totalCustomer: result.customerCount,
        });
      }
    });
  };

  dashboardListApi = async (sorting = []) => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
      search: this.state.search,
    };
    const response = await dashboardList(requestData);
    if (response && response.status === "success") {
      // const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      // if(rows.length > 0) {
      //     for(let i in rows) {
      //         apiRows.push(rows[i]);
      //     }
      // }

      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          name: result.data[i].first_name + " " + result.data[i].last_name,
          mobile: result.data[i].mobile,
          comments: result.data[i].comments,
          follow_up_date: moment(result.data[i].follow_up_date).format(
            "Do MMMM  YYYY"
          ),
          email: result.data[i].email,
        };
        apiRows.push(row);
      }
      console.log("api_rows", apiRows);
      console.log("result.total", result.total);
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  userInfoApi = async () => {
    const id = User.user_id;
    const response = await getEmployee(id);
    if (response && response.status === "success") {
      let result = response.result.users[0];
      User.setRoleId(result.role_id);
      User.setName(result.name);
      User.setEmail(result.email);
      User.setMobile(result.mobile);
      User.setAddress(result.address);
      User.setProfilePic(result.profile_image);
    }
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  render() {
    return (
      <Layout>
        <Header>
          <h3 className="font-style">Dashboard</h3>
          <div className="col-md-10 normal-font-size">
            <Tabs
              defaultActiveKey="overview"
              id="controlled-tab-example"
              onSelect={(menu) => this.setState({ menu })}
            >
              <Tab eventKey="overview" title="Overview" active />
            </Tabs>
          </div>
        </Header>
        <div className="p-3">
          <div className="row">
            <div className="col-md-9 px-1">
              {this.renderChart()}
              {this.renderTable()}
            </div>
            <div className="col-md-3">{this.renderLeadConversationRatio()}</div>
            {this.renderMailActionModal()}
          </div>
        </div>
      </Layout>
    );
  }

  renderChart = () => {
    const {
      lineChartLabel,
      lineChartValue,
      totalCount,
      totalProspect,
      totalCustomer,
    } = this.state;
    const combineProspect = totalCount - totalCustomer;
    return (
      <div className="row border pb-3 mb-3">
        <div className="col-md-12">
          <div className="d-flex pb-4 pt-2 justify-content-between">
            <div className="d-flex align-self-center col-8">
              <p className="font-weight-bold normal-font-size mb-0">
                <strong>Leads</strong>
              </p>
            </div>
            <div className="col-4">
              <CustomSelect
                className="black"
                placeholder="Select Filter*"
                value={this.state.filter}
                onChange={(e) =>
                  this.setState({ filter: e.target.value }, () => {
                    this.dashboardApi();
                  })
                }
                options={dateFilter}
                error={this.state.shadeError}
                defaultUnit={true}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 d-flex justify-content-between">
          <VerticalBar label={lineChartLabel} value={lineChartValue} />
          <DoughnutChart
            label={["Prospect", "Leads"]}
            value={[totalProspect, combineProspect]}
          />
          <DoughnutCustomerChart
            label={["Customer", "Leads"]}
            value={[totalCustomer, totalCount]}
          />
        </div>
      </div>
    );
  };

  renderTable = () => {
    const CustomToolbar = () => {
      return (
        <div className="py-2 d-flex justify-content-between">
          <p className="theme-font-color normal-font-size align-self-center px-3">
            Follow up leads
          </p>
          <div className="px-3">
            <GridToolbarExport />
          </div>
        </div>
      );
    };

    console.log("render_this.state.rows", this.state.rows);
    return (
      <div style={{ height: 400, width: "100%" }}>
        <div className="border col-md-4 bg-white rounded border-secondary d-flex justify-content-between p-3 my-2">
          <input
            type="text"
            className="no-outline input-style small-font-size w-100"
            placeholder="Search"
            onChange={this._handleSearchChange}
          />
          <i className="fas fa-search align-self-center" />
        </div>
        <DataGrid
          rows={this.state.rows}
          columns={this.state.columns}
          pageSize={this.state.size}
          onPageSizeChange={(size) =>
            this.setState({ size: size, current_page: 1 }, () =>
              this.dashboardListApi()
            )
          }
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(current_page) =>
            this.setState({ current_page: parseInt(current_page) + 1 }, () =>
              this.dashboardListApi()
            )
          }
          rowCount={this.state.totalData}
          checkboxSelection={false}
          disableSelection={true}
          hideFooterSelectedRowCount={true}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  };
  renderLeadConversationRatio = () => {
    const { totalCount, totalLeads, totalProspect, totalCustomer } = this.state;
    const combineProspect = totalCount - totalCustomer;
    const prospectValue = percentageCalculations([
      totalProspect,
      combineProspect,
    ]);
    return (
      <div className="theme-bg-color border-radius py-5 px-4">
        <p className="text-white text-center font-style medium-font-size">
          Lead Conversation Ratio
        </p>
        <h3 className="font-style text-white text-center">{prospectValue}</h3>
        <p className="text-white text-center font-style small-font-size">
          {this.state.filter ? this.state.filter + " Days Data " : "-"}
        </p>
        <hr className="text-white my-3" style={{ opacity: 2.25 }}></hr>
        <div className="rounded bg-white d-flex justify-content-between p-3 my-3">
          <p className="font-style medium-font-size">Leads</p>
          <p className="theme-font-color font-style medium-font-size">
            {totalLeads ? totalLeads : "-"}
          </p>
        </div>
        <div className="rounded bg-white d-flex justify-content-between p-3  my-3">
          <p className="font-style medium-font-size d-flex align-self-center">
            Prospect
          </p>
          <p className="theme-font-color font-style medium-font-size">
            {totalProspect ? totalProspect : "-"}
          </p>
        </div>
        <div className="rounded bg-white d-flex justify-content-between p-3 my-3">
          <p className="font-style medium-font-size">Customer</p>
          <p className="theme-font-color font-style medium-font-size">
            {totalCustomer ? totalCustomer : "-"}
          </p>
        </div>
      </div>
    );
  };
  // Render add Product modal function

  renderMailActionModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.actionForm}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <p className="font-style normal-font-size">Follow up action</p>
            <i
              className="fas fa-times-circle theme-font-color pointer"
              onClick={() => this.setState({ actionForm: false })}
            />
          </div>
          <Modal.Body>
            <form onSubmit={this.onSubmitActionForm}>
              <div className="row">
                <div className="col-md-10">
                  <ReactQuill
                    className="bg-white"
                    value={this.state.comments}
                    onChange={this.handleChange}
                    modules={this.modules}
                    formats={this.formats}
                    placeholder="Comments"
                    onFocus={() => this.setState({ commentsError: "" })}
                  />
                  {this.state.commentsError ? (
                    <span className="small-font-size theme-font-color">
                      {this.state.commentsError}
                    </span>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <div className="mx-3">
                    <a
                      className="text-decoration-none text-white normal-font-size font-style"
                      href={"mailto:" + this.state.email}
                    >
                      <i className="theme-font-color pointer far fa-envelope giga-font-size" />
                      <p className="xsmall-font-size theme-font-color mb-0 pointer">
                        Mail
                      </p>
                    </a>
                  </div>
                  <div className="mx-3">
                    <a
                      className="text-decoration-none text-white normal-font-size font-style"
                      href={"tel:" + this.state.mobile}
                    >
                      <i className="fas fa-phone-alt theme-font-color pointer giga-font-size"></i>
                      <p className="xsmall-font-size theme-font-color mb-0 pointer">
                        Call
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="py-1">
                <CustomDatePicker
                  label="Next follow up date & time"
                  selected={this.state.followupDate}
                  value={this.state.followupDate}
                  onChange={(value) => {
                    this.setState({ followupDate: value });
                  }}
                  onSelect={this.followupDateHandleChange}
                  error={this.state.followupDateErr}
                  minDate={moment("07/07/2021").format("DD/MM/YYYY")}
                  onFocus={() => this.setState({ followupDateErr: "" })}
                  disablePast
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="btn"
                  className="btn theme-font-color border-style font-style mt-3 py-2 small-font-size"
                  onClick={() => this.setState({ actionForm: false })}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn theme-bg-color font-style mt-3 py-2 text-white small-font-size"
                  onClick={this.onSubmitActionForm}
                >
                  Action
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Handle arrival change
  followupDateHandleChange = (followupDate) => {
    this.setState({ followupDate, followupDateErr: "" });
  };
  handleChange = (value) => {
    this.setState({ comments: value, commentsError: "" });
  };

  // Search handle function
  _handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ search: value }, () => {
      this.dashboardListApi();
    });
  };

  // Validation for follow up date
  validateFollowupDate = () => {
    const followupDateError = CheckDate(this.state.followupDate);
    console.log("followupDateError", followupDateError);
    if (followupDateError === 1) {
      this.setState({ followupDateErr: "Follow up date empty" });
      return false;
    }
    this.setState({ followupDateErr: "" });
    return true;
  };

  // Validation for comments
  validateDescription = () => {
    const descriptionError = CheckMessage(this.state.comments);
    console.log("descriptionError", descriptionError);
    if (descriptionError === 1) {
      this.setState({ commentsError: "Comments empty" });
      return false;
    } else if (descriptionError === 2) {
      this.setState({ commentsError: "Invalid Comments" });
      return false;
    } else {
      this.setState({ commentsError: "" });
      return true;
    }
  };

  validateAll = () => {
    const commentsInput = this.validateDescription();
    const followupDateInput = this.validateFollowupDate();
    if (commentsInput && followupDateInput) {
      return true;
    } else {
      return false;
    }
  };

  onSubmitActionForm = async (e) => {
    e.preventDefault();
    const allValidation = await this.validateAll();
    console.log("allValidation", allValidation);
    if (allValidation) {
      const requestData = {
        lead_id: this.state.id,
        follow_up_date: moment(this.state.followupDate).format("Do MMMM  YYYY"),
        comments: this.state.comments,
        user_id: User.user_id,
      };
      const response = await createViewLead(requestData);
      console.log("response", response);
      if (response.status === "success") {
        this.dashboardListApi();
        AppConfig.setMessage("Comments added successfully", false);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else if (response.status === "error") {
        AppConfig.setMessage(response.result);
      }
    }
  };
}
export default observer(Dashboard);
