// Add product modal
import React from "react";
import "../../../css/Common.css";
import {
  CustomInput,
  CustomSelect,
  CustomDatePicker,
} from "../../../common/Element";
import Modal from "react-bootstrap/Modal";
import "../../../css/Modal.css";
import { createEmployeeRoleOptions } from "../../../common/DropdownOptions";
import { getLocationDropdown } from "../../../apis/admin/Location";
import { getLotNoDropdown } from "../../../apis/admin/Employee";
import {
  createEmployee,
  updateEmployee,
  getEmployee,
} from "../../../apis/admin/Employee";
import AppConfig from "../../../modal/AppConfig";
import moment from "moment";
import User from "../../../modal/User";
import {
  CheckPassword,
  CheckEmail,
  CheckName,
  CheckPhone,
  DropDownCheck,
  CheckAddress,
} from "../../../common/Validation";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class CreateEmployee extends React.Component {
  state = {
    createEmployeeModal: false,
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    alternatePhoneNumber: "",
    address: "",
    role: "",
    lotNo: [],
    locationAddress: "",
    location: "",
    showPassword: false,
    endDate: null,
    lotNumberList: [],
    managerLocation: "",
  };
  async componentDidMount() {
    // Api call for location dropdown
    const response = await getLocationDropdown();
    let locationNameList = [];
    if (response && response.status === "success") {
      const result = response.result.location_dropdown;
      for (let i in result) {
        let locationName = {
          value: result[i].id,
          label: result[i].name,
        };
        locationNameList.push(locationName);
      }
    }
    // Api call for lot number dropdown
    const lotNumberResponse = await getLotNoDropdown();
    let lotNumberList = [];
    if (lotNumberResponse && lotNumberResponse.status === "success") {
      const result = lotNumberResponse.result.lotno_dropdown;
      for (let i in result) {
        let lotNo = {
          value: result[i].id,
          label: result[i].lot_no,
        };
        lotNumberList.push(lotNo);
      }
    }
    this.setState({
      locationNameList: locationNameList,
      lotNumberList: lotNumberList,
    });
  }

  async componentDidUpdate(prevProps) {
    const id = this.props.editUserId;
    if (this.props.status !== prevProps.status) {
      this.setState({ createEmployeeModal: this.props.status });
      const response = await getEmployee(id);
      if (response && response.status === "success") {
        let result = response.result;
        let lot_access = [];
        if (result.guestusers?.lot_access) {
          const lotAcceessData = result.guestusers.lot_access;
          for (let data of lotAcceessData) {
            lot_access.push(data["id"]);
          }
        }
        this.setState({
          name: result.users[0]?.name,
          email: result.users[0]?.email,
          phoneNumber: result.users[0]?.mobile,
          password: result.users[0]?.password,
          alternatePhoneNumber: result.users[0]?.alternate_mobile,
          address: result.users[0]?.address,
          role: result.users[0]?.role_id,
          endDate: result.guestusers?.access_end_date,
          lotNo: lot_access,
          locationAddress: result.guestusers?.loc_access.id,
        });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.hideButton ? (
          <div className="d-flex w-100 justify-content-between w-100">
            <p
              className="my-0 py-2  mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ createEmployeeModal: true });
              }}
            >
              {this.props.user === "employee"
                ? "Create Employee"
                : "Guest User"}
            </p>
            <i className="fas fa-arrow-right align-self-center"></i>
          </div>
        ) : null}
        {this.renderCreateEmployeeModal()}
      </>
    );
  }

  // Render add Product modal function

  renderCreateEmployeeModal() {
    const { user, editUserId } = this.props;
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.createEmployeeModal}
      >
        <div className="m-3">
          <div className="d-flex justify-content-between px-3">
            <p className="font-style normal-font-size">
              {user === "employee"
                ? editUserId
                  ? "Update Employee"
                  : "Create Employee"
                : "Guest User"}
            </p>
            <i
              className="fas fa-times-circle pointer theme-font-color"
              onClick={this.handleClose}
            />
          </div>
          <Modal.Body>
            <form>
              <div className="py-1">
                <CustomInput
                  type="text"
                  tabIndex="1"
                  id="employee-name"
                  placeholder="Name*"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                      nameError: "",
                    })
                  }
                  onFocus={() => this.setState({ nameError: "" })}
                  error={this.state.nameError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  tabIndex="2"
                  id="email"
                  placeholder="Email*"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                      emailError: "",
                    })
                  }
                  onFocus={() => this.setState({ emailError: "" })}
                  error={this.state.emailError}
                />
              </div>
              <div className="py-1">
                <CustomInput
                  type="text"
                  tabIndex="3"
                  id="phone-number"
                  placeholder="Phone Number*"
                  value={this.state.phoneNumber}
                  onChange={(e) =>
                    this.setState({
                      phoneNumber: e.target.value,
                      phoneNumberError: "",
                    })
                  }
                  onFocus={() => this.setState({ phoneNumberError: "" })}
                  error={this.state.phoneNumberError}
                />
              </div>
              {!this.props.editUserId ? (
                <div className="py-3">
                  <CustomInput
                    type={this.state.showPassword ? "text" : "password"}
                    id="password"
                    tabIndex="4"
                    placeholder="Password*"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        passwordError: "",
                      })
                    }
                    onFocus={() => this.setState({ passwordError: "" })}
                    icon={
                      this.state.showPassword
                        ? "far fa-eye-slash"
                        : "far fa-eye"
                    }
                    onClickIcon={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                    error={this.state.passwordError}
                  />
                </div>
              ) : null}
              {this.props.user === "employee" ? (
                <>
                  <div className="py-1">
                    <CustomInput
                      tabIndex="5"
                      type="text"
                      id="alternate-phone-number"
                      placeholder="Alternate Phone Number"
                      value={this.state.alternatePhoneNumber}
                      onChange={(e) =>
                        this.setState({
                          alternatePhoneNumber: e.target.value,
                          alternatePhoneNumberError: "",
                        })
                      }
                      onFocus={() =>
                        this.setState({
                          alternatePhoneNumberError: "",
                        })
                      }
                      error={this.state.alternatePhoneNumberError}
                    />
                  </div>
                  <div className="py-1">
                    <CustomInput
                      tabIndex="6"
                      type="text"
                      id="address"
                      placeholder="Address*"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({
                          address: e.target.value,
                          addressError: "",
                        })
                      }
                      onFocus={() =>
                        this.setState({
                          addressError: "",
                        })
                      }
                      error={this.state.addressError}
                    />
                  </div>
                  <div className="mt-3">
                    <CustomSelect
                      // className='black'
                      tabIndex="7"
                      placeholder="Role*"
                      value={this.state.role}
                      onChange={(e) =>
                        this.setState({
                          role: e.target.value,
                        })
                      }
                      options={createEmployeeRoleOptions}
                      onFocus={() => this.setState({ roleError: "" })}
                      error={this.state.roleError}
                    />
                  </div>
                  {this.state.role === "6" ? (
                    <div className="mt-3">
                      <CustomSelect
                        className="black"
                        tabIndex="9"
                        placeholder="Select location"
                        value={this.state.managerLocation}
                        onChange={(e) =>
                          this.setState({
                            managerLocation: e.target.value,
                          })
                        }
                        options={this.state.locationNameList}
                        onFocus={() =>
                          this.setState({
                            managerLocationError: "",
                          })
                        }
                        error={this.state.managerLocationError}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <CustomDatePicker
                    tabIndex="8"
                    label="End Date"
                    selected={this.state.endDate}
                    value={this.state.endDate}
                    onChange={(value) => {
                      this.setState({ endDate: value });
                    }}
                    onSelect={this.endDateHandleChange}
                    error={this.state.endDateError}
                    onFocus={() => this.setState({ endDateError: "" })}
                    disablePast
                  />
                  <FormControl className="col-md-12 mt-2">
                    <InputLabel shrink htmlFor="select-multiple-native">
                      Select lot No.
                    </InputLabel>
                    <Select
                      multiple
                      native
                      value={this.state.lotNo}
                      onChange={this.handleChangeMultiple}
                      onFocus={() =>
                        this.setState({
                          lotOrLocationError: "",
                        })
                      }
                      inputProps={{
                        id: "select-multiple-native",
                      }}
                    >
                      {this.state.lotNumberList.map((lotNo, i) => {
                        return (
                          <option key={i} value={lotNo.value}>
                            {lotNo.label}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <div className="mt-3">
                    <CustomSelect
                      className="black"
                      tabIndex="10"
                      placeholder="Select location*"
                      value={this.state.locationAddress}
                      onChange={(e) =>
                        this.setState({
                          locationAddress: e.target.value,
                        })
                      }
                      options={this.state.locationNameList}
                      onFocus={() =>
                        this.setState({
                          lotOrLocationError: "",
                        })
                      }
                    />
                  </div>
                  {this.state.lotOrLocationError ? (
                    <span className="small-font-size theme-font-color">
                      {this.state.lotOrLocationError}
                    </span>
                  ) : null}
                </>
              )}
              <button
                type="submit"
                className="btn theme-bg-color col-md-12 font-style mt-3 py-2 text-white"
                onClick={this.onSubmitCreateEmployee}
              >
                Save
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      lotNo: value,
    });
  };

  handleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  onSelectLotNo = (e) => {
    let SelectedLotNo = [];
    for (let i in e) {
      let lotNo = {
        value: e[i].id,
        label: e[i].name,
      };
      SelectedLotNo.push(lotNo);
    }
    this.setState({
      selectLotNo: SelectedLotNo,
    });
  };

  handleClose = () => {
    this.setState({ createEmployeeModal: false });
    this.props.closeModel(false);
  };

  // Handle start date change
  startDateHandleChange = (startDate) => {
    this.setState({ startDate, startDateErr: "" });
  };

  // Handle end date change
  endDateHandleChange = (endDate) => {
    this.setState({ endDate, endDateErr: "" });
  };

  // Validation for name
  validateName = () => {
    const nameError = CheckName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Name empty" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Invalid name" });
      return false;
    } else return true;
  };

  // Validation for email
  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Email empty" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email" });
      return false;
    } else return true;
  };

  // Validation for phone Number
  validatePhoneNumber = () => {
    const phoneNumberError = CheckPhone(this.state.phoneNumber);
    if (phoneNumberError === 1) {
      this.setState({ phoneNumberError: "Phone Number empty" });
      return false;
    } else if (phoneNumberError === 2) {
      this.setState({ phoneNumberError: "Invalid phone number" });
      return false;
    } else return true;
  };

  // Validation for address
  validateAddress = () => {
    const addressError = CheckName(this.state.address);
    if (addressError === 1) {
      this.setState({ addressError: "Address empty" });
      return false;
    } else if (addressError === 2) {
      this.setState({ addressError: "Invalid Address" });
      return false;
    } else return true;
  };

  // Validation for password
  validatePassword = () => {
    const passwordError = CheckPassword(this.state.password);
    if (passwordError === 1) {
      this.setState({ passwordError: "Password empty" });
      return false;
    } else if (passwordError === 2) {
      this.setState({ passwordError: "Invalid password" });
      return false;
    } else return true;
  };

  // Validation for alternate phone number
  validateAlternatePhoneNumber = () => {
    const alternatePhNoError = CheckPhone(this.state.alternatePhoneNumber);
    if (alternatePhNoError === 2) {
      this.setState({
        alternatePhoneNumberError: "Enter valid alternate phone number",
      });
      return false;
    } else return true;
  };

  // Validation for address
  validateAddress = () => {
    const addressError = CheckAddress(this.state.address);
    if (addressError === 1) {
      this.setState({ addressError: "Address empty" });
      return false;
    } else if (addressError === 2) {
      this.setState({ addressError: "Invalid address" });
      return false;
    } else return true;
  };

  // Validation for role
  validateRole = () => {
    const roleError = DropDownCheck(this.state.role);
    if (roleError === 1) {
      this.setState({ roleError: "Select role" });
      return false;
    } else return true;
  };

  // newly added
  // Validation for location access
  validateManagerLocationAddress = () => {
    const locationAddressError = DropDownCheck(this.state.managerLocation);
    if (locationAddressError === 1) {
      this.setState({ managerLocationError: "Select Location empty" });
      return false;
    } else return true;
  };

  // Validation for lot no

  validateLotNo = () => {
    const lotNoError = DropDownCheck(this.state.lotNo);
    if (lotNoError === 1) {
      this.setState({ lotNoError: "Select lot no." });
      return false;
    } else if (lotNoError === 1) {
      this.setState({ lotNoError: "Select Location Access" });
      return false;
    } else return true;
  };

  // Validation for location access
  validateLocationAddress = () => {
    const locationAddressError = DropDownCheck(this.state.locationAddress);
    if (locationAddressError === 1) {
      this.setState({ locationAddressError: "Select Location Access" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const nameInput = this.validateName();
    const emailInput = this.validateEmail();
    const phoneNumberInput = this.validatePhoneNumber();
    const passwordInput = this.validatePassword();
    const alternatePhoneNumberInput = this.validatePhoneNumber();
    const addressInput = this.validateAddress();
    const roleInput = this.validateRole();
    const managerLocation = this.validateManagerLocationAddress();
    if (
      this.props.user === "guest" &&
      this.state.lotNo.length === 0 &&
      this.state.locationAddress === ""
    ) {
      this.setState({
        lotOrLocationError: "Select location empty",
      });
      return false;
    }
    const id = this.props.editUserId;

    console.log(
      "Guste Create => ",
      this.props.user === "guest",
      nameInput,
      emailInput,
      phoneNumberInput,
      passwordInput
    );
    console.log(
      "employee Create => ",
      this.props.user === "employee",
      nameInput,
      emailInput,
      phoneNumberInput,
      passwordInput,
      addressInput,
      alternatePhoneNumberInput
    );

    if (
      this.props.user === "employee" &&
      id &&
      nameInput &&
      emailInput &&
      phoneNumberInput &&
      addressInput &&
      alternatePhoneNumberInput
    ) {
      console.log(
        id,
        nameInput,
        emailInput,
        phoneNumberInput,
        addressInput,
        alternatePhoneNumberInput
      );
      return true;
    }
    if (this.props.user === "employee") {
      if (managerLocation) return true;
    }
    if (
      this.props.user === "employee" &&
      nameInput &&
      emailInput &&
      phoneNumberInput &&
      passwordInput &&
      addressInput &&
      alternatePhoneNumberInput
    ) {
      console.log(
        nameInput,
        emailInput,
        phoneNumberInput,
        passwordInput,
        addressInput,
        alternatePhoneNumberInput
      );
      return true;
    }
    if (
      this.props.user === "guest" &&
      id &&
      nameInput &&
      emailInput &&
      phoneNumberInput &&
      passwordInput
    ) {
      console.log(nameInput, emailInput, phoneNumberInput, passwordInput);
      // this else if for guest user
      return true;
    }
    if (
      this.props.user === "guest" &&
      nameInput &&
      emailInput &&
      phoneNumberInput
    ) {
      console.log(id, nameInput, emailInput, phoneNumberInput);
      return true;
    }

    console.log(
      nameInput,
      emailInput,
      phoneNumberInput,
      passwordInput,
      addressInput,
      alternatePhoneNumberInput
    );
    return false;
  };

  // onsubmit function for create employee inputs

  onSubmitCreateEmployee = async (e) => {
    e.preventDefault();
    console.log("this.props.user", this.props.user);
    const id = this.props.editUserId;
    const allValidation = await this.ValidateAll();
    console.log("allvali", allValidation);
    if (allValidation) {
      let requestData = {
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.phoneNumber,
        address: this.state.address,
        password: this.state.password,
        user_id: User.user_id,
      };
      if (this.props.user === "employee") {
        requestData["role_id"] = this.state.role;
        requestData["created_by"] = User.user_id;
        requestData["alternate_mobile"] = this.state.alternatePhoneNumber;
        requestData["access_end_date"] = null;
        requestData["location_access"] = this.state.managerLocation; // new changes
        requestData["lot_access"] = null;
      } else {
        requestData["role_id"] = "5";
        requestData["created_by"] = User.user_id;
        requestData["access_end_date"] = moment(this.state.endDate).format(
          "Do MMMM  YYYY"
        );
        requestData["lot_access"] = this.state.lotNo;
        requestData["location_access"] = this.state.locationAddress;
      }

      if (id) {
        requestData["updated_by"] = User.user_id;
        const response = await updateEmployee(requestData, id);
        if (response.status === "success") {
          this.setState({ createEmployeeModal: false });
          this.props.closeModel(false);
          User.setRefresh(true);
          this.props.reRenderTable();
          AppConfig.setMessage("User updated successfully", false);
        } else if (response.status === "error") {
          // this.props.closeModel(false);
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      } else {
        const response = await createEmployee(requestData);
        if (response.status === "success") {
          this.setState({ createEmployeeModal: false });
          User.setRefresh(true);
          this.props.closeModel(false);
          this.props.reRenderTable();
          AppConfig.setMessage("User created successfully", false);
        } else if (response.status === "error") {
          // this.setState({ createEmployeeModal: false });
          // this.props.closeModel(false);
          // User.setRefresh(true);
          const result = response.result;
          let message = result;
          if (result[Object.keys(response.result)[0]]) {
            message = result[Object.keys(response.result)[0]];
          }
          AppConfig.setMessage(message);
        }
      }
    }
  };
}

export default CreateEmployee;
