import React from "react";
import { observer } from "mobx-react";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../css/DataTable.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  productList,
  getProduct,
  deleteProduct,
  getQRCode,
  deleteMultipleProducts,
} from "../../apis/admin/Product";
import { callApi } from "../../apis/Api";
import { uploadFile } from "../../apis/General";
import Modal from "react-bootstrap/Modal";
import ConfirmModal from "../../common/ConfirmModal";
import User from "../../modal/User";
import AppConfig from "../../modal/AppConfig";
import { shadesList } from "../../apis/admin/Shades";
import { getLocationDropdown } from "../../apis/admin/Location";
import { CustomSelect } from "../../common/Element";
import {
  createGallery,
  updateGallery,
  deleteGallery,
} from "../../apis/admin/Gallery";
import "../../css/Common.css";
import AddProduct from "../admin/create/AddProduct";
import ImageGallery from "react-image-gallery";

class Product extends React.Component {
  state = {
    rows: [],
    columns: [],
    size: 10,
    current_page: 1,
    totalData: 0,
    viewProductGallery: false,
    editProductId: "",
    viewProductGalleryId: "",
    status: false,
    viewProductConfirmModal: false,
    galleryCollection: [],
    editGallaryId: "",
    viewGalleryConfirmModal: false,
    search: "",
    filter: "",
    viewQRCode: false,
    viewQRCodeId: "",
    viewProductDetails: false,
    viewProductDetailsId: "",
    uploadPics: "",
    shadeId: "",
    location: "",
    hideButton: false,
    qrcodeImage: "",
    isExpanded: false,
    isEdit: false,
    isMultipleDelete: false,
    productIdsArr: [],
  };

  async componentDidMount() {
    // Api call for location dropdown
    const requestData = {
      role_id: User.role_id,
      user_id: User.user_id,
    };
    const response = await getLocationDropdown(requestData);
    let locationNameList = [];
    if (response && response.status === "success") {
      console.log("called");
      const result = response.result.location_dropdown;
      for (let i in result) {
        let locationName = {
          value: result[i].id,
          label: result[i].name,
        };
        locationNameList.push(locationName);
      }
    }
    // Api call for shade dropdown
    const shadeResponse = await shadesList(requestData);
    let shadeList = [];
    if (shadeResponse && shadeResponse.status === "success") {
      console.log("called");
      const result = shadeResponse.result.shades_dropdown;
      for (let i in result) {
        let shade = {
          value: result[i].id,
          label: result[i].name,
        };
        shadeList.push(shade);
      }
    }
    this.setState({
      locationNameList: locationNameList,
      shadeList: shadeList,
    });

    const columns = [
      { field: "Id", hide: true },
      { field: "lot_no", headerName: "Lot No", minWidth: 200 },
      { field: "dimensions", headerName: "Dimensions", minWidth: 300 },
      { field: "shade_name", headerName: "Shade", minWidth: 200 },
      { field: "location_name", headerName: "Location", minWidth: 200 },
    ];

    if (!(User.role_id === 2 || User.role_id === 3)) {
      columns.push({
        field: "action",
        headerName: "Action",
        minWidth: 130,
        renderCell: (cellValues) => (
          <div>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="right"
              id="popover-basic"
              overlay={this.popover(cellValues.id)}
            >
              <i className="fas fa-ellipsis-v pointer theme-font-color" />
            </OverlayTrigger>
            {this.props.admin ? (
              <i
                className="far fa-eye pointer mx-2 theme-font-color"
                onClick={() => this.viewProductDetails(cellValues.id)}
              />
            ) : null}
            <i
              className="far fa-images pointer mx-2 theme-font-color"
              onClick={() => this.viewProductGallery(cellValues.id)}
            />
            {!this.props.admin ? (
              <i
                className="fas fa-qrcode pointer mx-2 theme-font-color"
                onClick={() => this.viewQRCodeModal(cellValues.id)}
              />
            ) : null}
          </div>
        ),
      });
    }

    this.productApi();
    this.setState({ columns: columns });
  }

  // Render Menu function for data table data
  popover = (id) => {
    const role_id = User.role_id;
    console.log("role_id", role_id);
    return (
      <Popover id="popover-basic" className="border-0 p-3">
        {role_id === 4 ||
        role_id === 2 ||
        role_id === 3 ||
        role_id === 1 ||
        role_id === 6 ? (
          <p className="my-1 pointer ">
            <i
              className="far fa-edit mr-2"
              onClick={() => this.editProduct(id)}
            >
              {" "}
              Edit
            </i>
          </p>
        ) : null}
        {role_id === 1 ? (
          <p
            className="my-1 pointer "
            onClick={() => this.deleteProductModal(id)}
          >
            <i className="far fa-trash-alt mr-2"> Delete</i>
          </p>
        ) : null}
      </Popover>
    );
  };

  productApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
      search: this.state.search,
      shade_id: this.state.shadeId,
      location_id: this.state.location,
      user_id: User.user_id,
      role_id: User.role_id,
    };
    const response = await productList(requestData);
    console.log("response_productList", response);
    if (response && response.status === "success") {
      const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      if (rows.length > 0) {
        for (let i in rows) {
          apiRows.push(rows[i]);
        }
      }
      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          Id: result.data[i].id,
          product_code: result.data[i].product_code,
          lot_no: result.data[i].lot_no,
          dimensions:
            result.data[i].width +
            " " +
            result.data[i].width_unit +
            " " +
            result.data[i].height +
            " " +
            result.data[i].height_unit +
            " " +
            result.data[i].thickness +
            " " +
            result.data[i].thickness_unit +
            " ",
          shade_name: result.data[i].shade_name,
          location_name: result.data[i].location_name,
          lot_arrival_date: result.data[i].lot_arrival_date,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  viewProductApi = async (id) => {
    const response = await getProduct(id);
    if (response && response.status) {
      let result = response.result.products[0];
      this.setState({
        productCode: result.product_code,
        lotNo: result.lot_no,
        location: result.location_name,
        shadeName: result.shade_name,
        width: result.width,
        widthUnit: result.width_unit,
        height: result.height,
        heightUnit: result.height_unit,
        thickness: result.thickness,
        thicknessUnit: result.thickness_unit,
        lotArrivalDate: result.lot_arrival_date,
        quantity: result.quantity,
        sellingPrice: result.price,
        productImage: result?.product_image?.image_path,
      });
    }
  };

  galleryApi = async () => {
    // View Gallery api
    const { viewProductGalleryId } = this.state;
    let galleryFunctionUrl = "productimage";
    const response = await callApi(
      galleryFunctionUrl,
      {},
      "GET",
      viewProductGalleryId
    );

    console.log("result", JSON.stringify(response.result));
    const result = response.result;
    let galleryCollection = [];
    for (let i in result.productmages) {
      {
        this.props.admin
          ? galleryCollection.push(result.productmages[i])
          : galleryCollection.push({
              original: result.productmages[i].image_path,
              thumbnail: result.productmages[i].image_path,
            });
      }
    }
    this.setState({ galleryCollection: galleryCollection });
  };

  qrCodeApi = (id) => {
    getQRCode(id, this.callBackQR);
  };

  callBackQR = (response = false) => {
    if (response) {
      this.setState({ qrcodeImage: response });
    }
  };

  render() {
    const role_id = User.role_id;
    if (User.refresh) {
      this.productApi();
      User.setRefresh(false);
    }
    return (
      <div className="m-3 row no-printme">
        <div className="border col-md-4 bg-white rounded border-secondary d-flex justify-content-between p-3">
          <input
            type="text"
            className="no-outline input-style small-font-size w-100"
            placeholder="Search.."
            onChange={this._handleSearchChange}
          />
          <i className="fas fa-search align-self-center" />
        </div>
        <div className="col-md-3">
          <CustomSelect
            className="black"
            placeholder="Filter Shade*"
            value={this.state.shadeId}
            onChange={this.sortingShadeHandler}
            options={this.state.shadeList}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            className="black"
            placeholder="Filter Location*"
            value={this.state.location}
            onChange={this.sortingLocationHandler}
            options={this.state.locationNameList}
          />
        </div>
        {role_id === 2 || role_id === 3 || role_id === 4 || role_id === 1 ? (
          this.state.isMultipleDelete ? (
            <div className="col-md-2 my-2">
              <button
                type="btn"
                className="btn theme-bg-color text-white fw-bold normal-font-size"
                onClick={() => this.handleDeleteMultiple()}
              >
                Delete
              </button>
            </div>
          ) : null
        ) : null}
        <div className="my-5">{this.renderTable()}</div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewProductConfirmModal}
          heading="Delete Product"
          title="Are you sure you want to delete the product?"
          confirm={() => this.getProductSuccess()}
          handleClose={() => this.setState({ viewProductConfirmModal: false })}
        />
        <ConfirmModal
          delete={true}
          visible={this.state.viewGalleryConfirmModal}
          heading="Delete Picture"
          title="Are you sure you want to delete the picture?"
          confirm={() => this.getGallerySuccess()}
          handleClose={() => this.setState({ viewGalleryConfirmModal: false })}
        />
        <AddProduct
          hideButton={this.state.hideButton}
          status={this.state.status}
          editProductId={this.state.editProductId}
          closeModel={() => this.setState({ status: false, isEdit: false })}
          isEdit={this.state.isEdit}
        />
      </div>
    );
  }

  renderTable = () => {
    const CustomToolbar = () => {
      return (
        <div className="px-3 py-2 d-flex justify-content-between no-printme">
          <p className="theme-font-color normal-font-size align-self-center">
            Product Details
          </p>
          <GridToolbarExport />
        </div>
      );
    };
    console.log("this.state.rows => ", this.state.rows);
    return (
      <div style={{ height: 550, width: "100%" }} className="no-printme">
        <DataGrid
          className="black"
          rows={this.state.rows}
          columns={this.state.columns}
          pageSize={this.state.size}
          page={this.state.current_page - 1}
          onPageSizeChange={(size) =>
            this.setState({ size: size, current_page: 1 }, () =>
              this.productApi()
            )
          }
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(current_page) =>
            this.setState({ current_page: parseInt(current_page) + 1 }, () =>
              this.productApi()
            )
          }
          rowCount={this.state.totalData}
          checkboxSelection={true}
          disableSelection={true}
          hideFooterSelectedRowCount={true}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(ids) => {
            if (ids.length > 0) {
              console.log("selection_product_ids", ids);
              this.setState({
                isMultipleDelete: true,
                productIdsArr: [...ids],
              });
            } else {
              this.setState({
                isMultipleDelete: false,
                productIdsArr: [...ids],
              });
            }
          }}
        />
        {this.renderProductGalleryModal()}
        {this.renderQRCodeModal()}
        {this.renderViewProductDetailsModal()}
      </div>
    );
  };

  // Render upload picture modal function

  renderProductGalleryModal = () => {
    const { galleryCollection } = this.state;
    return (
      <Modal
        size="xl"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewProductGallery}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              Product Gallery
            </p>
            <i
              className="fas fa-times-circle  pointer theme-font-color"
              onClick={() => {
                this.setState({
                  viewProductGallery: false,
                  galleryCollection: [],
                });
              }}
            />
          </div>
          <Modal.Body>
            {this.props.admin ? (
              <div className="row p-3">
                {this.state.galleryCollection.map((galleryCollection) => (
                  <div className="col-md-3 p-2 mx-0">
                    <img
                      src={galleryCollection.image_path}
                      alt="gal-img"
                      width="100%"
                      height="175px"
                    />
                    {this.props.admin ? (
                      <div className="d-flex my-3">
                        <i
                          className="far fa-edit pointer mx-2"
                          onClick={(e) => {
                            this.setState({
                              editGallaryId: galleryCollection.id,
                            });
                            this.handleUploadPicsSelect(e);
                          }}
                        />
                        <i
                          className="far fa-trash-alt pointer mx-2"
                          onClick={() => {
                            this.deleteGallery(galleryCollection.id);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <div className="row p-3">
                {galleryCollection ? (
                  <ImageGallery
                    items={galleryCollection}
                    thumbnailPosition="left"
                    showBullets={true}
                    showIndex={true}
                    showThumbnails={true}
                    lazyLoad={true}
                    showPlayButton={true}
                    autoPlay={true}
                  />
                ) : null}
              </div>
            )}

            {this.props.admin ? (
              <form
                onSubmit={this.onSubmitGalleryPic}
                encType="multipart/form-data"
              >
                {this.state.uploadPics ? (
                  <div>{this.renderUploadedPic()}</div>
                ) : null}
                <div className="d-flex justify-content-between">
                  <input
                    type="file"
                    multiple
                    id="uploadPics"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.selectUploadPics}
                  />
                  <button
                    className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                    onClick={this.handleUploadPicsSelect}
                  >
                    <i className="fas fa-upload" />
                    <span className="mx-3">Upload</span>
                  </button>
                  <button
                    type="submit"
                    className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                  >
                    <span className="mx-3">Save</span>
                  </button>
                </div>
              </form>
            ) : null}
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Render qr code modal function

  renderQRCodeModal = () => {
    const qrCode = this.state.qrcodeImage;
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewQRCode}
      >
        <div className="p-3">
          <div className="d-flex justify-content-end mx-3 no-printme">
            <i
              className="fas fa-times-circle  pointer theme-font-color no-printme"
              onClick={() => {
                this.setState({ viewQRCode: false });
              }}
            />
          </div>
          <Modal.Body>
            <div className="row p-3">
              <div className="col-md-12 p-2 mx-0 ">
                {this.state.qrcodeImage ? (
                  <div className="bg-white mx-3 p-5 d-flex justify-content-center printme">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: qrCode,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <button
              type="btn"
              className="no-printme btn theme-bg-color text-white normal-font-size font-style py-2 my-3 col-md-12"
              onClick={() => window.print()}
            >
              <i className="fas fa-print" />
              <span className="mx-3">Print</span>
            </button>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  renderViewProductDetailsModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewProductDetails}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="normal-font-size  fw-bold my-0 theme-font-color">
              View Product Details
            </p>
            <i
              className="fas fa-times-circle  pointer theme-font-color"
              onClick={() => {
                this.setState({ viewProductDetails: false });
              }}
            />
          </div>
          <Modal.Body>
            <div>
              {this.state.productImage ? (
                <div className="border-radius col-md-9 my-3">
                  <img
                    className="border-radius"
                    src={this.state.productImage}
                    alt="product"
                    width="100%"
                    height="300px"
                  />
                </div>
              ) : null}
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Product Code: </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {" "}
                    {this.state.productCode ? this.state.productCode : "-"}{" "}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Lot No.: </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.lotNo ? this.state.lotNo : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Location: </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.location ? this.state.location : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size">
                    {" "}
                    Lot Arrival Date:
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.lotArrivalDate
                      ? this.state.lotArrivalDate
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Shade </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.shadeName ? this.state.shadeName : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Quantity </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.quantity ? this.state.quantity : "-"}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">
                  <p className="text-white normal-font-size"> Selling Price </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.sellingPrice ? this.state.sellingPrice : "-"}
                  </p>
                </div>
              </div>
              <p className="text-white normal-font-size"> Dimensions</p>
              <div className="d-flex justify-content-between">
                <div className="col-md-4">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.width ? this.state.width : "-"}{" "}
                    {this.state.widthUnit ? this.state.widthUnit : "-"}{" "}
                  </p>
                  <p className="text-white xsmall-font-size"> Width</p>
                </div>
                <div className="col-md-4">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.height ? this.state.height : "-"}{" "}
                    {this.state.heightUnit ? this.state.heightUnit : "-"}{" "}
                  </p>
                  <p className="text-white xsmall-font-size"> Height</p>
                </div>
                <div className="col-md-4">
                  <p className="text-white normal-font-size fw-bold">
                    {this.state.thickness ? this.state.thickness : "-"}{" "}
                    {this.state.thicknessUnit ? this.state.thicknessUnit : "-"}
                  </p>
                  <p className="text-white xsmall-font-size"> Thickness</p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Search handle function
  _handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, search: value }, () => {
      this.productApi();
    });
  };

  viewProductGallery = (id) => {
    this.setState(
      { viewProductGallery: true, viewProductGalleryId: id },
      () => {
        this.galleryApi();
      }
    );
  };

  viewQRCodeModal = (id) => {
    this.setState({ viewQRCode: true, viewQRCodeId: id }, () => {
      this.qrCodeApi(id);
    });
  };

  viewProductDetails = (id) => {
    this.setState(
      { viewProductDetails: true, viewProductDetailsId: id },
      () => {
        this.viewProductApi(this.state.viewProductDetailsId);
      }
    );
  };
  // Delete project confirm modal
  deleteProductModal = (id) => {
    this.setState({ viewProductConfirmModal: true, deleteId: id });
  };

  // Edit project modal
  editProduct = (id) => {
    this.setState({ editProductId: id, status: true, isEdit: true });
  };

  // Delete project api function
  getProductSuccess = () => {
    const id = this.state.deleteId;
    deleteProduct(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewProductConfirmModal: false, rows: [] });
        User.setRefresh(true);
        AppConfig.setMessage(response.result, false);
      } else {
        this.setState({ viewProductConfirmModal: false });
        User.setRefresh(true);
        AppConfig.setMessage(response.result);
      }
    });
  };

  // Handle file select

  handleUploadPicsSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("uploadPics");
    fileSelector.click();
  };

  // Select file

  selectUploadPics = (e) => {
    e.preventDefault();
    const uploadPics = e.target.files[0];
    const type = "uploadPics";
    uploadFile(uploadPics, this.callBackUploadPics, type);
  };

  //  Call back function for uploaded pics
  callBackUploadPics = (response = false) => {
    if (response && response.status === "success") {
      this.setState(
        {
          uploadPics: response.result.productimage,
          galleryCollection: [],
        },
        () => this.galleryApi()
      );
    }
  };

  // Create and update uploaded gallery images
  onSubmitGalleryPic = async (e) => {
    e.preventDefault();
    const id = this.state.editGallaryId;
    let requestData = {
      user_id: User.user_id,
      product_id: this.state.viewProductGalleryId,
      image_path: this.state.uploadPics,
    };

    let response = "";
    if (id) {
      requestData["updated_by"] = User.user_id;
      response = await updateGallery(requestData, id);
    } else {
      requestData["created_by"] = User.user_id;
      response = await createGallery(requestData);
    }
    if (response.status === "success") {
      this.setState({
        viewProductGallery: false,
        uploadPics: "",
        viewProductGalleryId: "",
      });
      // User.setRefresh(true);
      AppConfig.setMessage("Picture uploaded successfully", false);
    } else if (response.status === "error") {
      User.setRefresh(true);
      this.setState({ viewProductGallery: false });
      AppConfig.setMessage(response.result);
    }
  };

  // Delete gallery confirm modal
  deleteGallery = (id) => {
    this.setState({ viewGalleryConfirmModal: true, deleteGalleryId: id });
  };

  // // Delete gallery api function
  getGallerySuccess = () => {
    const id = this.state.deleteGalleryId;
    deleteGallery(id).then((response) => {
      if (response && response.status === "success") {
        this.setState(
          { viewGalleryConfirmModal: false, galleryCollection: [] },
          () => this.galleryApi()
        );
        User.setRefresh(true);
        AppConfig.setMessage("Product Deleted Successfully", false);
      } else {
        this.setState({ viewProjectConfirmModal: false });
        User.setRefresh(true);
        AppConfig.setMessage(response.result);
      }
    });
  };

  //Render uploaded pic

  renderUploadedPic = () => {
    return (
      <div className="col-md-4">
        <div className="col-md-12 border border-dark rounded bg-white">
          <div className="row">
            <img src={this.state.uploadPics} alt="pic" />
          </div>
        </div>
        <i
          className="fas fa-edit theme-font-color big-font-size mx-2 pointer"
          onClick={this.handleUploadPicsSelect}
        />
        <i
          className="fas fa-trash-alt theme-font-color big-font-size mx-2 pointer"
          onClick={() => this.setState({ uploadPics: "" })}
        ></i>
      </div>
    );
  };

  // Sorting handler function
  sortingShadeHandler = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, shadeId: value }, () => {
      this.productApi();
    });
  };
  // Sorting handler function
  sortingLocationHandler = (e) => {
    const { value } = e.target;
    this.setState({ rows: [], current_page: 1, location: value }, () => {
      this.productApi();
    });
  };

  // Delete Multiple Lead
  handleDeleteMultiple = async () => {
    const requestBody = {
      id: [...this.state.productIdsArr],
    };
    console.log("requestBody", requestBody);
    try {
      const response = await deleteMultipleProducts(requestBody);
      console.log("response", response);
      if (response && response.status === "success") {
        this.setState({ rows: [], totalData: 0, productIdsArr: [] }, () => {
          this.productApi();
          AppConfig.setMessage(response.result, false);
        });
      } else {
        AppConfig.setMessage(response.result);
      }
    } catch (err) {
      console.log("deleteMultipleLeads_err", err);
    }
  };
}
export default observer(Product);
