import React from "react";
import { Layout } from "../../common/Element";
import "../../css/Common.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../common/Header";
import { observer } from "mobx-react";
import Product from "./Product";
import ProductGallery from "./ProductGallery";
import User from "../../modal/User";

class Inventory extends React.Component {
	state = {
		row: [],
		columns: [],
		size: 10,
		current_page: 1,
		totalData: 0,
		menuTab: "",
	};
	componentDidMount() {
		const role_id = User.role_id;
		if (role_id === 5) {
			this.setState({ menuTab: "product_gallery" });
		}
	}
	render() {
		const role_id = User.role_id;
		return (
			<Layout>
				<Header>
					<h3 className="font-style">Inventory</h3>
					<div className="col-md-10 normal-font-size">
						<Tabs
							defaultActiveKey="overview"
							id="controlled-tab-example"
							onSelect={(menuTab) => this.setState({ menuTab })}
						>
							{role_id === 1 ||
							role_id === 2 ||
							role_id === 3 ||
							role_id === 4 ? (
								<Tab
									eventKey="overview"
									title="Overview"
									active
								/>
							) : null}
							<Tab
								eventKey="product_gallery"
								title="Product Gallery"
							/>
						</Tabs>
					</div>
				</Header>
				{this.renderPage()}
			</Layout>
		);
	}

	renderPage = () => {
		if (this.state.menuTab === "product_gallery") {
			return <ProductGallery />;
		} else {
			return <Product editProduct={this.state.editProductId} />;
		}
	};
}
export default observer(Inventory);
