// Login page
import React from "react";
import { Link } from "react-router-dom";
import { CheckUserName, CheckPassword } from "../common/Validation";
import { CustomInput } from "../common/Element";
import { login } from "../apis/General";
import Logo from "../assets/logo.png";
import LoginBgImg from "../assets/login/loginbg.jpg";
import LoginImg from "../assets/login/heroLogin.png";
import SigninIcon from "../assets/login/element.png";
import AppConfig from "../modal/AppConfig";
import User from "../modal/User";
import { observer } from "mobx-react";
import Toast from "react-bootstrap/Toast";

class SignIn extends React.Component {
	state = {
		username: "",
		password: "",
		error: "",
		showPassword: false,
		status: "",
		success: "",
	};
	render() {
		return (
			<div
				className="login-bg"
				style={{ backgroundImage: `url(${LoginBgImg})` }}
			>
				<div className="col-md-10">
					<div className="row login-form-container">
						<div className="col-md-8 p-5">
							<div className="d-flex justify-content-center">
								<img src={Logo} alt="logo" />
							</div>
							<div className="d-flex justify-content-center">
								<div className="col-md-8">
									<img
										src={LoginImg}
										alt="login-img"
										width="100%"
									/>
								</div>
							</div>
						</div>
						<div className="col-md-4 form-container">
							{this.renderLogin()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	// Render signup form
	renderLogin() {
		let message = "Error!";
		let classMessage = "alert-danger";
		if (this.state.success) {
			message = "Success!";
			classMessage = "alert-success";
		}
		return (
			<div className="col-md-12 p-3 my-4">
				<div className="d-flex justify-content-between">
					<p className="giga-font-size font-style text-white align-self-center">
						Sign in
					</p>
					<img src={SigninIcon} alt="signin-icon" />
				</div>
				<p className="medium-font-size font-style text-white">
					Please enter your login details.
				</p>
				<form onSubmit={this.onSubmitLogin}>
					<div className="py-3">
						<CustomInput
							type="text"
							id="email-mobile"
							placeholder="Email/Mobile No."
							value={this.state.username}
							onChange={(e) =>
								this.setState({
									username: e.target.value,
									usernameError: "",
								})
							}
							onFocus={() => this.setState({ usernameError: "" })}
							icon="fas fa-envelope"
							error={this.state.usernameError}
							tabIndex="1"
						/>
					</div>

					<div className="py-3">
						<CustomInput
							type={this.state.showPassword ? "text" : "password"}
							id="password"
							tabIndex="2"
							placeholder="Password"
							value={this.state.password}
							onChange={(e) =>
								this.setState({
									password: e.target.value,
									passwordError: "",
								})
							}
							onFocus={() => this.setState({ passwordError: "" })}
							icon={
								this.state.showPassword
									? "far fa-eye-slash"
									: "far fa-eye"
							}
							onClickIcon={() =>
								this.setState({
									showPassword: !this.state.showPassword,
								})
							}
							error={this.state.passwordError}
						/>
					</div>
					<Link
						to="/forgot-password"
						className="text-decoration-none text-white"
					>
						<p className="small-font-size  font-weight-bold my-3">
							Forgot Password?
						</p>
					</Link>
					{this.state.error ? (
						<Toast
							onClose={() => this.setState({ error: "" })}
							show={this.state.error}
							delay={3000}
							autohide
						>
							<Toast.Header
								className={`normal-font-size fw-bold ${classMessage}`}
								closeButton={false}
							>
								<p
									className={`small-font-size fw-bold ${classMessage}`}
								>
									{message}
								</p>
							</Toast.Header>
							<Toast.Body className="normal-font-size">
								<p className="small-font-size fw-bold">
									{this.state.error}
								</p>
							</Toast.Body>
						</Toast>
					) : (
						""
					)}
					<button
						type="submit"
						className="btn theme-bg-color text-white col-md-12 font-style my-3 py-2"
					>
						Let's hop in
					</button>
					<p className="small-font-size text-white text-justify pt-3">
						<span className="font-weight-bold">WARNING:</span>
						This application is available only for authorized users.
						If you are not an authorized user, Please disconnect the
						session by closing the browser immediately. By accessing
						this system, you agree that your actions may be
						monitored if unauthorized user is suspected.
					</p>
				</form>
			</div>
		);
	}

	// Validation for username

	validateUserName = () => {
		const usernameErr = CheckUserName(this.state.username);
		if (usernameErr && usernameErr === 1) {
			this.setState({ usernameError: "Empty email / phone no." });
			return false;
		} else if (usernameErr && usernameErr === 2) {
			this.setState({ usernameError: "Enter valid email" });
			return false;
		} else if (usernameErr && usernameErr === 3) {
			this.setState({ usernameError: "Enter valid phone" });
			return false;
		}
		return true;
	};

	// Validation for password

	validatePassword = () => {
		const passwordError = CheckPassword(this.state.password);
		if (passwordError === 1) {
			this.setState({ passwordError: "Password empty" });
			return false;
		} else if (passwordError === 2) {
			this.setState({ passwordError: "Enter valid password" });
			return false;
		} else return true;
	};

	// Empty input validation

	ValidateAll = () => {
		const userNameInput = this.validateUserName();
		const passwordInput = this.validatePassword();
		if (userNameInput && passwordInput) {
			return true;
		} else {
			return false;
		}
	};

	// on submit sign in function
	onSubmitLogin = async (e) => {
		e.preventDefault();
		const allValidation = this.ValidateAll();
		if (allValidation) {
			const requestData = {
				username: this.state.username,
				password: this.state.password,
			};
			login(requestData).then((response) => {
				if (response && response.status === "success") {
					User.setUserId(response.result.user_id);
					User.setRoleId(response.result.role_id);
					AppConfig.setApiKey(response.result.token);
					if (response.result.role_id === 5) {
						this.props.history.replace("/inventory");
					} else {
						this.props.history.replace("/dashboard");
					}
				} else if (response.status === "error") {
					this.setState({ error: response.result });
				}
			});
		}
	};
}
export default observer(SignIn);
