import { callApi } from "./Api";
import "./Global";
/**
 *
 * @param{notification list)data
 */
export const notificationList = async (userId) => {
	const functionUrl = "notification_list";
	const requestData = {
		user_id: userId,
	};
	try {
		console.log("project fun url", functionUrl);
		return await callApi(functionUrl, requestData)
			.then((response) => {
				console.log(response);
				return response;
			})
			.catch((error) => {
				console.log("Error from API => ", error);
			});
	} catch (error) {
		console.log("Error from catch => ", error);
	}
};

/**
 *
 * @param{update read or unread) by id
 */
export const updateNotification = async (requestData, id) => {
	const functionUrl = "notification";
	try {
		return await callApi(functionUrl, requestData, "PUT", id)
			.then((response) => {
				console.log(response);
				return response;
			})
			.catch((error) => {
				console.log("Error from API => ", error);
			});
	} catch (error) {
		console.log("Error from catch => ", error);
	}
};

/**
 *
 * @param{clear all notification) by all the id's
 */
export const clearAll = async (requestData = {}) => {
	const functionUrl = "clearall_notification";
	try {
		return await callApi(functionUrl, requestData)
			.then((response) => {
				console.log(response);
				return response;
			})
			.catch((error) => {
				console.log("Error from API => ", error);
			});
	} catch (error) {
		console.log("Error from catch => ", error);
	}
};

/**
 *
 * @param{read all) by all the id's
 */
export const readAll = async (requestData = {}) => {
	const functionUrl = "readall_notification";
	try {
		return await callApi(functionUrl, requestData)
			.then((response) => {
				console.log(response);
				return response;
			})
			.catch((error) => {
				console.log("Error from API => ", error);
			});
	} catch (error) {
		console.log("Error from catch => ", error);
	}
};
