import {callApi} from '../Api';
import '../Global';

/**
 *
 * @param{architect name and project)data
 */
export const createArchitect = async(requestData={}) => {
    const functionUrl = "architect";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};