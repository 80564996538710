import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Common.css';

import SignIn from './pages/SignIn';
import ForgotPassword from './pages/general/ForgotPassword';
import ChangePassword from './pages/general/ChangePassword';

//Import admin component 
import Dashboard from './pages/Dashboard';
import Admin from './pages/admin/Admin';
import Leads from './pages/leads/Leads';
import ViewLead from './pages/leads/ViewLead';
import Inventory from './pages/inventory/Inventory';

import ToastMessage from './common/ToastMessage';
import { Switch } from 'react-router-dom';
import AppConfig from './modal/AppConfig';
import Loader from './common/Loader';
import User from './modal/User';
import { observer } from 'mobx-react';
// import ScrollToTop from './pages/ScrollToTop';
import { PublicRoute, PrivateRoute } from './common/Element';


const App = observer(() => {
    if (!AppConfig.isHydrated || !User.isHydrated) {
        return <p className="d-flex flex-grow-1 align-self-center giga-font-size">loading...</p>;
    }
    return (
        <>
            <Loader />
            <ToastMessage />
            {/* <ScrollToTop /> */}
            <Switch>
                <PublicRoute exact path="/" component={SignIn} />
                <PublicRoute exact path="/sign-in" component={SignIn} />
                <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/change-password' component={ChangePassword} />
                {/** Inventory link */}
                <PrivateRoute exact path='/inventory' component={Inventory} />
                {/** Leads link */}
                <PrivateRoute exact path='/leads' component={Leads} />
                <PrivateRoute exact path='/view-leads/:id' component={ViewLead} />
                {/** Admin link */}
                <PrivateRoute exact path='/admin' component={Admin} />
                <PublicRoute path="*" component={() => "404 Not Found"} />
                
            </Switch>
        </>
    )
}
)
export default App;