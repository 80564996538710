import React, { useState } from "react";
import {
	ProSidebar,
	SidebarHeader,
	SidebarContent,
	SidebarFooter,
} from "react-pro-sidebar";
import Logo from "../../assets/logo.png";
import LogoSm from "../../assets/favicon/favicon-96x96.png";
import "../../css/Common.css";
import "react-pro-sidebar/dist/css/styles.css";
import MenuList from "./MenuList";

function SideBar({ toggled, handletogglesidebar, handleCollapsedChange }) {
	const [collapsed, setCollapsed] = useState(false);
	return (
		<ProSidebar
			collapsed={collapsed}
			toggled={toggled}
			breakPoint="md"
			onToggle={handletogglesidebar}
			handletogglesidebar={handleCollapsedChange}
		>
			<SidebarHeader className="d-flex justify-content-center py-2 bg-white">
				{collapsed ? (
					<img src={LogoSm} alt={LogoSm} width="70" height="35" />
				) : (
					<img src={Logo} alt={Logo} width="160" height="60" />
				)}
			</SidebarHeader>
			<SidebarContent style={{ backgroundColor: "#F3F3F3" }}>
				<MenuList />
			</SidebarContent>
			<SidebarFooter
				className="p-3"
				style={{ backgroundColor: "#F3F3F3" }}
			>
				<div className="d-flex justify-content-end">
					{collapsed ? (
						<i
							className="fas fa-chevron-circle-right pointer theme-font-color giga-font-size"
							onClick={() => setCollapsed(false)}
							size={30}
						/>
					) : (
						<i
							className="fas fa-chevron-circle-left pointer theme-font-color giga-font-size"
							onClick={() => setCollapsed(true)}
							icon="collpase"
							size={30}
						/>
					)}
				</div>
			</SidebarFooter>
		</ProSidebar>
	);
}

export default SideBar;
