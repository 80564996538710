import { callApi } from "../Api";
import "../Global";

/**
 *
 * @param{id, name, address)data
 */
export const createLocation = async (requestData = {}) => {
  const functionUrl = "location";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{location list)data
 */
export const locationList = async (requestData = {}) => {
  const functionUrl = "location_list";

  try {
    console.log("project fun url", functionUrl);
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{update location) by id
 */
export const updateLocation = async (requestData, id) => {
  const functionUrl = "location";
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{delete location) by id
 */
export const deleteLocation = async (id) => {
  const functionUrl = "location";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single location) by id
 */
export const getLocation = async (id) => {
  const functionUrl = "location";
  try {
    return await callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
/**
 *
 * @param{get location dropdown list) data
 */
export const getLocationDropdown = async (requestData = {}) => {
  const functionUrl = "location_dropdown";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

// Delete multiple Leads
export const deleteMultipleLocations = async (requestBody = {}) => {
  const functionUrl = "delete_multiple_locations";
  try {
    return await callApi(functionUrl, requestBody, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
