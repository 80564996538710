// Role options
export const createEmployeeRoleOptions = [
  { label: "Admin", value: 1 },
  { label: "Sales", value: 2 },
  { label: "Marketing", value: 3 },
  { label: "Accounts", value: 4 },
  { label: "Manager", value: 6 },
];
export const roleOptions = [
  { label: "Admin", value: 1 },
  { label: "Sales", value: 2 },
  { label: "Marketing", value: 3 },
  { label: "Accounts", value: 4 },
  { label: "Guest User", value: 5 },
  { label: "Manager", value: 6 },
];

// Role object options
export const roleName = {
  1: "Admin",
  2: "Sales",
  3: "Marketing",
  4: "Accounts",
  5: "Guest User",
};

// Unit options
export const unitOptions = [
  { label: "cm", value: "cm" },
  { label: "mm", value: "mm" },
  { label: "sq.ft", value: "sqft" },
];

// Quantity unitoptions
export const quantityUnitOptions = [
  { label: "mm", value: "mm" },
  { label: "no's", value: "nos" },
];

// Lead type option
export const leadTypeOptions = [
  { value: "IS_ARCHITECT", label: "Architect" },
  { value: "CUSTOMER", label: "Customer" },
];

// Lead status options
export const leadStatusOptions = [
  { label: "Lead", value: "LEAD" },
  { label: "Prospect", value: "PROSPECT" },
  { label: "Customer", value: "CUSTOMER" },
];

// Date options for dashboard filter
export const dateFilter = [
  { value: "7", label: "7 Days" },
  { value: "15", label: "15 Days" },
  { value: "30", label: "30 Days" },
  { value: "90", label: "90 Days" },
  { value: "180", label: "180 Days" },
  { value: "360", label: "360 Days" },
];
